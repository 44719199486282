import { Component } from '@angular/core';

@Component({
  selector: 'app-status-formatter-cell',
  styleUrls: ['./status-chage-formatter.scss'],
  template: `
    <mat-icon *ngIf="!!params.param && params.hasIcon" svgIcon="circle-filled" [class]="params.param"> </mat-icon>
    <span [ngClass]="{ 'change-header': !params.hasIcon }">{{ params.param }}</span>
    <span *ngIf="params.changeDescription" class="change-description">{{ params.changeDescription }}</span>
  `,
})
export class StatusChangeFormatterComponent {
  params: any;

  agInit(params: any): void {
    this.params = params.value;
  }
}
