import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { ThemePalette, TooltipPosition } from '@angular/material';
import { isEmpty as _isEmpty } from 'lodash';
import { IconsKeys } from '../../enums/icons.enum';

@Component({
  selector: 'app-special-service-formatter-cell',
  styleUrls: ['./special-service-formatter.scss'],
  template: `
    <mat-icon *ngFor="let ind of indicators" [svgIcon]="ind" [class]="ind"></mat-icon>
    <mat-icon
      *ngIf="headLoadInd && showHeadLoadPopover"
      [svgIcon]="IconsKeys.headLoad"
      [class]="IconsKeys.headLoad"
      #headLoadIcon
      [xpoPopoverTriggerFor]="jsPopover"
    ></mat-icon>
    <mat-icon
      *ngIf="headLoadInd && !showHeadLoadPopover"
      [svgIcon]="IconsKeys.headLoad"
      [class]="IconsKeys.headLoad"
      #headLoadIcon
    ></mat-icon>
    <xpo-popover #jsPopover="xpoPopover" class="greyPopover" [position]="pos" [color]="color" [caretPosition]="cPos">
      <p xpo-popover-item *ngFor="let item of popoverItems">{{ item }}</p>
    </xpo-popover>
  `,
})
export class SpecialServiceFormatterComponent {
  params: any;
  headLoadInd: boolean;
  readonly IconsKeys = IconsKeys;
  pos: TooltipPosition = 'below';
  cPos: HorizontalConnectionPos | VerticalConnectionPos = 'center';
  color: ThemePalette = 'warn';
  popoverItems: string[] = [];
  showHeadLoadPopover: boolean = false;

  indicators: Array<IconsKeys> = [];

  agInit(params: any): void {
    this.params = params;
    const { headLoadDetail } = this.params.value;
    this.showHeadLoadPopover = !_isEmpty(headLoadDetail) && !this.params.data.children;

    this.setIndicator(params);

    if (this.showHeadLoadPopover) {
      this.popoverItems = [
        'Destination: ' + (headLoadDetail.destinationSic || '?'),
        'Package Type: ' + headLoadDetail.lseOrSkdCd,
        'Cube: ' + headLoadDetail.cbePercentage + ' %',
        'Weight: ' + headLoadDetail.headLoadedLbs + ' lbs',
        'Feet: ' + headLoadDetail.nbrOfFeet,
      ];
    }
  }

  private setIndicator(params) {
    if (params.node.group) {
      const flattened = params.value.flat();
      flattened.some((val) => val.guaranteedInd) && this.indicators.push(IconsKeys.guaranteed);
      flattened.some((val) => val.misloadInd) && this.indicators.push(IconsKeys.misload);
      flattened.some((val) => val.hazmatInd) && this.indicators.push(IconsKeys.hazmat);
      flattened.some((val) => val.frzblInd) && this.indicators.push(IconsKeys.freezable);

      this.headLoadInd = flattened.some((val) => val.headLoadDetail && val.headLoadInd);
    } else {
      const { guaranteedInd, misloadInd, hazmatInd, frzblInd, headLoadInd } = params.value;
      guaranteedInd && this.indicators.push(IconsKeys.guaranteed);
      misloadInd && this.indicators.push(IconsKeys.misload);
      hazmatInd && this.indicators.push(IconsKeys.hazmat);
      frzblInd && this.indicators.push(IconsKeys.freezable);

      this.headLoadInd = !!headLoadInd;
    }
  }
}
