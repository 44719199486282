/* tslint:disable: max-line-length */
import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { XpoBoardView } from '@xpo/ngx-core-board';
import { Unsubscriber, XpoLtlServiceCentersService } from '@xpo/ngx-ltl';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SidePanelData } from '../../../../shared/components/side-panel-container/image-gallery-container/enums/side-panel-data.model';
import { AppRoutes } from '../../../../shared/enums/app-routes.enum';
import { ComponentsEnum } from '../../../../shared/enums/components.enum';
import { BypassFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/BypassFormatterComponent';
import { IconsFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/icons-formatter';
import { LoadedWeightFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/loaded-weight-formatter';
import { SpecialServiceFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/special-service-formatter';
import { StatusFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/status-formatter';
import { InteractionServiceResp } from '../../../../shared/models/interaction-service-response.model';
import { ExternalUrlsService } from '../../../../shared/services/external-urls.service';
import { SidePanelOpts } from './../../../../shared/components/side-panel-container/image-gallery-container/enums/options.model';
import { SidePanelStatus } from './../../../../shared/enums/side-panel-status';
import { SidePanelDataStructures } from './../../../../shared/models/side-panel-data.model';
import { InteractionService } from './../../../../shared/services/interaction.service';
import { ChangeViewService } from './../services/change-view.service';
import { LoadsViewBoardTemplate } from './loads-view-board-template';
import { LoadsViewDataSourceService } from './loads-view-data-source.service';

@Component({
  selector: 'app-loads-view',
  templateUrl: './loads-view.component.html',
  styleUrls: ['./loads-view.component.scss'],
})
export class LoadsViewComponent implements OnInit, OnDestroy {
  viewTemplates: LoadsViewBoardTemplate[];
  views: XpoBoardView[];
  loadsCollection: any = [];
  sizeToFit: boolean = true;
  gridOptions: GridOptions = {
    suppressHorizontalScroll: true,
    frameworkComponents: {
      IconsFormatterComponent: IconsFormatterComponent,
      SpecialServiceFormatterComponent: SpecialServiceFormatterComponent,
      BypassFormatterComponent: BypassFormatterComponent,
      StatusFormatterComponent: StatusFormatterComponent,
      LoadedWeightFormatterComponent: LoadedWeightFormatterComponent,
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      suppressMenu: true,
    },
    headerHeight: 40,
    rowHeight: 30,
  };
  showTable = false;
  subscription: Subscription;
  eventSubscription: Subscription;
  readonly AppRoutes = AppRoutes;
  sidePanelDisplay: boolean = false;
  sidePanelId: string;
  iframeUrl: string;
  selectedLane: string;
  private gridApi: GridApi;
  laneData;
  sidePanelOpts: SidePanelOpts;
  sidePanelData: SidePanelData;
  private unsubscriber = new Unsubscriber();
  changeSubscription: any;
  selectedSic;

  constructor(
    public datasource: LoadsViewDataSourceService,
    private decimalPipe: DecimalPipe,
    private externalUrlsService: ExternalUrlsService,
    public serviceCentersService: XpoLtlServiceCentersService,
    public locationApiService: LocationApiService,
    private changeViewService: ChangeViewService,
    private interactionService: InteractionService
  ) {
    this.changeSubscription = this.changeViewService.getCurrentView().subscribe((view) => {
      if (this.gridApi && view === AppRoutes.LOADS_VIEW) {
        this.gridApi.sizeColumnsToFit();
      }
    });
    this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((resp: InteractionServiceResp) => {
        this.sidePanelDisplay = false;
        this.selectedSic = resp.data.sic;
      });
  }

  ngOnInit() {
    this.viewTemplates = [new LoadsViewBoardTemplate(this.decimalPipe)];
    this.views = [this.viewTemplates[0].createLoadsView()];
    this.subscription = this.viewTemplates[0].clickSubject$.subscribe((params) => {
      if (!!params) {
        this.setupSidePanel(params);
      }
    });
    this.sidePanelOpts = {};
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.gridApi = null;
    this.changeSubscription.unsubscribe();
  }

  setupSidePanel(params) {
    if (params) {
      this.selectedLane = params.lane;
      this.sidePanelId = params.load || '';
      this.iframeUrl = this.externalUrlsService.parseUrl('image-viewer') + this.sidePanelId;
      this.laneData = {
        filterSic: this.selectedSic,
        selectedLane: params.lane,
        sidePanelId: params.load,
        iframeUrl: this.iframeUrl,
        ...params,
      };
      this.sidePanelData = {
        currentEventTmst: this.laneData.currentEventTmst,
        closeTmst: this.laneData.closeTmst,
        statusCd: this.laneData.statusCd,
        sidePanelId: this.laneData.sidePanelId,
        filterSic: this.selectedSic,
        laneData: this.laneData,
      };
    }
    this.openSidePanelDisplay();
  }

  openSidePanelDisplay() {
    if (this.sidePanelData) {
      const message = new SidePanelDataStructures(this.sidePanelData, this.sidePanelOpts, SidePanelStatus.OPENED);
      this.interactionService.sendDataSidePanel(message);
    }
  }

  onLoadsGridReady(gridEvent: AgGridEvent) {
    this.gridApi = gridEvent.api;
  }
}
