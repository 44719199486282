import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatChipsModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatTooltipModule,
} from '@angular/material';
import { ExceptionManagementApiService } from '@xpo-ltl/sdk-exceptionmanagement';
import { PricingApiService } from '@xpo-ltl/sdk-pricing';
import { ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { XpoCardModule, XpoTabsModule } from '@xpo/ngx-core';
import { XpoBoardModule, XpoFiltersModule } from '@xpo/ngx-core-board';
import { XpoLtlShipmentDetailsModule, XpoLtlShipmentDetailsService } from '@xpo/ngx-ltl';
import { PipesModule } from '../../pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';
import { AlertsTabContentComponent } from './alerts-tab-content/alerts-tab-content.component';
import { AlertsComponent } from './alerts.component';
import { AlertCardComponent } from './card/alert-card.component';

@NgModule({
  declarations: [AlertsComponent, AlertCardComponent, AlertsTabContentComponent],
  imports: [
    CommonModule,
    XpoCardModule,
    XpoBoardModule,
    XpoFiltersModule,
    XpoTabsModule,
    MatTabsModule,
    MatIconModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    SharedModule,
    XpoLtlShipmentDetailsModule,
    FormsModule,
    PipesModule,
    MatTooltipModule,
  ],
  entryComponents: [],
  providers: [ExceptionManagementApiService, XpoLtlShipmentDetailsService, ShipmentOdsApiService, PricingApiService],
})
export class AlertsModule {}
