import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { GoldenLayoutService } from '@embedded-enterprises/ng6-golden-layout';
import * as GoldenLayout from 'golden-layout';
import {
  filter as _filter,
  findIndex as _findIndex,
  forEach as _forEach,
  get as _get,
  isUndefined as _isUndefined,
  map as _map,
  uniq as _uniq,
} from 'lodash';
import { Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GoldenLayoutExtService } from '../shared/layout-preference/services/golden-layout-ext.service';
import { LayoutPreferenceService } from '../shared/layout-preference/services/layout-preference.service';
import { SidePanelDataStructures } from '../shared/models/side-panel-data.model';
import { SidePanelOpts } from './../shared/components/side-panel-container/image-gallery-container/enums/options.model';
import { ComponentsEnum } from './../shared/enums/components.enum';
import { InteractionServiceResp } from './../shared/models/interaction-service-response.model';
import { InteractionService } from './../shared/services/interaction.service';

@Component({
  selector: 'app-lanes',
  templateUrl: './execution.component.html',
  styleUrls: ['./execution.component.scss'],
})
export class ExecutionComponent implements OnInit, OnDestroy {
  sidePanelDisplay: boolean = false;
  sideData: any;
  sidePanelOpts: SidePanelOpts;
  loadRequestPanel: boolean = false;

  private readonly keyMap = [
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0',
  ];
  glSubscription: Subscription;
  interactionSubscription: Subscription;

  private shortcutSlots = new Array<GoldenLayout.ContentItem>(10); // create 10 slots

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && !event.repeat && event.key !== 'Control' && event.key !== 'Shift') {
      const slot = _findIndex(this.keyMap, (k) => k === event.code);
      if (slot > -1) {
        this.toggleMaximizeComponent(slot);
      }
    }
  }

  constructor(
    private goldenLayout: GoldenLayoutService,
    private layoutPreferences: LayoutPreferenceService,
    private interactionService: InteractionService
  ) {
    const glService = this.goldenLayout as GoldenLayoutExtService;

    this.layoutPreferences.loadLayoutsFromStorage();

    this.glSubscription = glService.componentCreated$.subscribe((component: GoldenLayout.ContentItem) => {
      const slot = _findIndex(this.shortcutSlots, (compSlot) => _isUndefined(compSlot));
      if (slot >= 0) {
        this.shortcutSlots[slot] = component;

        component.on('titleChanged', (title) => {
          const tooltip = `${title} (CTRL+SHFT+${slot + 1})`;
          timer(1).subscribe(() => {
            const tabElem = _get(component, 'container.tab.element');
            if (tabElem) {
              tabElem.attr('title', tooltip);
            }
          });
        });

        component.setTitle(`${component.config.title}`);
      }
    });

    glService.componentDestroyed$.subscribe((component: GoldenLayout.ContentItem) => {
      const slot = _findIndex(this.shortcutSlots, (compSlot) => compSlot === component);
      if (slot >= 0) {
        this.shortcutSlots[slot] = undefined;
      }
    });

    this.interactionSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.SIDE_PANEL)
      .pipe(filter((resp: InteractionServiceResp) => !!resp.data && !!Object.keys(resp.data).length))
      .subscribe((resp: InteractionServiceResp) => {
        if (
          resp.data.sidePanel.sideData.sidePanelId &&
          this.sideData.sidePanelId !== resp.data.sidePanel.sideData.sidePanelId
        ) {
          this.sideData = { ...resp.data.sidePanel.sideData } || {};
          this.sidePanelOpts = { ...resp.data.sidePanel.sidePanelOpts } || {};
        }
        this.sidePanelDisplay = resp.data.sidePanel.sidePanelDisplay || false;
      });
    this.interactionSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.LOAD_PANEL)
      .subscribe((resp: InteractionServiceResp) => {
        if (resp.data.status === 'OPEN') {
          this.loadRequestPanel = true;
        } else if (resp.data.status === 'CLOSE') {
          this.loadRequestPanel = false;
        }
      });
    this.clearLaneData();
  }

  private deMaximizeAll() {
    const stacks = _uniq(_map(_filter(this.shortcutSlots, (pane) => !!pane), (pane) => pane.parent));
    _forEach(stacks, (stack) => {
      if (stack.isMaximised) {
        stack.toggleMaximise();
      }
    });
  }

  private toggleMaximizeComponent(slot: number) {
    const selectedPane = this.shortcutSlots[slot];
    if (selectedPane) {
      if (selectedPane.parent) {
        if (selectedPane.parent.isMaximised && selectedPane === selectedPane.parent.getActiveContentItem()) {
          selectedPane.parent.toggleMaximise();
        } else {
          this.deMaximizeAll();
          selectedPane.parent.toggleMaximise();
          selectedPane.parent.setActiveContentItem(selectedPane);
        }
      } else {
        selectedPane.toggleMaximise();
      }
    }
  }

  ngOnInit() {}

  closeSidePanelDisplay() {
    this.clearLaneData();
    this.sidePanelDisplay = false;
  }

  clearLaneData() {
    this.sideData = SidePanelDataStructures.getBlankData();
  }

  ngOnDestroy() {
    this.glSubscription.unsubscribe();
    this.interactionSubscription.unsubscribe();
  }
}
