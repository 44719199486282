import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-square-info',
  templateUrl: './square-info.component.html',
  styleUrls: ['./square-info.component.scss'],
})
export class SquareInfoComponent implements OnInit {
  @Input() infoAmount: number;
  @Input() infoDescription: string;

  ngOnInit(): void {}
}
