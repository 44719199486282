/* tslint:disable: max-line-length */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MoveModeCd } from '@xpo-ltl/sdk-common';
import { XpoBoardView } from '@xpo/ngx-core-board';
import { Unsubscriber, XpoLtlServiceCentersService, XpoLtlTimeService } from '@xpo/ngx-ltl';
import { AgGridEvent, ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { Observable, of, Subscription, forkJoin, timer } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, takeUntil, filter } from 'rxjs/operators';
import { AllowableLoadsService } from '../services/allowable-loads.service';
import { GlobalFilters } from './../../../../shared/models/global-filters';
import { AuthService } from './../../../../shared/services/auth.service';

import { HttpClient } from '@angular/common/http';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { SidePanelData } from '../../../../shared/components/side-panel-container/image-gallery-container/enums/side-panel-data.model';
import { AppRoutes } from '../../../../shared/enums/app-routes.enum';
import { ComponentsEnum } from '../../../../shared/enums/components.enum';
import { IconsKeys } from '../../../../shared/enums/icons.enum';
import { SidePanelStatus } from '../../../../shared/enums/side-panel-status';
import { AddLoadFormatter } from '../../../../shared/formatters/ag-grid-cell-formatters/add-load-formatter';
import { BypassFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/BypassFormatterComponent';
import { CloseToFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/closeto-formatter';
import { ExceptionFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/exception-formatter';
import { IconsFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/icons-formatter';
import { LoadedWeightFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/loaded-weight-formatter';
import { MoveToFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/move-to-formatter';
import { SpecialServiceFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/special-service-formatter';
import { StatusFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/status-formatter';
import { InteractionServiceResp } from '../../../../shared/models/interaction-service-response.model';
import { SidePanelDataStructures } from '../../../../shared/models/side-panel-data.model';
import { ExternalUrlsService } from '../../../../shared/services/external-urls.service';
import { ShiftService } from '../../../../shared/services/shift.service';
import { SidePanelOpts } from './../../../../shared/components/side-panel-container/image-gallery-container/enums/options.model';
import { InteractionService } from './../../../../shared/services/interaction.service';
import { LanesViewBoardTemplate } from './lanes-view-board-template';
import { LanesViewDataSourceService } from './lanes-view-data-source.service';

import moment from 'moment-timezone';

@Component({
  selector: 'app-lanes-view',
  templateUrl: './lanes-view.component.html',
  styleUrls: ['./lanes-view.component.scss'],
})
export class LanesViewComponent implements OnInit, OnDestroy {
  gridOptions: GridOptions;
  aggFunctions;
  sizeToFit: boolean = true;
  private gridApi: GridApi;
  private colApi: ColumnApi;
  frameworkComponents = {
    IconsFormatterComponent: IconsFormatterComponent,
    SpecialServiceFormatterComponent: SpecialServiceFormatterComponent,
    StatusFormatterComponent: StatusFormatterComponent,
    BypassFormatterComponent: BypassFormatterComponent,
    LoadedWeightFormatterComponent: LoadedWeightFormatterComponent,
    ExceptionFormatterComponent: ExceptionFormatterComponent,
    CloseToFormatterComponent: CloseToFormatterComponent,
    MoveToFormatterComponent: MoveToFormatterComponent,
    AddLoadFormatter: AddLoadFormatter,
  };
  sidePanelData: SidePanelData;
  sidePanelOpts: SidePanelOpts;
  sidePanelId: string;
  iframeUrl: string;
  viewTemplates: LanesViewBoardTemplate[];
  views: XpoBoardView[];
  subscription: Subscription;
  readonly AppRoutes = AppRoutes;
  isResetRowHeights = false;
  detailLanesNodes: any[] = [];
  expandedCloseToNode: any[] = [];
  heightHelper: any = {};
  selectedLane: string;
  laneData;
  interactionSubscription: Subscription;
  private unsubscriber = new Unsubscriber();
  selectedSic: string;
  sicLoadRequestAccess = false;
  lastReqGlobalFilter: GlobalFilters = new GlobalFilters();
  dateAndShiftValid$: Observable<boolean>;

  constructor(
    private externalUrlsService: ExternalUrlsService,
    public datasource: LanesViewDataSourceService,
    public serviceCentersService: XpoLtlServiceCentersService,
    private interactionService: InteractionService,
    private authService: AuthService,
    private shiftService: ShiftService,
    private allowableLoadsService: AllowableLoadsService
  ) {
    this.interactionSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(
        map((resp) => resp.data),
        filter((data: GlobalFilters) => !!data.region && !!data.shift && !!data.planDate),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((data: GlobalFilters) => {
        const message = new SidePanelDataStructures(null, null, SidePanelStatus.CLOSED);
        this.interactionService.sendDataSidePanel(message);
        this.selectedSic = data.sic;
        this.handleLoadReqAccess(data);
      });

    this.interactionService.subscribeToComponent(ComponentsEnum.SIC).subscribe((sic) => {
      if (this.gridApi) {
        timer(5000).subscribe(() => {
          this.gridApi.refreshHeader();
        });
      }
    });

    this.aggFunctions = {
      getCloseToVal: this.getValuesAsString,
      getByp: this.getBypAgg,
      getGuaranteed: this.getGuaranteedInd,
      getCountAgg: this.getCountAgg,
      getSumAgg: this.getSumAgg,
      getExceptionInd: this.getExceptionInd,
      getValues: this.getValues,
      formatDecimal: this.formatDecimal,
    };
    this.gridOptions = {
      groupMultiAutoColumn: true,
      groupSuppressAutoColumn: true,
      aggFuncs: this.aggFunctions,
      suppressAggFuncInHeader: true,
      frameworkComponents: this.frameworkComponents,
      headerHeight: 28,
      icons: {
        groupExpanded: `<img src="./assets/images/expanded.svg"
          style="width: 22px; position: absolute; left: 10px; top: 4px;" data=test"expandButton">`,
        groupContracted: `<img src="./assets/images/contracted.svg"
          style="width: 22px; position: absolute; left: 10px; top: 4px;" data=test"contractButton">`,
      },
      // level 1 grid options
      columnDefs: [
        {
          headerName: ' ',
          headerClass: 'main-table-header',
          children: [
            {
              width: 45,
              minWidth: 45,
              maxWidth: 45,
              headerName: '',
              showRowGroup: 'lane',
              field: 'lane',
              cellRenderer: 'agGroupCellRenderer',
              sortable: false,
              resizable: false,
              cellRendererParams: {
                suppressCount: true,
              },
            },
            {
              headerName: 'Actions',
              minWidth: 72,
              width: 72,
              maxWidth: 72,
              cellRenderer: 'AddLoadFormatter',
              valueGetter: (params) => {
                return this.checkLaneLoadReqAccess(params);
              },
            },
          ],
        },
        {
          headerName: 'Trip Summary',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Move to',
              showRowGroup: 'lane',
              minWidth: 90,
              maxWidth: 90,
              cellRenderer: 'MoveToFormatterComponent',
              field: 'lane',
              valueGetter: (params) => {
                return this.getMoveModeCD(params.data);
              },
              cellClass: 'laneColumn',
              cellRendererParams: {
                suppressCount: true,
              },
              comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
                return valueA.label > valueB.label ? 1 : -1;
              },
            },
            {
              headerName: 'Close To',
              field: 'closeToSicCd',
              minWidth: 120,
              maxWidth: 120,
              cellClass: 'ellipsis',
              valueGetter: (params) => {
                return this.formatCloseTo(params);
              },
            },
            {
              headerName: 'Special Services',
              minWidth: 120,
              maxWidth: 120,
              cellClass: 'specialServicesColumn',
              valueGetter: (params) => {
                return this.getSpecialServices(params.data);
              },
              aggFunc: 'getValues',
              sortable: false,
              cellRenderer: 'SpecialServiceFormatterComponent',
            },
          ],
        },
        {
          headerName: 'Loads',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Bypass',
              field: 'bypassLoadCount',
              valueGetter: function(params) {
                return params.data.bypassLoadCount ? params.data.bypassLoadCount : '';
              },
              type: 'numericColumn',
            },
            {
              headerName: '',
              field: 'planned',
              valueGetter: (params) => {
                const dataPlannedLoadCount = _.get(params, 'data.planned.loadCount', '');
                return this.handlePlannedColVisibility(dataPlannedLoadCount);
              },
              type: 'numericColumn',
              headerValueGetter: (params) => `${this.allowableLoadsService.isBypassModel ? 'BYP' : ''} Planned`,
            },

            {
              headerName: 'Actual',
              field: 'loaded',
              type: 'numericColumn',
              valueGetter: function(params) {
                return params.data.loaded && params.data.loaded.loadCount ? params.data.loaded.loadCount : '';
              },
            },
          ],
        },
        {
          headerName: 'Cube',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: '',
              field: 'planned',
              valueGetter: (params) => {
                const dataPlannedCube = _.get(params, 'data.planned.cube', '');
                return this.handlePlannedColVisibility(dataPlannedCube);
              },
              valueFormatter: (params) => {
                return this.formatDecimal(params);
              },
              type: 'numericColumn',
              headerValueGetter: (params) => `${this.allowableLoadsService.isBypassModel ? 'BYP' : ''} Planned`,
            },
            {
              headerName: 'Loaded',
              field: 'loaded',
              valueGetter: function getLoadedCube(params) {
                let cubeLoadSum = 0;
                if (params.data) {
                  params.data.children.forEach((closeTo) => {
                    closeTo.children.forEach((trailer) => {
                      if (!trailer.bypassInd) {
                        cubeLoadSum += trailer.loadedCube;
                      }
                    });
                  });
                }
                return cubeLoadSum || '';
              },
              type: 'numericColumn',
            },
            {
              headerName: 'Remaining',
              valueGetter: function getRemainingCube(params) {
                return params.data && params.data.remaining && params.data.remaining.cube
                  ? params.data.remaining.cube
                  : '';
              },
              type: 'numericColumn',
            },
          ],
        },
        {
          headerName: 'Weight',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: '',
              field: 'planned',
              valueGetter: (params) => {
                const dataPlannedWeight = _.get(params, 'data.planned.weight', '');
                return this.handlePlannedColVisibility(dataPlannedWeight);
              },
              valueFormatter: (params) => {
                return this.formatDecimal(params);
              },
              type: 'numericColumn',
              headerValueGetter: (params) => `${this.allowableLoadsService.isBypassModel ? 'BYP' : ''} Planned`,
            },
            {
              headerName: 'Loaded',
              field: 'loaded',
              valueGetter: function getLoadedWeight(params) {
                let weightLoadSum = 0;
                if (params.data) {
                  params.data.children.forEach((closeTo) => {
                    closeTo.children.forEach((trailer) => {
                      if (!trailer.bypassInd) {
                        weightLoadSum += trailer.loadedWeight;
                      }
                    });
                  });
                }
                return weightLoadSum || '';
              },
              valueFormatter: (params) => {
                return this.formatDecimal(params);
              },
              type: 'numericColumn',
            },
            {
              headerName: 'Remaining',
              field: 'remaining',
              valueGetter: function getRemainingWeight(params) {
                return params.data && params.data.remaining && params.data.remaining.weight
                  ? params.data.remaining.weight
                  : '';
              },
              valueFormatter: (params) => {
                return this.formatDecimal(params);
              },
              type: 'numericColumn',
            },
          ],
        },
        {
          headerName: 'Load Average',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'planned',
              valueGetter: function getPlannedLoadAvg(params) {
                return params.data && params.data.planned && params.data.planned.loadAverage
                  ? params.data.planned.loadAverage
                  : '';
              },
              valueFormatter: (params) => this.formatDecimal(params),
              type: 'numericColumn',
            },
            {
              headerName: 'Actual',
              field: 'loaded',
              valueGetter: function getPlannedWeight(params) {
                return params.data && params.data.loaded && params.data.loaded.loadAverage
                  ? params.data.loaded.loadAverage
                  : '';
              },
              valueFormatter: (params) => {
                return this.formatDecimal(params);
              },
              type: 'numericColumn',
            },
          ],
        },
        {
          headerName: 'HSS',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'hssLoadCount',
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.plannedHss && params.data.plannedHss.loadCount
                  ? params.data.plannedHss.loadCount
                  : '';
              },
            },
            {
              headerName: 'Actual',
              field: 'hssLoadCount',
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.loadedHss && params.data.loadedHss.loadCount
                  ? params.data.loadedHss.loadCount
                  : '';
              },
            },
          ],
        },
        {
          headerName: 'Others',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'TUC (%)',
              field: 'trailerUtilizationCapability',
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.trailerUtilizationCapability
                  ? params.data.trailerUtilizationCapability
                  : '';
              },
            },
            {
              headerName: 'TU (%)',
              field: 'closedTrailerUtilization',
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.closedTrailerUtilization ? params.data.closedTrailerUtilization : '';
              },
            },
          ],
        },
      ],
      groupDefaultExpanded: 0,
      masterDetail: true,
      getRowHeight: (params) => {
        const isDetailRow = params.node.detail;
        if (isDetailRow) {
          let detailPanelHeight = params.data.children.length * 30 + 3;
          params.data.children.map((node) => {
            detailPanelHeight += node.children.length * 27 + 40;
          });
          // saves the lane node to recalculate its height if necessary
          this.detailLanesNodes.push({ lane: params.data.lane, node: params.node, originalHeight: detailPanelHeight });
          return detailPanelHeight + 2;
        } else {
          return 30;
        }
      },
      detailCellRendererParams: {
        // level 2 grid options
        detailGridOptions: {
          headerHeight: 1,
          rowHeight: 27,
          frameworkComponents: this.frameworkComponents,
          icons: {
            groupExpanded: `<img class="group-expanded-icon" src="./assets/images/expanded.svg"
              style="width: 22px; position: absolute; left: 20px; top: 4px;" data=test"expandButton">`,
            groupContracted: `<img src="./assets/images/contracted.svg"
              style="width: 22px; position: absolute; left: 20px; top: 4px;" data=test"contractButton">`,
          },
          columnDefs: [
            {
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell',
              minWidth: 45,
              maxWidth: 45,
              showRowGroup: 'closeToSicCd',
              field: 'closeToSicCd',
              cellRenderer: 'agGroupCellRenderer',
              sortable: false,
              resizable: false,
              cellRendererParams: {
                suppressCount: true,
              },
            },
            {
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell secondary-table-cell-caption',
              minWidth: 72,
              maxWidth: 72,
              valueGetter: (params) => {
                return params.data && params.data.closeToSicCd === this.datasource.sic ? 'Hold to' : 'Close to';
              },
            },
            {
              headerClass: 'secondary-table-header',
              showRowGroup: 'closeToSicCd',
              minWidth: 210,
              maxWidth: 210,
              field: 'closeToSicCd',
              cellClass: 'secondary-table-cell close-to-cell',
            },
            {
              minWidth: 120,
              maxWidth: 120,
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell',
              headerName: 'Special Services',
              valueGetter: (params) => {
                return this.getSpecialServicesDetail(params.data);
              },
              aggFunc: 'getValues',
              sortable: false,
              cellRenderer: 'SpecialServiceFormatterComponent',
            },
            {
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell',
            },
            {
              // Loads Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              type: 'numericColumn',
              field: 'planned',
              cellRenderer: (params) => {
                const dataPlannedLoadCount = _.get(params, 'data.planned.loadCount', '');
                return this.handlePlannedColVisibility(dataPlannedLoadCount);
              },
            },
            {
              // Loads Actual
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              type: 'numericColumn',
              field: 'loaded',
              valueGetter: function getLoadCount(params) {
                return params.data && params.data.loaded && params.data.loaded.loadCount
                  ? params.data.loaded.loadCount
                  : '';
              },
            },
            {
              // Cube Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: (params) => {
                const dataPlannedCube = _.get(params, 'data.planned.cube', '');
                return this.handlePlannedColVisibility(dataPlannedCube);
              },
              type: 'numericColumn',
            },
            {
              // Cube Loaded
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              type: 'numericColumn',
              valueGetter: function getLoadedCube(params) {
                let cubeLoadSum = 0;
                if (params.data) {
                  params.data.children.forEach((trailer) => {
                    if (!trailer.bypassInd) {
                      cubeLoadSum += trailer.loadedCube;
                    }
                  });
                }
                return cubeLoadSum || '';
              },
            },
            {
              // Cube Remaining
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getRemainingCube(params) {
                return params.data && params.data.remaining ? params.data.remaining.cube : '';
              },
              type: 'numericColumn',
            },
            {
              // Weight Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: (params) => {
                const dataPlannedWeight = _.get(params, 'data.planned.weight', '');
                return this.handlePlannedColVisibility(dataPlannedWeight);
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Weight Loaded
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getLoadedWeight(params) {
                let weightLoadSum = 0;
                if (params.data) {
                  params.data.children.forEach((trailer) => {
                    if (!trailer.bypassInd) {
                      weightLoadSum += trailer.loadedWeight;
                    }
                  });
                }
                return weightLoadSum || '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Weight Remaining
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getRemainingWeight(params) {
                return params.data && params.data.remaining ? params.data.remaining.weight : '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Load Average Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getLoadAvgPlanned(params) {
                return params.data && params.data.planned ? params.data.planned.loadAverage : '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Load Average Actual
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getLoadAvgActual(params) {
                return params.data && params.data.loaded ? params.data.loaded.loadAverage : '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // HSS Planned NOT SHOWN
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
            },
            {
              // HSS Actual
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.loadedHss && params.data.loadedHss.loadCount
                  ? params.data.loadedHss.loadCount
                  : '';
              },
            },
            {
              // TUC (%)
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function(params) {
                return params.data ? params.data.trailerUtilizationCapability : '';
              },
              type: 'numericColumn',
            },
            {
              // TU (%)
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function(params) {
                return params.data ? params.data.closedTrailerUtilization : '';
              },
              type: 'numericColumn',
            },
          ],
          groupDefaultExpanded: 1,
          masterDetail: true,
          getRowHeight: (params) => {
            const isDetailRow = params.node.detail;
            if (isDetailRow) {
              return params.data.children.length * 27 + 40 + 1;
            } else {
              return 30;
            }
          },
          detailCellRendererParams: {
            // level 3 grid options
            detailGridOptions: {
              suppressMenu: true,
              getRowClass: () => {
                return 'deep-table-row';
              },
              headerHeight: 35,
              rowHeight: 27,
              headerComponentParams: {},
              frameworkComponents: this.frameworkComponents,
              columnDefs: [
                {
                  headerName: '',
                  width: 140,
                  maxWidth: 140,
                  suppressMenu: true,
                  lockPinned: true,
                  pinned: 'left',
                  valueGetter: ({ data: { hasNoChildrens } }) => {
                    return !hasNoChildrens ? IconsKeys.photoCamera : '';
                  },
                  lockPosition: true,
                  suppressMovable: true,
                  sortable: false,
                  cellRenderer: 'IconsFormatterComponent',
                  cellClass: ['iconColumn', 'deep-table-cell', 'camera-icon'],
                  headerClass: 'deep-table-cell-header',
                  // Cell event triggers and provides a new value to the Behavior Subject
                  onCellClicked: (params) => {
                    params.data.filterSic && LanesViewBoardTemplate._clickSubject.next(params.data);
                  },
                },
                {
                  headerName: 'Loads',
                  lockPosition: true,
                  suppressMovable: true,
                  lockPinned: true,
                  pinned: 'left',
                  field: 'load',
                  cellClass: 'deep-table-cell',
                  headerClass: 'deep-table-cell-header',
                  width: 180,
                  maxWidth: 180,
                  cellClassRules: {
                    'no-trailer-message': function(params) {
                      return params.data.hasNoChildrens;
                    },
                  },
                  valueGetter: (params) => {
                    if (params.data.hasNoChildrens) {
                      return 'No Trailers Loaded in this Lane';
                    }
                    return params.data.load;
                  },
                },
                {
                  field: 'Special Services',
                  lockPosition: true,
                  suppressMovable: true,
                  lockPinned: true,
                  pinned: 'left',
                  sortable: false,
                  headerClass: 'deep-table-cell-header',
                  cellClass: 'deep-table-cell',
                  width: 130,
                  maxWidth: 130,
                  valueGetter: (params) => {
                    const { guaranteedInd, frzblInd, hazmatInd, headLoadInd, headLoadDetail, misloadInd } = params.data;
                    return { guaranteedInd, frzblInd, hazmatInd, headLoadInd, headLoadDetail, misloadInd };
                  },
                  cellRenderer: 'SpecialServiceFormatterComponent',
                  minWidth: 200,
                },
                {
                  headerName: 'Loaded Weight (lbs)',
                  headerClass: 'deep-table-cell-header',
                  cellClass: ['deep-table-cell', 'cell-aling-right'],
                  lockPinned: true,
                  pinned: 'left',
                  field: 'loadedWeight',
                  lockPosition: true,
                  suppressMovable: true,
                  width: 145,
                  maxWidth: 145,
                  valueGetter: ({ data: { loadedWeight, hasNoChildrens } }) => {
                    loadedWeight = `${loadedWeight}`;
                    const chars = loadedWeight.length - 3;
                    if (!hasNoChildrens) {
                      if (chars > 0) {
                        return loadedWeight.substring(0, chars) + ',' + loadedWeight.substring(chars, 6);
                      } else {
                        return loadedWeight;
                      }
                    } else {
                      return null;
                    }
                  },
                },
                {
                  headerName: 'Loaded Cube',
                  headerClass: 'deep-table-cell-header',
                  cellClass: ['deep-table-cell', 'cell-aling-right'],
                  lockPinned: true,
                  pinned: 'left',
                  field: 'loadedCube',
                  lockPosition: true,
                  suppressMovable: true,
                  width: 115,
                  maxWidth: 115,
                  valueGetter: (params) => {
                    return params.data.loadedCube ? params.data.loadedCube.toFixed(2) : null;
                  },
                },
                {
                  headerName: 'Source',
                  headerClass: 'deep-table-cell-header',
                  cellClass: 'deep-table-cell',
                  lockPinned: true,
                  pinned: 'left',
                  field: 'sourceCd',
                  lockPosition: true,
                  suppressMovable: true,
                  width: 120,
                  maxWidth: 120,
                },
                {
                  headerName: 'Door #',
                  headerClass: 'deep-table-cell-header',
                  cellClass: 'deep-table-cell',
                  lockPinned: true,
                  pinned: 'left',
                  field: 'eventDoor',
                  lockPosition: true,
                  suppressMovable: true,
                  width: 120,
                  maxWidth: 120,
                  valueGetter: (params) => {
                    const convert = parseInt(params.data.eventDoor, 10);
                    return !isNaN(convert) ? convert : '';
                  },
                },
                {
                  headerName: 'Status',
                  field: 'statusCd',
                  headerClass: 'deep-table-cell-header',
                  cellRenderer: 'StatusFormatterComponent',
                  cellClass: ['statusColumn', 'deep-table-cell'],
                  lockPinned: true,
                  pinned: 'left',
                  lockPosition: true,
                  suppressMovable: true,
                  width: 120,
                  maxWidth: 120,
                },
                {
                  headerName: '',
                  headerClass: 'deep-table-cell-header',
                  cellRenderer: 'IconsFormatterComponent',
                  cellClass: ['iconColumn', 'deep-table-cell', 'bypassColumn'],
                  lockPinned: true,
                  pinned: 'left',
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  width: 40,
                  maxWidth: 40,
                  valueGetter: (params) => {
                    return params.data.bypassInd ? IconsKeys.bypass : params.data.ptlTrlrInd ? IconsKeys.ptl : '';
                  },
                },
              ],
              onFirstDataRendered(params) {
                params.api.sizeColumnsToFit();
              },
            },
            getDetailRowData: function(params) {
              params.successCallback(params.data.children);
            },
          },
          onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
            params.api.forEachDetailGridInfo(function(detailGridInfo) {
              detailGridInfo.api.sizeColumnsToFit();
            });
          },
        },
        getDetailRowData: function(params) {
          params.successCallback(params.data.children);
        },
      },
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
        params.api.forEachDetailGridInfo(function(detailGridInfo) {
          detailGridInfo.api.sizeColumnsToFit();
        });
      },
      onModelUpdated(params) {
        params.api.refreshHeader();
      },
    };
    this.gridOptions.defaultColDef = {
      suppressMenu: true,
      sortable: true,
      resizable: true,
    } as ColDef;
  }

  ngOnInit() {
    this.viewTemplates = [new LanesViewBoardTemplate(this.serviceCentersService)];
    this.views = [this.viewTemplates[0].createLanesView()];
    this.subscription = this.viewTemplates[0].clickSubject$.subscribe((params) => {
      this.setupSidePanel(params);
    });
    this.sidePanelOpts = { allowFullScreen: true };
  }
  ngOnDestroy() {
    this.unsubscriber.done;
    this.gridApi = null;
    this.colApi = null;
    this.interactionSubscription.unsubscribe();
  }

  onLanesGridLoad(gridEvent: AgGridEvent) {
    this.gridApi = gridEvent.api;
    this.colApi = gridEvent.columnApi;
    /*this.gridOptions.detailCellRendererParams.alignedGrid = [this.gridOptions];
    this.gridOptions.detailCellRendererParams.detailGridOptions.onGridReady = function (params) {
      this.gridOptions.api.alignedGrids = [];
      this.gridOptions.api.forEachDetailGridInfo((paramss: DetailGridInfo, index) => {
        this.gridOptions.api.alignedGrids.push(paramss);
      });
    };*/
  }

  // returns the list as a string
  getValuesAsString(values) {
    return values.filter(Boolean).toString();
  }

  // just returns the list of values
  getValues(values) {
    return values;
  }

  getBypAgg(values) {
    return values.filter(Boolean).length;
  }

  getGuaranteedInd(values) {
    const nonEmptyValues = values.filter(Boolean);
    return nonEmptyValues.length ? IconsKeys.guaranteed : '';
  }

  getExceptionInd(values) {
    const nonEmptyValues = values.filter(Boolean);
    return nonEmptyValues.length ? IconsKeys.circleFilled : '';
  }

  getCountAgg(values) {
    return values.filter(Boolean).length;
  }

  getSumAgg(values) {
    let sum = 0;
    values.forEach((val) => {
      sum += val ? val : 0;
    });
    return sum;
  }

  formatDecimal(number) {
    return number.value
      ? Math.floor(number.value)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : '';
  }

  getSpecialServicesDetail(params: any) {
    const total = {
      guaranteedInd: false,
      frzblInd: false,
      hazmatInd: false,
      headLoadInd: false,
      headLoadDetail: false,
      misloadInd: false,
    };

    if (params.children) {
      params.children.forEach((children2) => {
        _.forEach(total, (value, key) => {
          if (!!children2[key]) {
            total[key] = children2[key];
          }
        });
      });
    }
    return total;
  }

  getSpecialServices(params: any) {
    const total = {
      guaranteedInd: false,
      frzblInd: false,
      hazmatInd: false,
      headLoadInd: false,
      headLoadDetail: false,
      misloadInd: false,
    };

    if (params.children) {
      params.children.forEach((children2) => {
        children2.children.forEach((children3) => {
          _.forEach(total, (value, key) => {
            if (!!children3[key]) {
              total[key] = children3[key];
            }
          });
        });
      });
    }
    return total;
  }

  setupSidePanel(params) {
    if (params) {
      this.iframeUrl = this.externalUrlsService.parseUrl('linehaul') + params.load;
      this.laneData = {
        filterSic: this.selectedSic,
        selectedLane: params.lane,
        sidePanelId: params.load,
        iframeUrl: this.iframeUrl,
        ...params,
      };
      this.sidePanelData = {
        currentEventTmst: this.laneData.currentEventTmst,
        closeTmst: this.laneData.closeTmst,
        statusCd: this.laneData.statusCd,
        sidePanelId: this.laneData.sidePanelId,
        filterSic: this.selectedSic,
        laneData: this.laneData,
      };
    }
    this.openSidePanelDisplay();
  }

  openSidePanelDisplay() {
    if (this.sidePanelData) {
      const message = new SidePanelDataStructures(this.sidePanelData, this.sidePanelOpts, SidePanelStatus.OPENED);
      this.interactionService.sendDataSidePanel(message);
    }
  }

  getMoveModeCD(params: any) {
    const total = {
      exclusiveInd: false,
      supplementalInd: false,
      label: params.lane,
    };

    if (params.children) {
      params.children.forEach((children) => {
        total.exclusiveInd = total.exclusiveInd || children.moveMode === MoveModeCd.EXCLUSIVE;
        total.supplementalInd = total.supplementalInd || children.moveMode === MoveModeCd.SUPPLEMENTAL;
      });
    }
    return total;
  }

  formatCloseTo(params: any) {
    let closeTo = params.data.closeToSicCd;
    if (params.node.rowIndex === 0 && this.selectedSic === params.data.lane) {
      closeTo = '';
    } else {
      closeTo.length > 11 && (closeTo = closeTo.slice(0, 12) + '...');
    }
    return closeTo;
  }

  private checkDateandShift(sic, shift, planDate): Observable<boolean> {
    const selectedShift = shift.toUpperCase();
    const correctShift = shift && (selectedShift === 'O' || selectedShift === 'F'); // only enable add load icon for 'F' and 'O'
    if (!correctShift) {
      return of(false);
    }

    let correctDate: Observable<boolean>;
    if (selectedShift === 'F') {
      correctDate = this.checkDateforFAC(sic, planDate);
    } else {
      correctDate = this.checkDateforOutbound(sic, planDate);
    }

    return correctDate.pipe(shareReplay());
  }

  private checkDateforOutbound(sic, planDate): Observable<boolean> {
    return this.shiftService.isInOutboundShiftRange(planDate, sic, true);
  }

  private checkDateforFAC(sic, planDate): Observable<boolean> {
    return this.shiftService.isInFacShiftRange(planDate, sic, true);
  }

  private checkLaneLoadReqAccess(params): Observable<boolean> {
    const canSeePlannedData =
      this.authService.allowedToSeePlannedAlways() ||
      (this.authService.allowedToSeePlannedOnRelease() && this.allowableLoadsService.releaseStatus);
    const hasPlannedData =
      params.data.lane.toUpperCase() !== 'UNKNOWN' && params.data.planned && params.data.planned.loadCount > 0;
    const allowedToAddLR = this.authService.isAllowedToAddLoadRequest();

    return this.dateAndShiftValid$.pipe(
      map(
        (valid) =>
          valid && hasPlannedData && allowedToAddLR && !this.allowableLoadsService.isBypassModel && canSeePlannedData
      )
    );
  }

  private handleLoadReqAccess(currentGlobalFilter: GlobalFilters) {
    const differentDate =
      new Date(currentGlobalFilter.planDate).getTime() !== new Date(this.lastReqGlobalFilter.planDate).getTime();
    const differentShift = currentGlobalFilter.shift !== this.lastReqGlobalFilter.shift;
    const differentSic = currentGlobalFilter.sic !== this.lastReqGlobalFilter.sic;

    if (differentDate || differentShift) {
      this.dateAndShiftValid$ = this.checkDateandShift(
        currentGlobalFilter.sic,
        currentGlobalFilter.shift,
        currentGlobalFilter.planDate
      );
      this.lastReqGlobalFilter.shift = currentGlobalFilter.shift;
      this.lastReqGlobalFilter.planDate = currentGlobalFilter.planDate;
    }

    if (differentSic) {
      this.lastReqGlobalFilter.sic = currentGlobalFilter.sic;
      this.dateAndShiftValid$ = this.checkDateandShift(
        currentGlobalFilter.sic,
        currentGlobalFilter.shift,
        currentGlobalFilter.planDate
      );
    }
  }

  private handlePlannedColVisibility(colValue) {
    const showPlannedLoadCount = this.authService.allowedToSeePlannedAlways();
    const checkReleaseStatus =
      this.authService.allowedToSeePlannedOnRelease() && this.allowableLoadsService.releaseStatus;
    return checkReleaseStatus || showPlannedLoadCount || this.allowableLoadsService.isBypassModel ? colValue : '';
  }
}
