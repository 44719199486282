import { CdkDragEnd, DragRef } from '@angular/cdk/drag-drop';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PhotoTypeCd } from '@xpo-ltl/sdk-common';
import { LoadedTrailerSearchRecord } from '@xpo-ltl/sdk-linehauloperations';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery';
import { Subscription } from 'rxjs';
import { SidePanelConstants } from '../../side-panel-container-constants.component';
import { ImageGalleryService } from '../image-gallery-container-service.component';
import { ImageSearchRecordLabels } from './../enums/image-search-record-labels.enum';
import { SidePanelOpts } from './../enums/options.model';

@Component({
  selector: 'app-full-screen',
  templateUrl: './full-screen.component.html',
  styleUrls: ['./full-screen.component.scss'],
})
export class FullScreenComponent implements OnInit, OnDestroy {
  @ViewChild('ngxGallery', { static: false }) galleryComponent: NgxGalleryComponent;
  @ViewChild('ngxGallery', { read: ElementRef, static: false }) galleryElement: ElementRef;
  @ViewChild('hiddenDiv', { read: ElementRef, static: false }) hiddenDiv: ElementRef;
  @ViewChild('hiddenImg', { read: ElementRef, static: false }) hiddenImg: ElementRef;
  @Input() params: object;
  @Input() trailerStatus: string;
  @Input() containerData: LoadedTrailerSearchRecord;
  @Input() selectedImage: any;
  @Input() totalShipmentsCount: number;
  @Input() relatedProsByImage: any[];
  @Input() employeeName: string;
  @Input() closedByEmployeeId: string;
  @Input() panelOpts: SidePanelOpts;
  readonly PhotoTypeCd = PhotoTypeCd;
  galleryImages: NgxGalleryImage[] = [];
  selectedImageIndex: number;
  galleryOptions: NgxGalleryOptions[];
  dmsGalleryImages: any[];
  relatedProsList: any[];
  downloadingImages = false;
  zoomed: boolean = false;
  dragRef: DragRef = null;
  showGallery: boolean = false;
  sicCdTimeZone: any;
  loadingDMSImages: boolean = true;
  toolTipPros: any = [];
  selectedIndex: number;
  photoType: string;
  photoDesc: string;
  count: number = 1;
  imageActions;
  readonly dataUriRegex = new RegExp('data:image/(jpeg|png|tiff);base64');
  readonly ImageSearchRecordLabels = ImageSearchRecordLabels;
  imageSubscription: Subscription;

  constructor(
    private renderer: Renderer2,
    private timeService: XpoLtlTimeService,
    private sidePanelConstants: SidePanelConstants,
    private imageGalleryService: ImageGalleryService
  ) {
    this.imageSubscription = this.imageGalleryService
      .subscribeToIndex()
      .pipe()
      .subscribe((index) => {
        this.selectedIndex = index;
      });
  }

  ngOnInit() {
    this.imageGalleryService.resetZoom();
    this.dragRef && this.dragRef.reset();
    const [gallery_options] = this.sidePanelConstants.fullscreen_image_gallery_options;
    if (this.panelOpts.allowZoomAndRotate) {
      this.imageActions = [
        { icon: 'fa fa-search-plus', onClick: this.zoomIn.bind(this), titleText: 'Zoom in' },
        { icon: 'fa fa-search-minus', onClick: this.zoomOut.bind(this), titleText: 'Zoom out' },
        { icon: 'fa fa-undo', onClick: this.rotateLeft.bind(this), titleText: 'Rotate left' },
        { icon: 'fa fa-repeat', onClick: this.rotateRight.bind(this), titleText: 'Rotate right' },
      ];
    }
    this.galleryOptions = [
      {
        ...gallery_options,
        startIndex: this.selectedIndex,
        imageActions: this.imageActions,
      },
    ];

    const dmsIdImageTypes: string[] = [];
    const dmsIdImages: string[] = [];
    const images = this.containerData.images;

    images.forEach((img) => {
      dmsIdImages.push(img.fullPhotoDocumentId.toString());
      dmsIdImageTypes.push(
        img.fullPhotoDocumentType
          .toString()
          .replace('Optional[', '')
          .replace(']', '')
      );
    });

    this.timeService.timezoneForSicCd$(this.containerData.loadingSic).subscribe((timestamp) => {
      this.sicCdTimeZone = timestamp;
      this.imageGalleryService
        .loadImagesfromDMS(
          dmsIdImages,
          dmsIdImageTypes,
          this.galleryImages,
          this.selectedIndex,
          this.containerData,
          this.sicCdTimeZone
        )
        .then((resp) => {
          if (resp) {
            this.dmsGalleryImages = resp;
          }
        });
    });
    this.relatedProsList = this.relatedProsByImage[this.selectedIndex].pros;
  }

  imagesReady() {
    if (this.count === this.containerData.images.length) {
      this.photoType = this.dmsGalleryImages[this.selectedIndex].label;
      this.photoDesc = this.dmsGalleryImages[this.selectedIndex].description;
      this.galleryComponent.show(this.selectedIndex);
      this.loadingDMSImages = false;
    }
    this.count++;
  }

  onDragEnded(event: CdkDragEnd): void {
    this.dragRef = event.source._dragRef;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowRight':
        this.galleryComponent.showNext();
        break;
      case 'ArrowLeft':
        this.galleryComponent.showPrev();
        break;
    }
  }

  private zoomIn() {
    this.zoomed = true;
    this.imageGalleryService.zoomImg(
      this.renderer,
      this.hiddenImg.nativeElement,
      this.galleryElement.nativeElement,
      this.hiddenDiv.nativeElement,
      'in',
      this.selectedIndex
    );
  }

  private zoomOut() {
    this.zoomed = true;
    this.imageGalleryService.zoomImg(
      this.renderer,
      this.hiddenImg.nativeElement,
      this.galleryElement.nativeElement,
      this.hiddenDiv.nativeElement,
      'out',
      this.selectedIndex
    );
  }

  private rotateLeft() {
    const deg = this.imageGalleryService.getRotate(this.hiddenImg.nativeElement) - 90;
    const rotate = 'rotate(' + deg + 'deg)';
    this.renderer.setAttribute(
      this.hiddenImg.nativeElement,
      'src',
      this.imageGalleryService.getBackground(
        this.galleryElement.nativeElement,
        this.renderer,
        this.hiddenDiv.nativeElement,
        this.selectedIndex
      )
    );
    this.renderer.setStyle(this.hiddenImg.nativeElement, 'transform', rotate);
  }

  private rotateRight() {
    const deg = this.imageGalleryService.getRotate(this.hiddenImg.nativeElement) + 90;
    const rotate = 'rotate(' + deg + 'deg)';
    this.renderer.setAttribute(
      this.hiddenImg.nativeElement,
      'src',
      this.imageGalleryService.getBackground(
        this.galleryElement.nativeElement,
        this.renderer,
        this.hiddenDiv.nativeElement,
        this.selectedIndex
      )
    );
    this.renderer.setStyle(this.hiddenImg.nativeElement, 'transform', rotate);
  }

  downloadThis() {
    this.imageGalleryService.downloadThis(this.galleryImages[this.selectedIndex], 'fullscreen');
  }

  /**
   * Set current selected image.
   * @param selectedImage
   */
  onChangeImage(selectedImage: any) {
    this.selectedIndex = selectedImage.index;
    this.imageGalleryService.setIndex(selectedImage.index);
    this.selectedImage = { ...selectedImage, imageType: this.containerData.images[selectedImage.index].imageType };
    this.imageGalleryService.resetZoom();
    if (this.zoomed) {
      this.renderer.setStyle(
        this.imageGalleryService.getCurrentImgRef(this.galleryElement.nativeElement, selectedImage.index),
        'background-size',
        'contain'
      );
      this.renderer.setStyle(this.hiddenDiv.nativeElement, 'display', 'none');
      this.renderer.setStyle(this.hiddenDiv.nativeElement, 'transform', 'none');
      this.renderer.setStyle(this.hiddenDiv.nativeElement, 'width', '500px');
      this.renderer.setStyle(this.hiddenImg.nativeElement, 'transform', 'none');
    }
    this.relatedProsList = this.relatedProsByImage[this.selectedIndex].pros;
    this.dragRef && this.dragRef.reset();

    this.photoType = this.dmsGalleryImages[selectedImage.index].label;
    this.photoDesc = this.dmsGalleryImages[selectedImage.index].description;
  }

  handleEmailClick() {
    this.imageGalleryService.handleEmail(this.containerData, this.selectedIndex);
  }

  ngOnDestroy() {
    this.imageSubscription.unsubscribe();
  }
}
