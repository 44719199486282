import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';

export interface RegionAlerts {
  sic: string;
  model: number;
  bypass: number;
  gmisload: number;
  hss: number;
  totals: number;
}
export class ModelMetricsResp {
  /**
   * Mock for Metrics Alerts.
   */
  regionAlerts: RegionAlerts[];
}

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  private alertsSubject: BehaviorSubject<ModelMetricsResp> = new BehaviorSubject(undefined);
  alerts$ = this.alertsSubject.asObservable();

  constructor() {}

  mockService = {
    regionAlertsInstruction: () => {
      return of(this.mockResponse);
    },
  };

  getAlerts(): Observable<ModelMetricsResp> {
    return new Observable((observer) => {
      this.mockService.regionAlertsInstruction().subscribe(
        (response: ModelMetricsResp) => {
          this.alertsSubject.next(response);
          observer.next(response);
          observer.complete();
        },
        (error) => {
          observer.next(undefined);
          observer.complete();
        }
      );
    });
  }

  get mockResponse(): ModelMetricsResp {
    const data = new ModelMetricsResp();
    data.regionAlerts = [
      {
        sic: 'NAA',
        model: 4,
        bypass: 5,
        gmisload: 1,
        hss: 2,
        totals: 13,
      },
      {
        sic: 'NAG',
        model: 7,
        bypass: 1,
        gmisload: 5,
        hss: 4,
        totals: 24,
      },
    ];
    return data as ModelMetricsResp;
  }
}
