import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsHeaderComponent } from './details-header/details-header.component';
import { SchedulesComponent } from './schedules.component';

@NgModule({
  declarations: [SchedulesComponent, DetailsHeaderComponent],
  imports: [CommonModule],
  exports: [SchedulesComponent],
})
export class SchedulesModule {}
