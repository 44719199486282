import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { XpoBoardView, XpoTextInputFilter } from '@xpo/ngx-core-board';
import { XpoAgGridBoardViewTemplate } from '@xpo/ngx-core-board-ag-grid';
import { map as _map } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { loadsTableAvailableColumns } from './loads-table-available-columns';

@Injectable()
export class LoadsViewBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'LoadsView';
  static _clickSubject = new BehaviorSubject<any>(0);
  clickSubject$ = LoadsViewBoardTemplate._clickSubject.asObservable();

  constructor(private decimalPipe: DecimalPipe) {
    super({
      id: LoadsViewBoardTemplate.templateId,
      name: 'Loads',
      keyField: 'load',
      allowAdditional: false,
      availableColumns: loadsTableAvailableColumns(LoadsViewBoardTemplate, decimalPipe),
      availableFilters: [
        // new XpoTextInputFilter('lane', 'Move To Sic'),
        // new XpoTextInputFilter('closeToSicCd', 'Close To Sic'),
        // new XpoTextInputFilter('loadedCube', 'Utilization % : 0-50, 80'),
        // new XpoTextInputFilter('loadedWeight', 'Weight (lbs) : 0-10000, 15000'),
      ],
    });
  }

  createLoadsView(): XpoBoardView {
    return this.createView({
      closeable: false,
      criteria: {},
      id: 'LoadsView',
      name: 'Loads View',
    });
  }
}
