import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ErrorMessageParser, NotificationService } from '@xpo-ltl/data-api';
import { InfrastructureApiService, SendEmailRqst } from '@xpo-ltl/sdk-infrastructure';
import { of as ObservableOf } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { EmailFormFields } from '../email-form-fields.enum';
import { EmailUtils } from '../email-utils';
import { EmailConfig } from './email-config';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) config: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SendEmailComponent>,
    private infrastructureApi: InfrastructureApiService,
    private notificationService: NotificationService
  ) {
    this.emailConfig = this.mapConfig(config);
  }

  emailFormFields = EmailFormFields;
  userEmailFormGroup: FormGroup;
  sendingEmail = false;
  emailConfig: EmailConfig;
  emailSent: boolean = false;
  sendError = false;
  readonly emailRegExp = new RegExp('^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+.)?(xpo).com$');

  ngOnInit() {
    this.initializeForm();
  }

  hasError(error: string, field: EmailFormFields): boolean {
    return this.userEmailFormGroup.get(field).hasError(error) && !this.userEmailFormGroup.get(field).untouched;
  }

  cancelClicked() {
    this.dialogRef.close(false);
  }

  sendClicked() {
    const toastErrorFunc = (error) => {
      this.notificationService.showSnackBarMessage(`${ErrorMessageParser.parseMessage(error) || error}.`, {
        durationInMillis: 5000,
        status: 'error',
      });
    };

    this.sendingEmail = true;
    this.infrastructureApi
      .sendEmail(this.createEmail())
      .pipe(
        take(1),
        catchError((error) => {
          this.sendError = true;
          toastErrorFunc(error);
          return ObservableOf(undefined);
        }),
        finalize(() => (this.sendingEmail = false))
      )
      .subscribe((results) => {
        if (results) {
          this.emailSent = true;
          setTimeout(() => this.dialogRef.close(true), 1000);
        }
      });
  }

  private mapConfig(config: EmailConfig): EmailConfig {
    return {
      hasFromEmailInput: config.hasFromEmailInput || false,
      disableFromEmailInput: config.disableFromEmailInput || false,
      hasToEmailInput: config.hasToEmailInput || false,
      disableToEmailInput: config.disableToEmailInput || false,
      disableSubjectInput: config.disableSubjectInput || false,
      fromEmail: config.fromEmail || '',
      invalidCharacterPattern: config.invalidCharacterPattern || '',
      message: config.message || '',
      subject: config.subject || '',
      title: config.title || 'Email',
      toEmail: config.toEmail || '',

      attachment: config.attachment || null,
      trailerLink: config.trailerLink || '',
      trailerNbr: config.trailerNbr || '',
      beginDate: config.beginDate,
      endDate: config.endDate,
    };
  }

  private initializeForm() {
    this.userEmailFormGroup = this.formBuilder.group({
      [EmailFormFields.FROM_EMAIL]: new FormControl(
        {
          value: this.emailConfig.fromEmail,
          disabled: this.emailConfig.disableFromEmailInput,
        },
        [Validators.required]
      ),
      [EmailFormFields.TO_EMAIL]: new FormControl(
        {
          value: this.emailConfig.toEmail,
          disabled: this.emailConfig.disableToEmailInput,
        },
        [Validators.required, Validators.pattern(this.emailRegExp)]
      ),
      [EmailFormFields.SUBJECT]: new FormControl(
        {
          value: this.emailConfig.subject,
          disabled: this.emailConfig.disableSubjectInput,
        },
        [Validators.required, Validators.pattern(this.emailConfig.invalidCharacterPattern)]
      ),
      [EmailFormFields.MESSAGE]: new FormControl(
        {
          value: this.emailConfig.message,
          disabled: false,
        },
        [Validators.pattern(this.emailConfig.invalidCharacterPattern)]
      ),
    });
  }

  private createEmail(): SendEmailRqst {
    const formValue = this.userEmailFormGroup.getRawValue();

    return {
      emailMessage: {
        from: this.emailConfig.fromEmail,
        fromName: '',
        to: formValue[EmailFormFields.TO_EMAIL],
        subject: formValue[EmailFormFields.SUBJECT],
        messageBody: EmailUtils.generateMessageBody(
          this.emailConfig.trailerNbr,
          formValue[EmailFormFields.MESSAGE],
          this.emailConfig.attachment.base64Data,
          this.emailConfig.trailerLink
        ),
      },
      html: true,
    } as SendEmailRqst;
  }
}
