import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { ThemePalette, TooltipPosition } from '@angular/material';
import { isEmpty as _isEmpty } from 'lodash';
import { IconsKeys } from '../../../../enums/icons.enum';

@Component({
  selector: 'app-special-service-formatter-cell',
  styleUrls: ['./special-service-formatter.scss'],
  template: `
    <mat-icon *ngIf="guaranteedInd" [svgIcon]="IconsKeys.guaranteed" [class]="IconsKeys.guaranteed"></mat-icon>
    <mat-icon *ngIf="misloadInd" [svgIcon]="IconsKeys.misload" [class]="IconsKeys.misload"></mat-icon>
    <mat-icon *ngIf="hazmatInd" [svgIcon]="IconsKeys.hazmat" [class]="IconsKeys.hazmat"></mat-icon>
    <mat-icon
      *ngIf="(frzbleInd || freezable) && showHeadLoadPopover"
      [svgIcon]="IconsKeys.freezable"
      [class]="IconsKeys.freezable"
    ></mat-icon>
  `,
})
export class SpecialServiceFormatterComponent {
  params: any;
  guaranteedInd: boolean;
  hazmatInd: boolean;
  headLoadInd: boolean;
  frzbleInd: boolean;
  freezable: boolean;
  misloadInd: boolean;
  readonly IconsKeys = IconsKeys;
  pos: TooltipPosition = 'below';
  cPos: HorizontalConnectionPos | VerticalConnectionPos = 'center';
  color: ThemePalette = 'warn';
  popoverItems: string[] = [];
  showHeadLoadPopover: boolean = false;

  agInit(params: any): void {
    this.params = params;

    const { guaranteedInd, hazmatInd, frzbleInd, freezable, headLoadInd, misloadInd } = params.value;

    this.guaranteedInd = !!guaranteedInd;
    this.hazmatInd = !!hazmatInd;
    this.frzbleInd = !!frzbleInd;
    this.freezable = !!freezable;
    this.headLoadInd = !!headLoadInd;
    this.misloadInd = !!misloadInd;
  }
}
