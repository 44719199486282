import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule, MatIconModule, MatTabsModule } from '@angular/material';
import { XpoCardModule, XpoTabsModule } from '@xpo/ngx-core';
import { SharedModule } from './../../shared/shared.module';
import { AllowableLoadsModule } from './allowable-loads/allowable-loads.module';
import { DashboardComponent } from './dashboard.component';
import { SchedulesModule } from './schedules/schedules.module';
@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatChipsModule,
    XpoTabsModule,
    MatTabsModule,
    XpoCardModule,
    AllowableLoadsModule,
    SchedulesModule,
    SharedModule,
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
