import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatTabsModule } from '@angular/material';
import { XpoCardModule, XpoTabsModule } from '@xpo/ngx-core';
import { XpoBoardModule, XpoFiltersModule } from '@xpo/ngx-core-board';
import { SharedModule } from '../../shared/shared.module';
import { RealtimeComponent } from './realtime.component';

@NgModule({
  declarations: [RealtimeComponent],
  imports: [
    CommonModule,
    XpoCardModule,
    XpoBoardModule,
    XpoFiltersModule,
    XpoTabsModule,
    MatTabsModule,
    MatIconModule,
    SharedModule,
  ],
  entryComponents: [],
  providers: [],
})
export class RealtimeModule {}
