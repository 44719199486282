export class AlertCardComponentMock {
  mockAlert = {
    exception: {
      exceptionInstId: 507,
      priority: 'High',
      statusCd: 'New',
      postedDateTimeUtc: 1574376343000,
      resolvedDateTimeUtc: 1574376343000,
      nbrOfMinimumBeforeExpiry: 720,
      listActionCd: 'NoAction',
      auditInfo: {
        createdById: 'LTLAPP_USER',
        createdTimestamp: 1574376372060,
        updateById: 'LTLAPP_USER',
        updatedTimestamp: 1574376372061,
        createByPgmId: 'TrailerP',
        updateByPgmId: 'TrailerP',
      },
    },
    expectationHeader: {
      expectationInstId: 653,
      expectationDate: '2019-11-21',
      expectationSic: 'XAB',
      destinationSic: 'XUK',
      doorNbr: '0005',
      trailerId: 10000033,
      trailerNbr: 'CWBG-6440',
      listActionCd: 'NoAction',
      auditInfo: {
        createdById: 'LTLAPP_USER',
        createdTimestamp: 1574376371677,
        updateById: 'LTLAPP_USER',
        updatedTimestamp: 1574376371678,
        createByPgmId: 'TrailerP',
        updateByPgmId: 'TrailerP',
      },
      regionCd: 'XLH1',
      expectationDetail: [
        {
          expectationInstId: 653,
          expectationDetailSequenceNbr: 1,
          valueKey: 'TRLR_WGT',
          value: '7002.0',
          valueDateTimeUtc: 1574376343000,
          statusCd: 'Exception',
          listActionCd: 'NoAction',
          auditInfo: {
            createdById: 'LTLAPP_USER',
            createdTimestamp: 1574376371677,
            updateById: 'LTLAPP_USER',
            updatedTimestamp: 1574376371678,
            createByPgmId: 'TrailerP',
            updateByPgmId: 'TrailerP',
          },
        },
      ],
      expectationType: {
        typeCd: 'HSS_UNDER_WGT',
        typeDescription: 'Expectation Type Test Update',
        auditInfo: {
          createdById: 'SYSTEM',
          createdTimestamp: 1571352660326,
          updateById: 'LTLAPP_USER',
          updatedTimestamp: 1571774639501,
          createByPgmId: 'LOAD',
          updateByPgmId: 'LTLGENR',
        },
      },
    },
  };
  getAlerts() {
    return this.mockAlert;
    // return {
    //   alert: 'On Time',
    //   loadedBy: 'loadedBy',
    //   destSic: 'destSic',
    //   misloadedTo: 'misloadedTo',
    //   pro: 'pro',
    //   status: 'status',
    //   totalLoads: 'totalLoads',
    //   availableDsr: 'availableDsr',
    //   overages: 'overages',
    //   weight: 'weight',
    //   requestedBy: 'requestedBy',
    //   remainingLoad: 'remainingLoad',
    //   remainingWeight: 'remainingWeight',
    //   remainingCube: 'remainingCube',
    //   closeTo: 'closeTo',
    //   detail: 'detail',
    // };
  }
}
