import { Component, OnInit } from '@angular/core';
import { ExceptionAlert } from '@xpo-ltl/sdk-exceptionmanagement';
import {
  LinehaulOperationsApiService,
  ListMetricsPath,
  ListMetricsQuery,
  ListMetricsResp,
} from '@xpo-ltl/sdk-linehauloperations';
import { XpoDateFilter, XpoFilter, XpoFilterCriteria, XpoSingleSelectFilter } from '@xpo/ngx-core-board';
import { countBy as _countBy } from 'lodash';
import { chain as _chain } from 'lodash';
import { Subscription, timer } from 'rxjs';
import { ComponentsEnum } from '../../shared/enums/components.enum';
import { ActualAlerts } from '../../shared/models/actual-alerts';
import { AlertsService } from '../../shared/services/alerts.service';
import { AuthService } from '../../shared/services/auth.service';
import { InteractionService } from '../../shared/services/interaction.service';
import { RegionAlerts } from './metrics.service';
import { map, filter } from 'rxjs/operators';
import { GlobalFilters } from '../../shared/models/global-filters';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent implements OnInit {
  readonly filters: XpoFilter[];
  alertsSubscription: Subscription;
  summarySubscription: Subscription;
  criteriaObj: XpoFilterCriteria = {};
  searchingAlerts: boolean = false;
  searchingSummary: boolean = false;
  unresolvedAlerts: ExceptionAlert[] = [];
  alertsToShow: RegionAlerts[] = [];
  actualAlerts: ActualAlerts;
  summaryData = [];
  allowUser;

  constructor(
    private alertsService: AlertsService,
    private interactionService: InteractionService,
    private linehaulOpApiService: LinehaulOperationsApiService,
    private authService: AuthService
  ) {
    timer(1000).subscribe(() => {
      this.allowUser = this.authService.getPerms('metrics').metrics;
    });
    this.filters = this.getFilters();
    this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(
        map((resp) => resp.data),
        filter((data: GlobalFilters) => !!data.region && !!data.shift && !!data.planDate)
      )
      .subscribe(({ sic, region, shift, planDate }) => {
        const globalFilters = { sic, region, shift, planDate };
        globalFilters.planDate = new Date(planDate);
        this.searchingAlerts = true;
        this.searchingSummary = true;
        this.alertsToShow = [];
        this.getAlerts(globalFilters);

        if (region) {
          // this.alertsService.getAlerts(globalFilters);

          this.summarySubscription = this.getSummaryData(region, shift, globalFilters.planDate).subscribe(
            (response: ListMetricsResp) => {
              this.summaryData = response && response.summaryMetric[0].sicCd ? response.summaryMetric : [];
              this.searchingSummary = false;
              this.searchingAlerts = false;
            }
          );
        } else {
          this.searchingAlerts = false;
          this.searchingSummary = false;
        }
      });
  }

  ngOnInit() {
    this.searchingAlerts = false;
    this.searchingSummary = false;
  }

  private getAlerts(globalFilters, exceptionType = null, sicCd = null, showExpired = false) {
    this.alertsService.getAlerts(globalFilters, exceptionType, sicCd, showExpired).subscribe((response) => {
      this.searchingAlerts = true;
      if (response && this.allowUser) {
        this.alertsToShow = _chain(response.unresolvedAlerts)
          .groupBy('expectationHeader.expectationSic')
          .map((value, key) => ({
            sic: key,
            hss: this.getTypeCount(value, 'HSS_UNDER_WGT'),
            model: 0,
            bypass: 0,
            gmisload: this.getTypeCount(value, 'MISLOAD_GUR') + this.getTypeCount(value, 'GUR_LNH_MSLD'),
            totals: 0,
          }))
          .value()
          .filter((value) => {
            return value.gmisload || value.hss;
          });
        this.searchingAlerts = false;
      } else {
        this.searchingAlerts = false;
      }
    });
  }

  private getSummaryData(region, shift, planDate) {
    const metricParams = new ListMetricsPath();

    metricParams.shiftCd = shift;

    metricParams.planDate = planDate;
    const metricQuery = new ListMetricsQuery();
    metricQuery.regionCd = region;

    return this.linehaulOpApiService.listMetrics(metricParams, metricQuery, { toastOnError: false });
  }

  getTypeCount(alerts: ExceptionAlert[], type: string) {
    const count = _countBy(alerts, function(value) {
      return value.expectationHeader.expectationType.typeCd === type;
    });
    return count.true || 0;
  }

  private getFilters(): XpoFilter[] {
    return [
      new XpoSingleSelectFilter('shift', 'Shift', [
        { title: 'All', code: '1' },
        { title: 'Outbound', code: '2' },
        { title: 'Inbound', code: '3' },
      ]),
      new XpoDateFilter('f5', 'Date', {
        minDate: this.getLastTwoWeeks(),
        maxDate: new Date(),
      }),
    ];
  }

  getLastTwoWeeks(): Date {
    const lastTwoWeeks = new Date();
    lastTwoWeeks.setDate(lastTwoWeeks.getDate() - 15);
    return lastTwoWeeks;
  }
}
