import { Component } from '@angular/core';

@Component({
  selector: 'app-modified-by-formatter-cell',
  template: `
    <span *ngIf="params.valueFormatted">{{ params.valueFormatted }}</span>
    <span *ngIf="!params.valueFormatted && params.value !== 'Multiple'">{{ params.value }}</span>
    <strong *ngIf="params.value === 'Multiple'">{{ params.value }}</strong>
  `,
})
export class ModifiedByFormatterComponent {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }
}
