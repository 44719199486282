import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ModelGroupCd } from '@xpo-ltl/sdk-common';
import { GetEmployeeDetailsByEmpIdPath, HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';
import {
  ChangesContent,
  LinehaulOperationsApiService,
  ListChangesForLanesPath,
  ListChangesForLanesResp,
  ListLoadRequestsResp,
  ListModelInstructionsPath,
  ListModelInstructionsQuery,
  ListModelInstructionsResp,
  LoadLaneInstructionSummary,
  LoadRequest,
  MoveLaneInstructionSummary,
  TrailerLoadInfo,
  UpsertLoadRequestRqst,
} from '@xpo-ltl/sdk-linehauloperations';
import {
  ListLocationGroupCodesQuery,
  ListLocationGroupCodesResp,
  LocationApiService,
  LocationGroupCode,
} from '@xpo-ltl/sdk-location';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { XpoBoardData, XpoBoardState } from '@xpo/ngx-core-board';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { catchError, filter, map, mergeMap, share, shareReplay, take, tap } from 'rxjs/operators';

import { XpoSnackBar } from '@xpo/ngx-core';
import { AllowableLoadsTabsEnum } from '../../../../shared/enums/allowable-loads-tabs.enum';
import { ComponentsEnum } from '../../../../shared/enums/components.enum';
import { InteractionServiceResp } from '../../../../shared/models/interaction-service-response.model';
import { LaneDataType } from '../../../../shared/models/lane-data-type';
import { InteractionService } from '../../../../shared/services/interaction.service';
import { LaneDataTypesService } from '../../../../shared/services/lane-data-types.service';
import { UserRoleService } from '../../../../shared/services/user-role/user-role.service';
import { EnumUtil } from '../../../../utils/enum-utils';
// TODO  error in changes

import { ListModelInstructionsResponse } from '../models/list-model-instruction-resp.model';

@Injectable({ providedIn: 'root' })
export class AllowableLoadsService implements OnDestroy {
  private planDate: any;
  private sic: string;
  private shift: string;
  private region: string;
  private boardDataAux: XpoBoardData;
  private nameByID: Observable<string>[] = [];
  private namesList: { [key: string]: string } = {};
  private interactionSubscription: Subscription;
  private lastPlanDate: string;
  private lastShift: string = '';
  private requestFullName: { [key: string]: Observable<string> } = {};
  isBypassModel: boolean = false;
  releaseStatus: boolean = false;
  releaseStatusEnum = { RELEASED: 'Released' };

  savedFiltersChanges = []; // REFACTOR what is this?
  returnSingleLane: boolean = false; // REFACTOR what is this?

  constructor(
    private linehaulOpApiService: LinehaulOperationsApiService,
    private locationApiService: LocationApiService,
    private interactionService: InteractionService,
    private humanResourceApiService: HumanResourceApiService,
    private laneDataTypesService: LaneDataTypesService,
    private loggingApiService: LoggingApiService,
    private userRoleService: UserRoleService,
    private xpoSnackBar: XpoSnackBar,
    datePipe: DatePipe
  ) {
    this.interactionSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(filter((resp: InteractionServiceResp) => resp.data && Object.keys(resp.data).length > 0))
      .subscribe((resp: InteractionServiceResp) => {
        this.sic = resp.data.sic;
        // filters.planDate
        this.planDate = datePipe.transform(resp.data.planDate, 'yyyy-MM-dd');
        // filters.region &&
        this.region = resp.data.region;
        this.shift = resp.data.shift.substring(0, 1);
      });
  }

  getListChangesForLanes(state: XpoBoardState): Observable<XpoBoardData> {
    const emptyBoard = new XpoBoardData(state, [], 0, 0);
    const queryParams: ListChangesForLanesPath = {
      currentSicCd: this.sic,
      currentShiftCd: this.shift,
      runDate: this.planDate as any,
    };

    this.savedFiltersChanges = [this.sic, this.planDate, this.shift];
    // TODO remove 'toastOnError after demo
    if (this.sic) {
      return this.linehaulOpApiService.listChangesForLanes(queryParams, { toastOnError: false }).pipe(
        map((resp: ListChangesForLanesResp) => {
          if (resp.changesContents && resp.changesContents.length) {
            resp.changesContents.forEach((value) => {
              const userId = value.modifiedBy;
              this.getEmployeeName(value.modifiedBy, userId, value);
            });
            return new XpoBoardData(state, resp.changesContents, resp.changesContents.length, 50);
          } else {
            return emptyBoard;
          }
        }),
        catchError((err) => {
          console.log(err);
          return of(emptyBoard);
        })
      );
    } else {
      return of(emptyBoard);
    }
  }

  getListModelInstructions(
    state: XpoBoardState,
    type: string,
    lane: XpoBoardData,
    refreshing: boolean,
    sic?: string
  ): Observable<XpoBoardData> {
    const emptyBoard = new XpoBoardData(state, [], 0, 0);
    if (sic) {
      this.sic = sic;
    }

    // apply filters on existing data if any - doing this on the frontend for now for loads
    if (state.criteria && Object.values(state.criteria).some((v) => v)) {
      // Unreachable code - begin
      const filteredData = this.filterBoardData(state, this.boardDataAux);
      return filteredData.length ? of(new XpoBoardData(state, filteredData, filteredData.length, 50)) : of(emptyBoard);
      // Unreachable code - end
    }

    if (this.sic) {
      const lastSearchObject = JSON.parse(sessionStorage.getItem('lastAlloableLoadsResponse'));
      if (refreshing) {
        return this.generateBoard(emptyBoard, state, lane, type);
      } else {
        if (
          lastSearchObject &&
          lastSearchObject.sic &&
          this.lastShift.includes(this.shift) &&
          (lastSearchObject.sic === this.sic && this.lastPlanDate === this.planDate)
        ) {
          return of(
            type === AllowableLoadsTabsEnum.LANES
              ? this.setDataType(lastSearchObject.lanes, state, lane)
              : this.setDataType(lastSearchObject.loads, state, lane)
          );
        } else {
          return this.generateBoard(emptyBoard, state, lane, type);
        }
      }
    } else {
      return of(emptyBoard);
    }
  }

  upsertLoadRequest(loadRequestData: any, laneData): Observable<boolean> {
    const upsertLoadRequest = new UpsertLoadRequestRqst();
    const loadRequest = new LoadRequest();
    loadRequest.requestType = loadRequestData.requestType;
    loadRequest.requestQuantity = loadRequestData.numberOfLoads;
    loadRequest.reasonCd = loadRequestData.reason;
    loadRequest.requestorComments = loadRequestData.comment;
    loadRequest.loadToSicCode = loadRequestData.closeTo;
    loadRequest.moveToSicCode = laneData.lane;
    loadRequest.planDate = this.planDate;
    loadRequest.regionCd = this.region;
    loadRequest.planShiftCd = this.shift;
    loadRequest.originSicCode = this.sic;
    loadRequest.requestorName = `${this.userRoleService.user.givenName} ${this.userRoleService.user.lastName}`;
    loadRequest.requestorEmplid = this.userRoleService.user.employeeId;

    upsertLoadRequest.loadRequest = loadRequest;

    // REFACTOR review this
    return this.linehaulOpApiService.upsertLoadRequest(upsertLoadRequest).pipe(
      catchError((err) => {
        this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].message}`);
        return of(false);
      }),
      map((response: ListLoadRequestsResp) => {
        if (response) {
          this.loggingApiService.info(
            'At SIC -  ' + this.sic + ' - Lane: ' + laneData.lane + '- Loads: ' + loadRequestData.loadsNeeded,
            'Additional Load Requests',
            'At SIC - ' +
              this.sic +
              ' - Lane: ' +
              laneData.lane +
              ' - Shift: ' +
              EnumUtil.toShiftCodeValue(this.shift) +
              '- Additonal Loads Requested : ' +
              loadRequestData.loadsNeeded
          );
          return true;
        } else {
          return false;
        }
      })
    );
  }

  private getEmployeeName(empId: string, userId: string, row: ChangesContent) {
    const pathParams = new GetEmployeeDetailsByEmpIdPath();
    pathParams.employeeId = empId;

    if (this.requestFullName[userId]) {
      this.requestFullName[userId].subscribe((fullName: string) => {
        this.namesList[userId] = fullName;
        row['modifiedByName'] = fullName;
      });
    } else {
      this.requestFullName[userId] = this.humanResourceApiService
        .getEmployeeDetailsByEmpId(pathParams, null, { loadingOverlayEnabled: false })
        .pipe(
          take(1),
          shareReplay(1),
          map((res) => {
            return `${res.employee.basicInfo.firstName} ${res.employee.basicInfo.lastName}`;
          })
        );

      this.requestFullName[userId].subscribe((fullName) => {
        this.namesList[userId] = fullName;
        row['modifiedByName'] = fullName;
      });
    }
  }

  private getGroupCodeFromTimezone(timezone: string): ModelGroupCd {
    const modelGroups = {
      PST: ModelGroupCd.WESTERN,
      CST: ModelGroupCd.CENTRAL,
      EST: ModelGroupCd.EASTERN,
    };
    return modelGroups[timezone];
  }

  private generateBoard(
    emptyBoard: XpoBoardData,
    state: XpoBoardState,
    lane: XpoBoardData,
    type: string
  ): Observable<XpoBoardData> {
    return this.getModelGroupCode(this.sic).pipe(
      map((resp: ListLocationGroupCodesResp) => resp.locationGroupCodes),
      mergeMap((modelGroupCode: LocationGroupCode[]) => {
        const modelGroupCd = this.getGroupCodeFromTimezone(modelGroupCode[0].regionCd);
        return this.getLaneInstructions(this.sic, this.planDate, this.shift, modelGroupCd).pipe(
          map((instr: ListModelInstructionsResponse) => {
            if (instr) {
              this.releaseStatus = instr.releaseStatus === this.releaseStatusEnum.RELEASED;
              const flattenedData = this.transformApiData(instr.moveLaneInstructionSummary);
              const transformedLaneData = this.transformLaneInstructionsData(instr.moveLaneInstructionSummary);

              const isBypassmodel = instr.moveLaneInstructionSummary.some(
                (instrr) =>
                  !!instrr.instructionSummary.sourceCd &&
                  (instrr.instructionSummary.sourceCd.toUpperCase() === 'B' ||
                    instrr.instructionSummary.sourceCd.toUpperCase() === 'E')
              );
              this.isBypassModel = isBypassmodel;

              this.interactionService.setALAmount(transformedLaneData.length, AllowableLoadsTabsEnum.LANES);
              const result = { sic: this.sic, lanes: transformedLaneData, loads: flattenedData };
              sessionStorage.setItem('lastAlloableLoadsResponse', JSON.stringify(result));
              this.lastPlanDate = this.planDate;
              this.lastShift = this.shift;
              return type === AllowableLoadsTabsEnum.LANES
                ? this.setDataType(transformedLaneData, state, lane)
                : this.setDataType(flattenedData, state, lane);
            } else {
              return emptyBoard;
            }
          }),
          catchError((err) => {
            console.log(err);
            return of(emptyBoard);
          })
        );
      })
    );
  }

  private setDataType(tableData, state: XpoBoardState, lane?: XpoBoardData): XpoBoardData {
    const orderedData = tableData.length ? this.orderData(tableData, lane) : tableData;
    const boardDataAux = new XpoBoardData(state, orderedData, orderedData.length, 50);
    return boardDataAux;
  }

  private orderData(data, lane?: XpoBoardData) {
    return lane
      ? data.filter((l) => l.lane === lane)
      : data.filter((l) => l.lane === this.sic).concat(data.filter((l) => l.lane !== this.sic));
  }

  // Unreachable code - begin
  private filterBoardData(state: XpoBoardState, boardData: XpoBoardData): any {
    const filteredBoardData = [];

    Object.values(boardData.consumerData).forEach((data) => {
      let same = true;
      for (const comp in state.criteria) {
        if (state.criteria[comp].includes('-')) {
          if (same) {
            const twoNumbers = state.criteria[comp].split('-');
            same = Number(data[comp]) >= Number(twoNumbers[0]) && Number(data[comp]) <= Number(twoNumbers[1]);
          }
        } else {
          if (same) {
            same = String(data[comp]).includes(comp.toUpperCase());
          }
        }
      }
      if (same) {
        filteredBoardData.push(data);
      }
    });
    return filteredBoardData;
  }
  // Unreachable code - end

  private transformLaneInstructionsData(apiData: MoveLaneInstructionSummary[]) {
    const apiTransformedData = apiData
      ? apiData.map((data: MoveLaneInstructionSummary) => {
          const laneData = data.instructionSummary; // lane
          const loadData = data.loadLaneInstructionSummary; // loads for the above lane
          const closeToSicList = [];
          const closeToSicsWithPlannedLoadCount = [];
          const loadsList = loadData.map((load: LoadLaneInstructionSummary) => {
            let childTrailers: LaneDataType[] = [];
            const loadInstr = load.loadLaneInstructionSummary;
            closeToSicList.push(load.loadLaneInstructionSummary.closeToSicCd);
            if (!!load.loadLaneInstructionSummary.planned && !!load.loadLaneInstructionSummary.planned.loadCount) {
              const closeTo = load.loadLaneInstructionSummary.closeToSicCd;
              const plannedForCloseTo = load.loadLaneInstructionSummary.planned.loadCount;
              closeToSicsWithPlannedLoadCount.push({ closeTo: closeTo, plannedCount: plannedForCloseTo });
            }
            if (load.trailerLoadInfo) {
              load.trailerLoadInfo.forEach((trailer: TrailerLoadInfo) => {
                const tra: LaneDataType = {
                  filterSic: this.sic,
                  lane: load.loadLaneInstructionSummary.lane,
                  closeToSicCd: load.loadLaneInstructionSummary.closeToSicCd,
                  load: `${trailer.trailerLoadHist.equipmentIdPrefix.replace(/^0/, '')}-${String(
                    trailer.trailerLoadHist.equipmentIdSuffixNbr
                  ).padStart(4, '0')}`,
                  equipmentInstId: trailer.trailerLoadHist.equipmentId,
                  guaranteedInd: trailer.trailerLoadHist.guaranteedServiceInd,
                  hazmatInd: trailer.trailerLoadHist.hazmatInd,
                  frzblInd: trailer.trailerLoadHist.frzblInd,
                  ptlTrlrInd: trailer.ptlTrlrInd,
                  bypassInd: trailer.bypassTrlrInd,
                  loadedTimestamp: trailer.trailerLoadHist.loadedDateTime,
                  // update after api's has them
                  hssLoadCount: laneData['hssLoadCount'],
                  bypassLoadCount: laneData.bypassLoadCount,
                  loadedWeight: trailer.trailerLoadHist.loadedWeight,
                  loadedCube: trailer.trailerLoadHist.loadedCbePercentage,
                  exception: trailer.exceptionInd,
                  sourceCd: trailer.source,
                  statusCd: trailer.trailerLoadHist.currentStatus,
                  headLoadInd: !!trailer.headLoadDetail && !!Object.entries(trailer.headLoadDetail).length,
                  headLoadDetail: trailer.headLoadDetail,
                  loadedShipment: trailer.loadedShipment,
                  misloadInd: trailer.loadedShipment ? !!trailer.loadedShipment.find((shp) => shp.misloadInd) : false,
                  eventDoor: trailer.trailerLoadHist.eventDoor,
                  originSic: trailer.trailerLoadHist.originSic,
                  currentEventTmst: trailer.trailerLoadHist.currentEventDateTime,
                  closeTmst: trailer.trailerLoadHist.closeDateTime,
                };
                childTrailers.push(tra);
              });
            }
            if (childTrailers.length) {
              const trailersFiltered = [
                ...this.filterByCondition('Loading', childTrailers),
                ...this.filterByCondition('Closed', childTrailers),
                ...this.filterByCondition('Overhead', childTrailers),
                ...this.filterByCondition('Enroute', childTrailers),
              ];
              childTrailers = trailersFiltered;
            }
            if (!childTrailers.length) {
              const emptyData = this.laneDataTypesService.getNoDataStructure();
              childTrailers.push(emptyData);
            }
            return { ...loadInstr, children: childTrailers };
          });
          laneData.closeToSicCd = closeToSicList.toLocaleString();
          laneData['closeToSicsWithPlannedLoadCount'] = closeToSicsWithPlannedLoadCount;
          return { ...laneData, children: loadsList };
        })
      : [];
    // move 'unknown' lanes to the last
    return apiTransformedData
      .filter((lane) => lane.lane.toUpperCase() !== 'UNKNOWN')
      .concat(apiTransformedData.filter((lane) => lane.lane.toUpperCase() === 'UNKNOWN'));
  }

  private filterByCondition(status: string, childTrailers: LaneDataType[]) {
    return childTrailers.filter((trailer) => trailer.statusCd === status);
  }

  private getLaneInstructions(
    sicCd: string,
    plannedDate: Date,
    shift: string,
    modelGroupCd: ModelGroupCd
  ): Observable<ListModelInstructionsResp> {
    const laneRequest = new ListModelInstructionsQuery();
    const queryPath = new ListModelInstructionsPath();
    // REFACTOR laneRequest.sector = sector; variable sector was removed because it was always undefined
    queryPath.sicCd = sicCd;
    queryPath.plannedDate = plannedDate;
    queryPath.shiftCd = shift.slice(0, 1);
    queryPath.modelGroupCd = modelGroupCd;
    // TODO remove 'toastOnError after demo
    return this.linehaulOpApiService.listModelInstructions(queryPath, laneRequest, { toastOnError: false }).pipe(
      share(),
      tap(() =>
        this.loggingApiService.info(
          sicCd + ' - Shift: ' + shift,
          'List Model Instructions',
          sicCd + ' - ' + EnumUtil.toShiftCodeValue(shift) + ' - ' + plannedDate
        )
      ),
      catchError((err) => of(undefined))
    );
  }

  // REFACTOR TODO Review (helperLoc is not used anywhere)
  private getModelGroupCode(sic: string): Observable<ListLocationGroupCodesResp> {
    const getModelGroupCodePath = new ListLocationGroupCodesQuery();
    getModelGroupCodePath.sicCd = sic;
    getModelGroupCodePath.groupCategoryCd = 'LHO_MODEL_GRP';

    return this.locationApiService.listLocationGroupCodes(getModelGroupCodePath).pipe(share());
  }

  private transformApiData(apiData: MoveLaneInstructionSummary[]): LaneDataType[] {
    return apiData
      .map((data: MoveLaneInstructionSummary) => {
        const laneData = data.instructionSummary; // lane
        const loadData = data.loadLaneInstructionSummary; // loads for the above lane

        delete laneData.closeToSicCd; // remove from lane level and use list of close to
        // transformedData.push(laneData);

        return loadData
          .filter((load: LoadLaneInstructionSummary) => load.trailerLoadInfo)
          .map((load: LoadLaneInstructionSummary) => {
            const loadLaneInstrSumm = load.loadLaneInstructionSummary;
            loadLaneInstrSumm['lane'] = laneData.lane;
            // transformedData.push(loadLaneInstrSumm);
            return load.trailerLoadInfo.map((trailer) => {
              return {
                filterSic: this.sic,
                lane: load.loadLaneInstructionSummary.lane,
                closeToSicCd: load.loadLaneInstructionSummary.closeToSicCd,
                load: `${trailer.trailerLoadHist.equipmentIdPrefix.replace(/^0/, '')}-${
                  trailer.trailerLoadHist.equipmentIdSuffixNbr
                }`,
                equipmentInstId: trailer.trailerLoadHist.equipmentId,
                guaranteedInd: trailer.trailerLoadHist.guaranteedServiceInd,
                hazmatInd: trailer.trailerLoadHist.hazmatInd,
                frzblInd: trailer.trailerLoadHist.frzblInd,
                // update after api's has them
                hssLoadCount: laneData['hssLoadCount'],
                bypassLoadCount: laneData.bypassLoadCount,
                ptlTrlrInd: trailer.ptlTrlrInd,
                bypassInd: trailer.bypassTrlrInd,
                loadedWeight: trailer.trailerLoadHist.loadedWeight,
                loadedCube: trailer.trailerLoadHist.loadedCbePercentage,
                exception: trailer.exceptionInd,
                sourceCd: trailer.source,
                statusCd: trailer.trailerLoadHist.currentStatus,
                headLoadInd: !!trailer.headLoadDetail && !!Object.entries(trailer.headLoadDetail).length,
                headLoadDetail: trailer.headLoadDetail,
                loadedShipment: trailer.loadedShipment,
                misloadInd: trailer.loadedShipment ? !!trailer.loadedShipment.find((shp) => shp.misloadInd) : false,
                eventDoor: trailer.trailerLoadHist.eventDoor,
                currentEventTmst: trailer.trailerLoadHist.currentEventDateTime,
                closeTmst: trailer.trailerLoadHist.closeDateTime,
                originSic: trailer.trailerLoadHist.originSic,
                loadedTimestamp: trailer.trailerLoadHist.loadedDateTime,
              } as LaneDataType;
            });
          })
          .reduce((previous: LaneDataType[], current: LaneDataType[]) => previous.concat(current), []);
      })
      .reduce((previous: LaneDataType[], current: LaneDataType[]) => previous.concat(current), []);
  }

  ngOnDestroy() {
    this.interactionSubscription.unsubscribe();
  }
}
