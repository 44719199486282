import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadRequestPanelDataService {
  private data: any;

  constructor() {}

  setPanelData(data) {
    this.data = data;
  }

  getPanelData() {
    return this.data;
  }
}
