export enum InteractionEventsEnum {
  GLOBAL_FILTERS_UPDATED = 'globalFiltersUpdated',
  ALERT_FILTERS_UPDATED = 'alertFiltersUpdated',
  METRICS_UPDATED = 'metricsUpdated',
  ALL_UPDATED = 'allUpdated',
  REGION_UPDATED = 'regionUpdated',
  SIC_UPDATED = 'sicUpdated',
  SHIFT_UPDATED = 'shiftUpdated',
  SIDE_PANEL_UPDATED = 'sidePanelUpdated',
  LOAD_PANEL_UPDATED = 'loadPanelUpdated',
  LANES_LENGTH_UPDATED = 'Lanes',
  ALERTS_LIST = 'AlertsList',
  ALERT_LANE = 'AlertLane',
}
