import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(value: any[], column: string, order: any): any[] {
    let iteratee = [];
    if (column !== '') {
      iteratee = [column];
    }
    if (!value || !order || !value.length) {
      return value;
    }
    return _.orderBy(value, iteratee, order);
  }
}
