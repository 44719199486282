import { Injectable } from '@angular/core';
import { ComponentInitCallbackFactory, GoldenLayoutService } from '@embedded-enterprises/ng6-golden-layout';
import * as GoldenLayout from 'golden-layout';
import { Subject } from 'rxjs';

/**
 * Extend the base GoldenLayoutService to expose the GoldenLayout object
 * To use this, provide it in the app.module like:
 * {
 *    provide: GoldenLayoutService,
 *    useClass: GoldenLayoutExtService
 * }
 */

@Injectable()
export class GoldenLayoutExtService extends GoldenLayoutService {
  get goldenLayout(): GoldenLayout {
    return this['_layout'] as GoldenLayout;
  }

  private initializedSubject = new Subject<void>();
  readonly initialized$ = this.initializedSubject.asObservable();

  private componentCreatedSubject = new Subject<GoldenLayout.ContentItem>();
  readonly componentCreated$ = this.componentCreatedSubject.asObservable();

  private componentDestroyedSubject = new Subject<GoldenLayout.ContentItem>();
  readonly componentDestroyed$ = this.componentDestroyedSubject.asObservable();

  initialize(goldenLayout: GoldenLayout, componentInitCallbackFactory: ComponentInitCallbackFactory) {
    super.initialize(goldenLayout, componentInitCallbackFactory);

    this.goldenLayout.on('componentCreated', (component) => {
      this.componentCreatedSubject.next(component);
    });

    this.goldenLayout.on('itemDestroyed', (item) => {
      if (item.type === 'component') {
        this.componentDestroyedSubject.next(item);
      }
    });

    this.goldenLayout.on('initialised', () => {
      this.initializedSubject.next();
      this.initializedSubject.complete();
    });
  }
}
