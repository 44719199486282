import { Injectable, OnDestroy } from '@angular/core';
import { XrtAttributeFilter } from '@xpo-ltl/sdk-common';
import {
  ExceptionManagementApiService,
  ExpectationSearchFilter,
  RegisterFilterExpectationUnfilteredResp,
  RegisterFilterExpectationUnfilteredRqst,
  UnregisterFilterExpectationUnfilteredRqst,
} from '@xpo-ltl/sdk-exceptionmanagement';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { XrtChangedDocuments } from '@xpo/ngx-xrt';
import { XrtFireMessagingService, XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { FilterParams } from '../../shared/interfaces/firebase-filters.interface';
import { UserRoleService } from '../../shared/services/user-role/user-role.service';

@Injectable({ providedIn: 'root' })
export class AlertsMessagingService implements OnDestroy {
  private currentFilterHash = '';
  private currentFilterParams: FilterParams = undefined;
  private unsubscriber = new Unsubscriber();
  private elementsUpdatedCountSubject = new BehaviorSubject<number>(0);
  get elementsUpdatedCount$() {
    return this.elementsUpdatedCountSubject.asObservable();
  }
  private elementsUpdatedSubject = new BehaviorSubject(null);
  get elementsUpdated$() {
    return this.elementsUpdatedSubject.asObservable();
  }

  constructor(
    private messagingTokenService: XrtFireMessagingTokenService,
    private messagingService: XrtFireMessagingService,
    private userRoleService: UserRoleService,
    private exceptionManagementService: ExceptionManagementApiService
  ) {
    this.messagingService
      .changedDocument$()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((changedDocuments: XrtChangedDocuments) => {
        this.broadcastNumberOfElementsUpdated({
          filterHash: changedDocuments.filterHash,
          documents: JSON.parse(changedDocuments.documents.toString()),
        });
        this.broadcastElementsUpdated(changedDocuments);
      });

    new BroadcastChannel('linehaul-notification-broadcast-channel').onmessage = (event) => {
      this.broadcastNumberOfElementsUpdated({
        filterHash: event.data.data.filterHash,
        documents: JSON.parse(event.data.data.changedDocumentsJson),
      });
    };
  }

  private broadcastNumberOfElementsUpdated(changedDocuments: XrtChangedDocuments) {
    if (changedDocuments.filterHash === this.currentFilterHash) {
      this.elementsUpdatedCountSubject.next(changedDocuments.documents.length);
    }
  }

  private broadcastElementsUpdated(changedDocuments: XrtChangedDocuments) {
    if (changedDocuments.filterHash === this.currentFilterHash) {
      this.elementsUpdatedSubject.next(JSON.parse(changedDocuments.documents.toString()));
    }
  }

  private registerFilter(filterParameters): Promise<string> {
    return new Promise((resolve) => {
      this.messagingTokenService.getToken$().subscribe(
        (token) => {
          const request = new RegisterFilterExpectationUnfilteredRqst();
          request.connectionToken = token;
          request.filter = new ExpectationSearchFilter();
          request.filter.expectationSic = new XrtAttributeFilter();
          request.filter.expectationSic.equals = filterParameters.sic;
          this.exceptionManagementService
            .registerFilterExpectationUnfiltered(request, { toastOnError: false })
            .subscribe(
              (response: RegisterFilterExpectationUnfilteredResp) => {
                resolve(response.filterHash);
              },
              (error1) => {
                throw new Error(`Error ${error1}, filter not registered for Alerts!\``);
              }
            );
        },
        (error) => {
          throw new Error(`Error ${error.toString()}, Error!}`);
        }
      );
    });
  }

  private unregisterFilter(filterHash: string): void {
    if (filterHash) {
      this.messagingTokenService.getToken$().subscribe((token) => {
        const request = new UnregisterFilterExpectationUnfilteredRqst();
        request.connectionToken = token;
        request.userId = this.userRoleService.getUser().userId;
        request.filterHash = filterHash;

        this.exceptionManagementService
          .unregisterFilterExpectationUnfiltered(request, { toastOnError: false })
          .subscribe();
      });
    }
  }

  updateCurrentFilter(newFilterParams: FilterParams) {
    if (!newFilterParams.sic || !newFilterParams.planDate || !newFilterParams.shift) {
      return;
    }
    if (!_.isEqual(newFilterParams, this.currentFilterParams)) {
      this.unregisterFilter(this.currentFilterHash);
      this.registerFilter(newFilterParams)
        .then(
          (newHash: string | undefined) => {
            if (newHash) {
              this.currentFilterHash = newHash;
            }
          },
          function(error) {
            console.log(`Register Error! ${error}`);
          }
        )
        .catch((reason) => {
          console.log(`Register Error! ${reason}`);
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
