export class ManifestComponentMock {
  getTrailerContents() {
    return {
      trailerNumber: 'string',
      sicCd: 'string',
      loadDestination: 'string',
      doorNumber: 'string',
      sectorNumber: 1,
      cubeUtilizationPct: 1,
      weight: 1,
      trailerShiftCd: 'string',
      closeTmst: new Date(),
      trailerCurrentStatus: 'string',
      trailerLength: 1,
      currentEventTmst: new Date(),
      photoTakenTmst: new Date(),
      documentId: 1,
      fullPhotoDocumentId: 1,
      fullPhotoDocumentType: 'string',
      sequenceKey: 'string',
      loadedTrailerContents: [],
      origSic: 'string',
      currSic: 'string',
      moveToSic: 'string',
      loadWeight: 1,
      foreignScacCd: 'string',
      foreignRentalInd: 'string',
      closedTrailerTmst: new Date(),
      loadedBy: 'string',
    };
  }
}
