import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChangeViewService {
  private _changeAllowableLoadsView = new BehaviorSubject<string>('lanes-view');

  constructor() {}

  changeView($event) {
    this._changeAllowableLoadsView.next($event);
  }

  getCurrentView() {
    return this._changeAllowableLoadsView.asObservable();
  }
}
