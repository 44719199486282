import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AgGridEvent, ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-region-summary',
  templateUrl: './region-summary.component.html',
  styleUrls: ['./region-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegionSummaryComponent implements OnInit {
  @Input() summaryData;
  private gridApi: GridApi;
  private colApi: ColumnApi;

  gridOptions: GridOptions = {
    suppressHorizontalScroll: true,
    headerHeight: 40,
    rowStyle: { height: '40px !important' },
    defaultColDef: {
      cellStyle: { 'border-right': '1px solid #d8d8d8', height: '40px !important' },
      headerClass: 'headerStyle',
    } as ColDef,
  };

  columnDefs = [
    {
      headerName: 'SIC',
      field: 'sicCd',
      width: 100,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'DSRs Available',
      field: 'dsrsAvailable',
      width: 120,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'DSRs Required',
      field: 'dsrsRequired',
      width: 120,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'Loads',
      field: 'loadsPlanned',
      width: 100,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'Empties',
      field: 'empties',
      width: 100,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'Loads Over',
      field: 'loadsOver',
      width: 100,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'Double-turns',
      field: 'doubleTurns',
      width: 100,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'Load Factor',
      field: 'loadFactor',
      width: 100,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'TUC (%) / TU (%)',
      field: 'trailerUtilization',
      width: 140,
      suppressSizeToFit: false,
      suppressMenu: true,
      valueGetter: ({ data: { trailerUtilizationCapability, trailerUtilization } }) => {
        return `${trailerUtilizationCapability}% / ${trailerUtilization}%`;
      },
    },
    {
      headerName: 'Load Avg. Plan/Actual',
      field: 'loadAvgPlanActual',
      width: 160,
      suppressSizeToFit: false,
      suppressMenu: true,
      valueGetter: ({ data: { loadAvgPlanActual, loadAvgActual } }) => {
        return `${loadAvgPlanActual} / ${loadAvgActual}`;
      },
    },
    {
      headerName: 'Release Status',
      field: 'releaseStatus',
      width: 120,
      suppressSizeToFit: false,
      suppressMenu: true,
      headerClass: 'headerStyle-lastChild',
      cellStyle: { 'border-right': 'none', height: '40px !important' },
      valueFormatter: function(params) {
        return params.value && params.value === '0' ? 'Pending' : 'Released';
      },
    },
  ];

  constructor() {}

  ngOnInit() {}

  onGridReady(gridEvent: AgGridEvent) {
    this.gridApi = gridEvent.api;
    this.colApi = gridEvent.columnApi;
  }

  sizeChange() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }
}
