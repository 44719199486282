import { Injectable } from '@angular/core';
import { ListAllActiveSicsResp, LocationApiService } from '@xpo-ltl/sdk-location';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegionWithSics } from '../../models/region-with-sics.model';

@Injectable()
export class SicFilterService {
  private sicListByRegion = new BehaviorSubject<RegionWithSics[]>([]);
  currentSicList = this.sicListByRegion.asObservable();

  constructor(private locationApiService: LocationApiService) {}

  getAllSics(): Observable<ListAllActiveSicsResp> {
    return this.locationApiService.listAllActiveSics().pipe(
      map((resp: ListAllActiveSicsResp) => {
        return resp;
      })
    );
  }

  setSicList(sicList: RegionWithSics[]) {
    this.sicListByRegion.next(sicList);
  }
}
