import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PhotoTypeCd, XrtAttributeFilter } from '@xpo-ltl/sdk-common';
import {
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdResp,
  HumanResourceApiService,
} from '@xpo-ltl/sdk-humanresource';
import {
  LinehaulOperationsApiService,
  LoadedTrailerImageDetail,
  LoadedTrailerSearchFilter,
  LoadedTrailerSearchRecord,
  SearchLoadedTrailerImagesResp,
  SearchLoadedTrailerImagesRqst,
} from '@xpo-ltl/sdk-linehauloperations';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { EnumUtil } from './enums/enum-utils';
import { SidePanelOpts } from './enums/options.model';
import { SidePanelData } from './enums/side-panel-data.model';
@Component({
  selector: 'app-image-gallery-container',
  templateUrl: './image-gallery-container.component.html',
  styleUrls: ['./image-gallery-container.component.scss'],
})
export class ImageGalleryContainerComponent implements OnInit, OnChanges, SidePanelOpts {
  searchLoadedTrailerImagesRqst: SearchLoadedTrailerImagesRqst = new SearchLoadedTrailerImagesRqst();
  searchLoadedTrailerImagesResp: SearchLoadedTrailerImagesResp = new SearchLoadedTrailerImagesResp();
  slim: boolean = true;
  searchingImages = true;
  searchRan = false;
  unsubscribe: Subject<void> = new Subject();
  @Input() fullscreen: boolean;
  @Input() sidePanelData: SidePanelData;
  @Input() panelOpts: SidePanelOpts;
  laneData;
  loadedTrailerSearchRecord: LoadedTrailerSearchRecord;
  trailerImages = [];
  galleryImages: any[];
  selectedImage: LoadedTrailerImageDetail;
  totalShipmentsCount: number = 0;
  trailerStatus: string;
  selectedImageIndex: number = 0;
  readonly PhotoTypeCd = PhotoTypeCd;
  noResults: boolean = false;
  sicCdTimeZone = '';
  employeeName: string;
  closedByEmployeeId: string;
  selectedSic: string = '';
  defaultView: string;

  constructor(
    private loggingApiService: LoggingApiService,
    private _linehaulOperationsApiService: LinehaulOperationsApiService,
    private humanResourceApiService: HumanResourceApiService,
    private timeService: XpoLtlTimeService
  ) {}

  ngOnInit() {
    this.laneData = this.sidePanelData.laneData ? this.sidePanelData.laneData : this.sidePanelData;
    this.executeSearch();
  }

  ngOnChanges(changes: SimpleChanges) {
    const propName = 'sidePanelData';
    if (changes[propName] && changes[propName].currentValue !== changes[propName].previousValue) {
      this.clearComponent();
      const currentValue = changes[propName].currentValue;
      this.laneData = currentValue.laneData ? currentValue.laneData : currentValue;
      this.executeSearch();
    }
  }

  clearComponent() {
    this.searchingImages = true;
    this.searchRan = false;
    this.searchLoadedTrailerImagesResp = new SearchLoadedTrailerImagesResp();
    this.totalShipmentsCount = 0;
    this.trailerImages = [];
    this.noResults = false;
    this.selectedSic = null;
  }

  executeSearch() {
    this.selectedSic = this.laneData.filterSic;
    this.searchLoadedTrailerImagesResp.result = [];
    const loadedTimestamp = this.laneData.loadedTimestamp;
    const currentEventTmst = this.laneData.currentEventTmst || '';
    const closeUTCTmst = this.laneData.closeTmst;
    const status = this.laneData.statusCd;
    this.searchRan = false;
    this.searchLoadedTrailerImagesRqst.filter = new LoadedTrailerSearchFilter();
    this.searchLoadedTrailerImagesRqst.filter.q = this.laneData.sidePanelId;
    const date = new XrtAttributeFilter();
    const sicCdFilter = new XrtAttributeFilter();
    this.laneData.originSic ? (sicCdFilter.equals = this.laneData.originSic) : (sicCdFilter.equals = this.selectedSic);
    if (status === 'Closed' || status === 'Overhead' || status === 'Enroute') {
      date.min = moment(closeUTCTmst)
        .utc()
        .add(-1, 'hours')
        .format('MM/DD/YYYY HH:mm:ss');
      date.max = moment(closeUTCTmst)
        .utc()
        .add(1, 'hours')
        .format('MM/DD/YYYY HH:mm:ss');
    } else {
      if (status === 'Exception') {
        date.min = moment(closeUTCTmst)
          .utc()
          .add(-2, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
        date.max = moment(closeUTCTmst)
          .utc()
          .add(4, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
      } else {
        date.min = moment(loadedTimestamp)
          .utc()
          .add(-1, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
        date.max = moment(loadedTimestamp)
          .utc()
          .add(6, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
      }
    }
    this.searchLoadedTrailerImagesRqst.filter.loadingSic = sicCdFilter;
    this.searchLoadedTrailerImagesRqst.filter.loadingDate = date;
    this.loggingApiService.info(
      JSON.stringify(this.searchLoadedTrailerImagesRqst.filter),
      'Search Key',
      'Advanced Search',
      'Search'
    );
    this._linehaulOperationsApiService
      .searchLoadedTrailerImages(this.searchLoadedTrailerImagesRqst)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((searchResponse) => {
        if (searchResponse.result.length) {
          this.searchRan = true;
          this.searchLoadedTrailerImagesResp = searchResponse;
          this.loadedTrailerSearchRecord = searchResponse.result[0];
          this.totalShipmentsCount = this.totalShipments;
          this.getTrailerStatus(this.loadedTrailerSearchRecord.status);
          this.setPROSTimeByOriginSic();
          this.trasnformToImageGalleryArray(this.loadedTrailerSearchRecord.images);
          this.afterImagesReady();
          this.noResults = false;
          this.searchingImages = false;
        } else {
          this.noResults = true;
        }
      });
  }

  trasnformToImageGalleryArray(images) {
    this.galleryImages = [];
    images.forEach((img) => {
      const tmst = this.timeService.formatDate(
        img.imageTakenTimestamp,
        'YYYY-MM-DD HH:mm',
        this.loadedTrailerSearchRecord.loadingSic
      );
      const imgValue = 'data:image/jpeg;base64,' + img.thumbnail.replace(/[\r\n]/g, '');
      this.galleryImages.push({
        description: tmst,
        small: imgValue,
        medium: imgValue,
        big: imgValue,
        pros: img.pros,
      });
    });
  }

  afterImagesReady() {
    let defaultImageIndex = this.loadedTrailerSearchRecord.images.findIndex((img: LoadedTrailerImageDetail) => {
      return img.imageType === PhotoTypeCd.CLOSE_PHOTO;
    });
    defaultImageIndex = defaultImageIndex === -1 ? this.loadedTrailerSearchRecord.images.length - 1 : defaultImageIndex;
    this.selectedImage = this.loadedTrailerSearchRecord.images[defaultImageIndex];
    this.selectedImageIndex = defaultImageIndex;
    this.setEmployeesData();
  }
  setEmployeesData(): void {
    this.selectedImage.loadedBy &&
      this.getEmployeeName(this.selectedImage.loadedBy, 'employeeName').subscribe((resp) => (this.employeeName = resp));
    this.loadedTrailerSearchRecord.closedByEmployeeId &&
      this.getEmployeeName(this.loadedTrailerSearchRecord['closedByEmployeeId'], 'closedByEmployeeId').subscribe(
        (resp) => (this.closedByEmployeeId = resp)
      );
  }
  getEmployeeName(empId: string, value: string) {
    const pathParams = new GetEmployeeDetailsByEmpIdPath();
    pathParams.employeeId = empId;
    return this.humanResourceApiService
      .getEmployeeDetailsByEmpId(pathParams, null, { loadingOverlayEnabled: false })
      .pipe(
        take(1),
        map(
          (resp: GetEmployeeDetailsByEmpIdResp) =>
            resp.employee.basicInfo.firstName + ' ' + resp.employee.basicInfo.lastName
        )
      );
  }

  getTrailerStatus(status: string) {
    this.trailerStatus = EnumUtil.getTrailerStatus(status);
  }

  get totalShipments(): number {
    const { images } = this.loadedTrailerSearchRecord;
    const totalClosedShipments = images.filter((image) => image.imageType === 'ClosePhoto');
    if (totalClosedShipments && totalClosedShipments.length === 0) {
      const lastImg = images[images.length - 1];
      return lastImg && lastImg.pros ? lastImg.pros.length : 0;
    } else {
      return totalClosedShipments[totalClosedShipments.length - 1].pros.length;
    }
  }

  setPROSTimeByOriginSic() {
    this.timeService.timezoneForSicCd$(this.selectedSic).subscribe((timestamp) => {
      this.sicCdTimeZone = timestamp;
      this.loadedTrailerSearchRecord.images.forEach((image: any) => {
        if (!!image.pros.length) {
          image.pros.map((pro) => {
            const parseSicDate = moment(
              this.timeService.formatDate(pro.loadedTimestamp, 'MM-DD-YYYY HH:mm', this.selectedSic)
            ).tz(this.sicCdTimeZone);
            pro.loadedTimestamp = parseSicDate._i;
          });
        }
      });
      this.trailerImages = this.loadedTrailerSearchRecord.images;
    });
  }
}
