import { Injectable } from '@angular/core';
import { ExternalUrls } from './../enums/external-urls.enum';

@Injectable({
  providedIn: 'root',
})
export class ExternalUrlsService {
  constructor() {}

  readonly externalUrls = ExternalUrls;

  parseUrl(app): string {
    let url = '';
    // if (app === 'image-viewer') {
    //   url = `${location.protocol}//${location.host.replace('lnh', 'img')}/appjs/${
    //     this.externalUrls.IMAGE_VIEWER_SIDE_COMPONENT
    //   }`;
    // Use this if you need to test it locally
    // url = `${location.protocol}//tctsi-img.firebaseapp.com/appjs/${this.externalUrls.IMAGE_VIEWER_SIDE_COMPONENT}`;
    // For the iframe testing
    url = `http://localhost:9000/${this.externalUrls.IMAGE_VIEWER_SIDE_COMPONENT}`;
    // }
    return url;
  }
}
