import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ComponentsEnum } from '../../shared/enums/components.enum';
import { InteractionServiceResp } from '../../shared/models/interaction-service-response.model';
import { InteractionService } from '../../shared/services/interaction.service';
import { AppRoutes } from './../../shared/enums/app-routes.enum';
import { AuthService } from './../../shared/services/auth.service';
import { ChangesViewDataSourceService } from './allowable-loads/changes-view/changes-view-data-source.service';
import { LanesViewDataSourceService } from './allowable-loads/lanes-view/lanes-view-data-source.service';
import { LoadsViewDataSourceService } from './allowable-loads/loads-view/loads-view-data-source.service';
import { ChangeViewService } from './allowable-loads/services/change-view.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  readonly AppRoutes = AppRoutes;
  selectedRoute: string = 'Allowable Loads';
  lastUpdated: BehaviorSubject<any>;
  moment = moment;
  interactionSubscription: Subscription;
  alAmount: number;
  schAmount: number = 0;
  allowUserSchedule;
  allowUserLoads;

  constructor(
    private lanesService: LanesViewDataSourceService,
    private loadsService: LoadsViewDataSourceService,
    private changesService: ChangesViewDataSourceService,
    private interactionService: InteractionService,
    private authService: AuthService,
    private changeViewService: ChangeViewService
  ) {}

  ngOnInit() {
    this.interactionSubscription = this.interactionService.refresh$.subscribe(() => {
      this.lastUpdated.next(this.getCurrentDateTime());
    });
    this.interactionService.subscribeToComponent(ComponentsEnum.AMOUNT).subscribe((resp: InteractionServiceResp) => {
      this.alAmount = resp.data.amount || 0;
    });
    this.lastUpdated = new BehaviorSubject('');
    this.lastUpdated.next(this.getCurrentDateTime());
    this.allowUserSchedule = this.authService.getPerms(['schedules']).schedules;
  }

  getlastUpdated() {
    return this.lastUpdated.asObservable();
  }

  refresh() {
    this.changeViewService.getCurrentView().subscribe((view) => {
      switch (view) {
        case AppRoutes.LANES_VIEW:
          this.lanesService.refresh();
          break;
        case AppRoutes.LOADS_VIEW:
          this.loadsService.refresh();
          break;
        case AppRoutes.LANES_VIEW:
          this.changesService.refresh();
          break;
      }
    });
    this.lastUpdated.next(this.getCurrentDateTime());
  }

  getToday() {
    return new Date();
  }

  getCurrentDateTime() {
    const timeZone = this.getToday()
      .toLocaleDateString('en', { timeZoneName: 'short' })
      .split(',')[1];
    const timeString = this.moment().format('MMMM Do YYYY, [at] h:mm:ss A zz') + timeZone;
    return timeString;
  }

  selectedTabChange(tabEvent: MatTabChangeEvent | any) {
    this.selectedRoute = tabEvent.tab.textLabel;
  }

  ngOnDestroy() {
    this.interactionSubscription.unsubscribe();
  }
}
