import { Injectable } from '@angular/core';
import {
  EmployeeRole,
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdResp,
  HumanResourceApiService,
} from '@xpo-ltl/sdk-humanresource';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import userPermissionList from '../../../assets/auth-users.json';

@Injectable()
export class AuthService {
  perms;
  roles: Array<string> = [];
  userPerms = userPermissionList.features;
  loadRequestActionRoles = userPermissionList['loadRequest'].actionRoles;
  loadRequestShowRoles = userPermissionList['loadRequest'].showRoles;
  plannedReleasedRoles = userPermissionList['planned'].releasedRoles;
  plannedAlwaysRoles = userPermissionList['planned'].alwaysRoles;
  allow = { schedules: false, metrics: false, alerts: false };
  addLoadRequestRoles = userPermissionList['loadRequest'].addLoadRoles;

  constructor(private humanResourceApiService: HumanResourceApiService) {}

  setPerms(empId: string) {
    this.perms = this.userPerms[empId];
    const pathParams = new GetEmployeeDetailsByEmpIdPath();
    pathParams.employeeId = empId;

    return this.humanResourceApiService
      .getEmployeeDetailsByEmpId(pathParams, null, { loadingOverlayEnabled: false })
      .pipe(
        tap((resp: GetEmployeeDetailsByEmpIdResp) => {
          if (resp && resp.employee && resp.employee.roles) {
            this.roles = resp.employee.roles
              .filter((role: EmployeeRole) => !role.expirationDate)
              .map((role: EmployeeRole) => role.role.roleId);
          }
        }),
        catchError((e) => {
          console.log(e);
          return of(e);
        })
      );
  }

  isAllowedToApprove() {
    const intersection = this.loadRequestActionRoles.filter((element) => this.roles.includes(element));
    return !!intersection && !!intersection.length;
  }

  isAllowedToSeeAlert() {
    const intersection = this.loadRequestShowRoles.filter((element) => this.roles.includes(element));
    return !!intersection && !!intersection.length;
  }

  isAllowedToAddLoadRequest() {
    const intersection = this.addLoadRequestRoles.filter((element) => this.roles.includes(element));
    return !!intersection && !!intersection.length;
  }

  allowedToSeePlannedAlways() {
    return this.plannedAlwaysRoles.some((element) => this.roles.includes(element));
  }

  allowedToSeePlannedOnRelease() {
    return this.plannedReleasedRoles.some((element) => this.roles.includes(element));
  }

  getPerms(component: string | string[]) {
    if (this.perms) {
      if (this.perms[0] === 'all') {
        this.allow = {
          schedules: true,
          metrics: true,
          alerts: true,
        };
      } else if (Array.isArray(component)) {
        component.forEach((value) => {
          this.allow[value] = this.perms.some((val) => val === value);
        });
      } else {
        this.allow[component] = this.perms.some((val) => val === component);
      }
    }
    return this.allow;
  }
}
