export enum IconsKeys {
  hazmat = 'SS_Hazmat',
  guaranteed = 'SS_G',
  guaranteed2 = 'SS_G_2', // without the box
  a = 'SS_A',
  driverCollect = 'SS_DriverCollect',
  liftgate = 'SS_Liftgate',
  NOA = 'SS_NOA',
  TDC = 'SS_TDC',
  freezable = 'SS_Freezable',
  headLoad = 'SS_Headload',
  HSSWeight = 'HSS_Weight_icon',
  addLoad = 'AddLoad_icon',
  photoCamera = 'photo-camera',
  description = 'description',
  circleFilled = 'circle-filled',
  error = 'error',
  arrowUp = 'icon_arrowUp',
  bell = 'icon_bell',
  onTimeDepart = 'icon_OnTimeDepart',
  progLoad = 'icon_ProgLoad',
  misload = 'SS_Misload',
  bypass = 'SS_Bypass',
}
