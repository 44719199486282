import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  LinehaulOperationsApiService,
  ListLoadRequestReasonCodesResp,
  LoadRequestReasonCode,
} from '@xpo-ltl/sdk-linehauloperations';
import { XpoConfirmDialog, XpoConfirmDialogConfig, XpoSnackBar } from '@xpo/ngx-core';
import { forEach as _forEach } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';
import { AllowableLoadsService } from '../../services/allowable-loads.service';
import { ComponentsEnum } from './../../../../../shared/enums/components.enum';
import { InteractionService } from './../../../../../shared/services/interaction.service';
import { DialogConfigData } from './../../models/dialog-config-data.model';

@Component({
  selector: 'app-request-load',
  templateUrl: './request-load.component.html',
  styleUrls: ['./request-load.component.scss'],
})
export class RequestLoadComponent implements OnInit {
  hasDisabledSubmit: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) dialogConfig: any,
    private dialogRef: MatDialogRef<RequestLoadComponent>,
    private fb: FormBuilder,
    private allowableLoadsService: AllowableLoadsService,
    private linehaulApiService: LinehaulOperationsApiService,
    private xpoSnackBar: XpoSnackBar,
    private interactionService: InteractionService,
    private dialog: XpoConfirmDialog
  ) {
    this.dialogConfigData = dialogConfig.data;
  }

  sic: string;
  globalSic: string;
  private dialogConfigData: DialogConfigData;
  reasonCodes: LoadRequestReasonCode[];
  closeToSicCodesWithPlannedCount = [];
  actualLoadsChildren = [];
  closeToSicCodes = [];
  requestTypes = ['ADD', 'CUT'];
  plannedLoads = 0;
  loadRequestFormGroup: FormGroup;
  numberOfLoadsFormControl: AbstractControl = new FormControl();
  closeToFormControl: AbstractControl = new FormControl();
  reasonFormControl: AbstractControl = new FormControl();
  commentFormControl: AbstractControl = new FormControl();
  requestTypeFormControl: AbstractControl = new FormControl();
  readonly noDecimalOrNegativeExp = '[^\\.|-]+';

  ngOnInit() {
    this.plannedLoads = this.dialogConfigData.planned.loadCount;
    this.actualLoadsChildren = this.dialogConfigData.children;
    this.initForm();
    this.getLoadReqReasonCode();
    this.interactionService.subscribeToComponent(ComponentsEnum.SIC).subscribe((sic) => {
      this.globalSic = sic;
      this.sic = this.dialogConfigData.lane;
      this.closeToSicCodesWithPlannedCount = this.dialogConfigData.closeToSicsWithPlannedLoadCount;
      this.closeToSicCodes = this.closeToSicCodesWithPlannedCount.map((obj) => obj.closeTo);
      this.closeToFormControl.setValue(this.getDefaultCloseTo());
      this.commentFormControl.markAsTouched();
    });
  }

  initForm() {
    this.loadRequestFormGroup = this.fb.group({
      requestType: ['', Validators.required],
      numberOfLoads: ['', [Validators.required, Validators.min(1), Validators.max(99)]],
      closeTo: ['', Validators.required],
      reason: ['', [Validators.required]],
      comment: ['', [Validators.maxLength(100)]],
    });
    this.numberOfLoadsFormControl = this.loadRequestFormGroup.get('numberOfLoads');
    this.closeToFormControl = this.loadRequestFormGroup.get('closeTo');
    this.reasonFormControl = this.loadRequestFormGroup.get('reason');
    this.commentFormControl = this.loadRequestFormGroup.get('comment');
    this.requestTypeFormControl = this.loadRequestFormGroup.get('requestType');

    combineLatest([
      this.closeToFormControl.valueChanges,
      this.requestTypeFormControl.valueChanges.pipe(startWith(this.requestTypeFormControl.value)),
    ]).subscribe(([closeTo, requestType]) => {
      if (requestType === 'CUT') {
        const child = this.closeToSicCodesWithPlannedCount.find((c) => c.closeToSicCd === closeTo);
        const summaryLoadCount =
          this.dialogConfigData.planned.loadCount -
          (this.dialogConfigData.loaded ? this.dialogConfigData.loaded.loadCount : 0);
        const childLoadCount = child.planned
          ? child.planned.loadCount - (child.loaded ? child.loaded.loadCount : 0)
          : 0;
        this.plannedLoads = Math.min(summaryLoadCount, childLoadCount);
        this.numberOfLoadsFormControl.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.max(this.plannedLoads),
        ]);
      } else {
        this.numberOfLoadsFormControl.setValidators([Validators.required, Validators.min(1), Validators.max(99)]);
      }
      this.numberOfLoadsFormControl.updateValueAndValidity();
    });
  }

  get checkPlannedCount(): boolean {
    if (this.requestTypeFormControl.value === 'CUT') {
      const reasonCode = this.reasonFormControl.value;
      const closeTo = this.closeToFormControl.value;
      const actualLoadsBySic = this.actualLoadsChildren.find((c) => c.closeToSicCd === closeTo);
      const actualLoads = actualLoadsBySic.loaded ? actualLoadsBySic.loaded.loadCount : 0;
      const child = this.closeToSicCodesWithPlannedCount.find((c) => c.closeTo === closeTo);
      const numberLoads = this.numberOfLoadsFormControl.value;
      return child.plannedCount - numberLoads === 0
        ? true
        : child.plannedCount - numberLoads < actualLoads && reasonCode !== '56'
        ? false
        : true;
    } else {
      return true;
    }
  }

  get checkIfZerosActualLoads(): boolean {
    if (this.requestTypeFormControl.value === 'CUT') {
      const closeTo = this.closeToFormControl.value;
      const child = this.closeToSicCodesWithPlannedCount.find((c) => c.closeTo === closeTo);
      const numberLoads = this.numberOfLoadsFormControl.value;
      return child.plannedCount - numberLoads >= 0;
    } else {
      return true;
    }
  }

  submitRequestLoad(loadReqFg: FormGroup) {
    _forEach(loadReqFg.controls, (ctrl) => {
      ctrl.markAsTouched();
    });
    if (loadReqFg.valid) {
      if (this.checkIfZerosActualLoads) {
        if (this.checkPlannedCount) {
          this.hasDisabledSubmit = true;
          this.confirmIfZerosPlanned(loadReqFg).subscribe((response) => {
            this.hasDisabledSubmit = false;
            if (response) {
              this.xpoSnackBar.success('SUCCESS: Load Request submitted!');
              this.dialogRef.close();
            }
          });
        } else {
          this.xpoSnackBar.error(
            `ERROR: Cannot update Planned count to less than Actual Count, unless the 'Hold To Level Flow' Reason is selected`
          );
        }
      } else {
        this.xpoSnackBar.error('ERROR: Cannot update Planned count to less than zero!');
      }
    }
  }

  private confirmIfZerosPlanned(loadReqFg: FormGroup) {
    const closeTo = this.closeToFormControl.value;
    const child = this.closeToSicCodesWithPlannedCount.find((c) => c.closeTo === closeTo);
    if (loadReqFg.value.requestType === 'CUT' && child.plannedCount === loadReqFg.value.numberOfLoads) {
      const confirmConfig: XpoConfirmDialogConfig = {
        confirmButtonText: 'Proceed',
        confirmButtonColor: 'warn',
        rejectButtonText: 'Go back',
      };
      return this.dialog
        .confirm(
          'CUT Number Of Loads entered will zero out the Planned Load Count. Do you wish to continue?',
          'Confirm Zeroing Planned Load Count',
          confirmConfig
        )
        .pipe(switchMap((confirmDecline) => (confirmDecline ? this.upsertLoadRequest(loadReqFg) : of(false))));
    } else {
      return this.upsertLoadRequest(loadReqFg);
    }
  }

  private upsertLoadRequest(loadReqFg: FormGroup): Observable<boolean> {
    return this.allowableLoadsService.upsertLoadRequest(loadReqFg.getRawValue(), this.dialogConfigData);
  }

  handleCancel() {
    this.dialogRef.close();
  }

  private getDefaultCloseTo(): string {
    return this.closeToSicCodes.includes(this.sic) ? this.sic : this.closeToSicCodes[0];
  }

  getLoadReqReasonCode() {
    this.linehaulApiService
      .listLoadRequestReasonCodes()
      .pipe(take(1))
      .subscribe(
        (response: ListLoadRequestReasonCodesResp) => {
          this.reasonCodes = response.loadRequestReasonCodes.filter((l) => l.reasonTypeCd === 'REASON_CD');
        },
        (error1) => {
          this.xpoSnackBar.error('ERROR: Cannot get reason codes!');
          this.reasonCodes = [];
        }
      );
  }

  getCommentLength() {
    return this.commentFormControl.value.length;
  }
}
