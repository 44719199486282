import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
})
export class SchedulesComponent implements OnInit {
  detailsHeaderData = [
    { type: 'DSRs Available', content: 30 },
    { type: 'DSRs Required', content: 22 },
    { type: 'DSRs Dispatched', content: 8 },
    { type: 'Empties - Planned / Actual', content: '5 / 4' },
    { type: 'Planned Overages', content: 2 },
  ];

  constructor() {}

  ngOnInit() {}
}
