import { Component } from '@angular/core';
import { EnumUtil } from '../../../utils/enum-utils';

@Component({
  selector: 'app-status-formatter-cell',
  template: `
    <mat-icon
      *ngIf="data && data.statusCd && data.statusCd != 'ReqMan'"
      svgIcon="circle-filled"
      [class]="data.statusCd"
    ></mat-icon>
    <mat-icon *ngIf="data.statusCd && data.statusCd == 'ReqMan'" svgIcon="error" [class]="data.statusCd"></mat-icon>
    <span>{{ getTrailerStatus(data.statusCd) }}</span>
  `,
})
export class StatusFormatterComponent {
  data: any;

  agInit(params: any): void {
    this.data = params.data;
  }

  getTrailerStatus(status: string) {
    return EnumUtil.getTrailerStatus(status);
  }
}
