import { LayoutConfig } from '../shared/layout-preference/layout-config.interface';
import { AlertsComponent } from './alerts/alerts.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MetricsComponent } from './metrics/metrics.component';
import { RealtimeComponent } from './realtime/realtime.component';

export const goldenLayoutComponents = [
  {
    component: MetricsComponent,
    componentName: 'metrics',
  },
  {
    component: AlertsComponent,
    componentName: 'alerts',
  },
  {
    component: DashboardComponent,
    componentName: 'dashboard',
  },
  {
    component: RealtimeComponent,
    componentName: 'realtime',
  },
];
const content = [
  {
    type: 'column',
    width: 50,
    content: [
      {
        type: 'component',
        componentName: 'alerts',
        title: 'Alerts',
      },
      {
        type: 'component',
        componentName: 'metrics',
        title: 'Region Figures',
      },
    ],
  },
  {
    type: 'column',
    content: [
      {
        type: 'component',
        componentName: 'dashboard',
        title: 'Planning Board',
        height: 70,
      },
      //   componentName: 'realtime',
      //   title: 'Realtime Dashboard',
      //   height: 30,
      // },
      // {
      //   type: 'component',
      //   componentName: 'allowable-loads-metrics',
      //   title: 'Allowable Loads Metrics',
      // },
    ],
  },
];

export const goldenLayoutDefaultLayout: LayoutConfig[] = [
  {
    name: 'default',
    default: true,
    settings: {
      hasHeaders: true,
      constrainDragToContainer: true,
      reorderEnabled: true,
      selectionEnabled: true,
      popoutWholeStack: false,
      blockedPopoutsThrowError: true,
      closePopoutsOnUnload: true,
      showPopoutIcon: false,
      showMaximiseIcon: true,
      showCloseIcon: true,
    },
    dimensions: {
      borderWidth: 7,
      minItemHeight: 100,
      minItemWidth: 100,
      headerHeight: 20,
      dragProxyWidth: 30,
      dragProxyHeight: 20,
    },
    labels: {
      close: 'close',
      maximise: 'maximise',
      minimise: 'minimise',
      popout: 'open in new window',
    },
    content: [
      {
        type: 'row',
        content,
      },
    ],
  },
];
