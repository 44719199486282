import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ComponentsEnum } from '../enums/components.enum';
import { InteractionEventsEnum } from '../enums/interaction-events.enum';
import { GlobalFilters } from '../models/global-filters';
import { IInteractionServiceResp, InteractionServiceResp } from '../models/interaction-service-response.model';

@Injectable()
export class InteractionService {
  refresh$ = new EventEmitter<any>();
  amount = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  globalFilters = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  alertFilters = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  metrics = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  alerts = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  region = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  sic = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  planDate = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  shift = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  sidePanel = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  loadPanel = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  alertsList = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  alertLane = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  all$;

  subscribeToComponent(component: ComponentsEnum): Observable<any> {
    switch (component) {
      case ComponentsEnum.SHIFT:
        return this.shift.asObservable();
      case ComponentsEnum.SIC:
        return this.sic.asObservable();
      case ComponentsEnum.REGION:
        return this.region.asObservable();
      case ComponentsEnum.PLAN_DATE:
        return this.planDate.asObservable();
      case ComponentsEnum.METRICS:
        return this.metrics.asObservable();
      case ComponentsEnum.GLOBAL_FILTERS:
        return this.globalFilters.asObservable();
      case ComponentsEnum.ALERT_FILTERS:
        return this.alertFilters.asObservable();
      case ComponentsEnum.SIDE_PANEL:
        return this.sidePanel.asObservable();
      case ComponentsEnum.LOAD_PANEL:
        return this.loadPanel.asObservable();
      case ComponentsEnum.AMOUNT:
        return this.amount.asObservable();
      case ComponentsEnum.ALERTS:
        return this.alertsList.asObservable();
      case ComponentsEnum.ALERT_LANE:
        return this.alertLane.asObservable();
      case ComponentsEnum.ALL:
        this.all$ = combineLatest([this.metrics, this.alerts, this.globalFilters]);
        return this.all$;
    }
  }

  sendDataSic(sic: string) {
    const sicUpdate = new InteractionServiceResp(InteractionEventsEnum.SIC_UPDATED, { sic });
    this.sic.next(sicUpdate);
  }
  sendAlertLane(alertLane: string) {
    const AlertLaneUpdate = new InteractionServiceResp(InteractionEventsEnum.ALERT_LANE, { alertLane });
    this.alertLane.next(AlertLaneUpdate);
  }
  sendAlerts(alerts: any) {
    const alertsUpdate = new InteractionServiceResp(InteractionEventsEnum.ALERTS_LIST, { alerts });
    this.alertsList.next(alertsUpdate);
  }

  sendDataRegion(region: string) {
    const regionUpdate = new InteractionServiceResp(InteractionEventsEnum.REGION_UPDATED, { region });
    region && this.region.next(regionUpdate);
  }

  sendDataMetrics(metrics: string) {
    const metricsUpdate = new InteractionServiceResp(InteractionEventsEnum.METRICS_UPDATED, { metrics });
    this.metrics.next(metricsUpdate);
  }

  sendDataShift(shift: string) {
    const shiftUpdate = new InteractionServiceResp(InteractionEventsEnum.SHIFT_UPDATED, { shift });
    this.shift.next(shiftUpdate);
  }

  sendDataSidePanel(sidePanel: any) {
    sidePanel.filterSic = this.sic.value;
    const sidePanelUpdate = new InteractionServiceResp(InteractionEventsEnum.SIDE_PANEL_UPDATED, { sidePanel });
    this.sidePanel.next(sidePanelUpdate);
  }

  sendDataLoadRequest(loadPanel: any, status: string) {
    const loadPanelUpdate = new InteractionServiceResp(InteractionEventsEnum.LOAD_PANEL_UPDATED, { status, loadPanel });
    this.loadPanel.next(loadPanelUpdate);
  }

  closeLoadRequest() {
    const loadPanelUpdate = new InteractionServiceResp(InteractionEventsEnum.LOAD_PANEL_UPDATED, { status: 'CLOSE' });
    this.loadPanel.next(loadPanelUpdate);
  }

  setGlobalFilters(filters: GlobalFilters) {
    const filtersUpdate = new InteractionServiceResp(InteractionEventsEnum.GLOBAL_FILTERS_UPDATED, filters);
    this.globalFilters.next(filtersUpdate);
    this.updateGlobalFilters(filters);
  }

  updateGlobalFilters(filters: GlobalFilters) {
    this.sic.next(filters.sic as IInteractionServiceResp);
    this.shift.next(filters.shift as IInteractionServiceResp);
    this.region.next(filters.region as IInteractionServiceResp);
    this.planDate.next(filters.planDate as IInteractionServiceResp);
  }

  setAlertFilters(alert: any) {
    const filtersUpdate = new InteractionServiceResp(InteractionEventsEnum.ALERT_FILTERS_UPDATED, alert);
    this.alertFilters.next(filtersUpdate);
  }

  setALAmount(amount: number, type) {
    const lengthAmount = new InteractionServiceResp(type, { amount });
    this.amount.next(lengthAmount);
  }

  getGlobalFilters() {
    return this.globalFilters.asObservable();
  }
}
