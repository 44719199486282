import { Component } from '@angular/core';

@Component({
  selector: 'app-date-formatter-cell',
  // tslint:disable-next-line: max-line-length
  template: "<p title=\"{{params.value | date:'MM/dd/yyyy HH:mm'}}\">{{params.value | date:'MM/dd/yyyy HH:mm' }}</p>",
})
export class DateFormatterComponent {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }
}
