import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-realtime',
  templateUrl: './realtime.component.html',
  styleUrls: ['./realtime.component.scss'],
})
export class RealtimeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
