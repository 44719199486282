export enum ImageSearchRecordLabels {
  Trailer = 'Trailer',
  ProNbr = 'PRO#',
  Origin = 'Origin',
  Loading = 'Loading',
  LoadingSic = 'Loading Sic',
  LoadDestinationSic = 'Load Dest.',
  Dest = 'Dest.',
  SummaryMetrics = 'Summary Metrics',
  Utilization = 'Utilization',
  UtilizationUnit = 'Cube',
  Weight = 'Weight',
  WeightUnit = 'Lbs',
  Shift = 'Shift',
  Door = 'Door',
  ClosedBy = 'Closed By',
  LoadedBy = 'Loaded By',
  Status = 'Status',
  MotorMoves = 'MM',
  FreightType = 'Freight Type',
  DateTime = 'Date / Time',
  Shipments = 'Shipments',
}
