import { Injectable } from '@angular/core';
import {
  ExceptionManagementApiService,
  ListExceptionsQuery,
  ListExceptionsResp,
  ExceptionAlert,
} from '@xpo-ltl/sdk-exceptionmanagement';
import { GetLoadRequestPath, GetLoadRequestResp, LinehaulOperationsApiService } from '@xpo-ltl/sdk-linehauloperations';
import { Unsubscriber, XpoLtlTimeService } from '@xpo/ngx-ltl';
import { groupBy as _groupBy, maxBy as _maxBy } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, takeUntil, tap, switchMap } from 'rxjs/operators';
import { ComponentsEnum } from '../enums/components.enum';
import { ActualAlerts } from '../models/actual-alerts';
import { AlertFilters } from '../models/alert-filter';
import { LoadRequestUI } from '../models/load-request-ui.models';
import { InteractionService } from './interaction.service';
import { GlobalFilters } from '../models/global-filters';
import { ExceptionAlertStatus } from '../enums/exception-alert-status.enum';
import { ShiftService } from './shift.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  private unsubscriber = new Unsubscriber();
  private alertsSubject: BehaviorSubject<ListExceptionsResp> = new BehaviorSubject(undefined);
  alerts$ = this.alertsSubject.asObservable();
  savedQuery: ListExceptionsQuery;
  state;
  actualAlerts: ActualAlerts = {
    unresolvedAlerts: [],
    expiredAlerts: [],
    resolvedAlerts: [],
  };
  shift: string;
  alertFilters = new AlertFilters();
  private globalFilters: GlobalFilters;

  constructor(
    private exceptionManagementApiService: ExceptionManagementApiService,
    private linehaulOperationsApiService: LinehaulOperationsApiService,
    private timeService: XpoLtlTimeService,
    private interactionService: InteractionService,
    private shiftService: ShiftService,
    private datePipe: DatePipe
  ) {
    this.interactionService
      .subscribeToComponent(ComponentsEnum.ALERT_FILTERS)
      .pipe(distinctUntilChanged())
      .subscribe(({ data }) => {
        if (data.filter) {
          this.alertFilters[data.filter] = data.value;
        }
      });
  }

  getFormattedDate(date) {
    const timeToSic = this.timeService.formatDate(date, 'YYYY-MM-DD', this.globalFilters.sic);
    return this.globalFilters.planDate.toString().includes(timeToSic);
  }

  private getNewAlerts(query: ListExceptionsQuery, planDate: Date, showExpired: boolean): Observable<ExceptionAlert[]> {
    const yesterday = new Date(planDate);
    yesterday.setDate(planDate.getDate() - 1);
    const queryForYesterday: ListExceptionsQuery = {
      ...query,
      allowedStatusCds: showExpired ? undefined : [ExceptionAlertStatus.Pending, ExceptionAlertStatus.New],
      createdDate: this.datePipe.transform(yesterday, 'yyyy-MM-dd') as any,
    };

    if (!showExpired) {
      query.allowedStatusCds = [ExceptionAlertStatus.Pending, ExceptionAlertStatus.New];
    }

    return forkJoin([
      this.exceptionManagementApiService
        .listExceptions(query, { toastOnError: false })
        .pipe(map((result) => result.exceptionAlerts)),
      this.exceptionManagementApiService
        .listExceptions(queryForYesterday, { toastOnError: false })
        .pipe(map((result) => result.exceptionAlerts.filter((a) => !a.expectationHeader.shiftCd))),
    ]).pipe(map(([todayAlerts, yesterdayAlerts]) => todayAlerts.concat(yesterdayAlerts)));
  }

  private getOldAlerts(query: ListExceptionsQuery): Observable<ExceptionAlert[]> {
    return this.exceptionManagementApiService
      .listExceptions(query, { toastOnError: false })
      .pipe(map((result) => result.exceptionAlerts));
  }

  getAlerts(globalFilters, exceptionType = null, sicCd = null, showExpired = false): Observable<ActualAlerts> {
    this.shift = globalFilters.shift;
    const listExceptionsQuery: ListExceptionsQuery = {
      sicCd: sicCd || null,
      proNbr: null,
      shiftCd: null,
      createdDate: this.datePipe.transform(globalFilters.planDate, 'yyyy-MM-dd') as any,
      regionCd: globalFilters.region || null,
      exceptionType: exceptionType || null,
      trailerNbr: null,
      allowedStatusCds: undefined,
    };

    this.savedQuery = listExceptionsQuery;

    return this.shiftService
      .isTodayOrActiveFACShift(globalFilters.shift, globalFilters.planDate, globalFilters.sic)
      .pipe(
        switchMap((isToday) =>
          isToday
            ? this.getNewAlerts(listExceptionsQuery, globalFilters.planDate, showExpired)
            : this.getOldAlerts(listExceptionsQuery)
        ),
        map((exceptionAlerts: ExceptionAlert[]) => {
          const filteredAlertByShift = [];
          exceptionAlerts.forEach((alert) => {
            if (
              alert.expectationHeader.expectationType.typeCd === 'HSS_UNDER_WGT' ||
              alert.expectationHeader.expectationType.typeCd === 'MISLOAD_GUR'
            ) {
              filteredAlertByShift.push(alert);
            } else {
              if (alert.expectationHeader.shiftCd === this.shift) {
                filteredAlertByShift.push(alert);
              }
            }
          });

          const exceptionFilteredList = [];

          const groupedExceptions = _groupBy(filteredAlertByShift, 'exception.exceptionInstId');

          for (const exc in groupedExceptions) {
            if (groupedExceptions[exc]) {
              const filteredAlert = this.getHighestDetail(groupedExceptions[exc]);
              exceptionFilteredList.push(filteredAlert);
            }
          }

          this.actualAlerts = {
            resolvedAlerts: [],
            expiredAlerts: [],
            unresolvedAlerts: exceptionFilteredList,
          };
          return this.actualAlerts;
        }),
        catchError((err) => {
          console.log('ERROR getting alerts!' + err);
          return [];
        })
      );
  }

  getHighestDetail(alertList): object {
    if (alertList.length > 1) {
      return _maxBy(alertList, 'expectationHeader.expectationDetail[0].expectationDetailSequenceNbr');
    } else {
      return alertList[0];
    }
  }

  getLoadRequest(loadRequestId, exceptionInstId?: number): Observable<LoadRequestUI> {
    const getLoadRequestPath = new GetLoadRequestPath();
    getLoadRequestPath.lhoLoadRequestId = loadRequestId;
    return this.linehaulOperationsApiService.getLoadRequest(getLoadRequestPath, { toastOnError: false }).pipe(
      map((response: GetLoadRequestResp) => {
        const alert: LoadRequestUI = {
          ...response.loadRequest,
          expirationDateFrom: new Date(response.expirationDateFrom),
          expirationDateTo: new Date(response.expirationDateTo),
          exceptionInstId: exceptionInstId || null,
        };
        return alert;
      })
    );
  }
}
