import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { GoldenLayoutModule, GoldenLayoutService } from '@embedded-enterprises/ng6-golden-layout';
import { XpoButtonModule, XpoConfirmDialogModule, XpoDialogModule, XpoIconModule } from '@xpo/ngx-core';
import { LayoutPreferenceSelectorComponent } from './components/layout-preference-selector.component';
import { XpoLtlSaveLayoutDialog } from './components/save-layout-dialog.component';
import { GoldenLayoutExtService } from './services/golden-layout-ext.service';
import { XpoLtlLayoutPreferencesStorage } from './services/layout-preferences-storage.interface';
import { XpoLtlLayoutPreferencesStorageUserPreferencesService } from './services/layout-preferences-storage-user-preferences.service';

const components = [LayoutPreferenceSelectorComponent, XpoLtlSaveLayoutDialog];
const materialModules = [
  FormsModule,
  MatMenuModule,
  MatButtonModule,
  MatFormFieldModule,
  MatSelectModule,
  MatOptionModule,
  MatIconModule,
  MatInputModule,
  MatDialogModule,
];

const xpoModules = [XpoDialogModule, XpoIconModule, XpoButtonModule, XpoConfirmDialogModule];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    ...materialModules,
    ...xpoModules,
    GoldenLayoutModule.forRoot({
      components: [],
      defaultLayout: {
        content: [],
      },
    }),
  ],
  providers: [
    // LAYOUT_PREFERENCES_LOCAL_STORAGE_STATE_STORE_PROVIDER, // Don't need this since we are setting default layout from a layout name
    { provide: 'XPO_LTL_LAYOUT_PREFERENCES_STORAGE', useClass: XpoLtlLayoutPreferencesStorageUserPreferencesService },
    {
      provide: GoldenLayoutService,
      useClass: GoldenLayoutExtService,
    },
  ],
  entryComponents: [XpoLtlSaveLayoutDialog],
})
export class LayoutPreferenceModule {}
