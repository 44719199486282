import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { GlobalFilters } from '../shared/models/global-filters';
import { LinehaulService } from '../execution/linehaul.service';
import { retryWhen, delay, take, filter, map } from 'rxjs/operators';
import { RegionWithSics } from '../shared/models/region-with-sics.model';
import { ShiftOption } from '../execution/dashboard/allowable-loads/filters/shift-filter/shift';
import { InteractionService } from '../shared/services/interaction.service';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  globalFilters = new GlobalFilters();

  regionOptions: RegionWithSics[];
  shiftOptions: ShiftOption[] = [
    { title: 'FAC', code: 'F' },
    { title: 'Outbound', code: 'O' },
  ];

  filtersForm = new FormGroup({
    region: new FormControl(''),
    shift: new FormControl(this.shiftOptions[1].code),
    planDate: new FormControl(new Date()),
    sic: new FormControl(''),
  });
  maxDate = new Date();

  constructor(
    private linehaulService: LinehaulService,
    private interactionService: InteractionService,
    private timeService: XpoLtlTimeService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParamMap
      .pipe(
        filter((queryParams: any) => queryParams.params && Object.keys(queryParams.params).length !== 0),
        map((queryParams: any) => queryParams.params)
      )
      .subscribe((params: any) => {
        this.filtersForm.setValue({
          region: params.region || '',
          shift: params.shift || 'O',
          planDate: params.planDate ? new Date(params.planDate) : new Date(),
          sic: params.sic || '',
        });
      });

    this.filtersForm.valueChanges.subscribe((newFilters) => {
      if (newFilters.region !== this.globalFilters.region) {
        this.globalFilters.region = newFilters.region;
        this.interactionService.sendDataRegion(newFilters.region);
      }

      if (newFilters.shift !== this.globalFilters.shift) {
        this.interactionService.sendDataShift(newFilters.shift);
        this.globalFilters.shift = newFilters.shift;
      }

      this.globalFilters.planDate = newFilters.planDate;

      if (this.globalFilters.sic !== newFilters.sic) {
        this.globalFilters.sic = newFilters.sic;
        if (newFilters.sic) {
          const dateInSic = new Date(
            this.timeService.formatDate(new Date(), 'YYYY-MM-DDThh:mm:ss', this.globalFilters.sic)
          );
          this.maxDate = dateInSic > new Date() ? dateInSic : new Date();
        } else {
          this.maxDate = new Date();
        }
      }

      this.interactionService.setGlobalFilters(this.globalFilters);
    });
    this.getDataForFilters();
  }

  private getDataForFilters() {
    this.linehaulService
      .getRegionsWithSicsList()
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
      .subscribe((resp) => {
        this.regionOptions = resp.sort((a, b) => (a.region < b.region ? -1 : a.region > b.region ? 1 : 0));
      });
  }

  handleSicChange(sicCd: string): void {
    if (sicCd) {
      const regionWithSics = this.regionOptions.find((r) => r.sicList.some((s) => sicCd === s.sicCd));
      const sic = regionWithSics.sicList.find((s) => sicCd === s.sicCd);

      if (sic && sic.isFac !== sic.isOb) {
        this.globalFilters.shift = sic.isFac ? 'F' : 'O';
      }

      this.filtersForm.setValue({
        region: regionWithSics.region,
        sic: sicCd,
        planDate: this.filtersForm.value.planDate,
        shift: this.filtersForm.value.shift,
      });
    } else {
      this.filtersForm.controls['sic'].setValue('');
    }
  }
}
