import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule, MatSortModule, MatTableModule } from '@angular/material';
import { XpoCardModule } from '@xpo/ngx-core';
import { XpoAgGridModule } from '@xpo/ngx-core-ag-grid';
import { XpoBoardModule, XpoFiltersModule } from '@xpo/ngx-core-board';
import { XpoAgGridBoardModule } from '@xpo/ngx-core-board-ag-grid';
import { AgGridModule } from 'ag-grid-angular';
import { InteractionService } from '../../shared/services/interaction.service';
import { SharedModule } from '../../shared/shared.module';
import { MetricsComponent } from './metrics.component';
import { MetricsService } from './metrics.service';
import { RegionAlertsComponent } from './region-alerts/region-alerts.component';
import { SquareInfoComponent } from './region-alerts/square-info/square-info.component';
import { RegionSummaryComponent } from './region-summary/region-summary.component';
@NgModule({
  declarations: [MetricsComponent, RegionAlertsComponent, SquareInfoComponent, RegionSummaryComponent],
  imports: [
    CommonModule,
    XpoCardModule,
    XpoBoardModule,
    XpoFiltersModule,
    XpoAgGridBoardModule,
    XpoAgGridModule,
    MatTableModule,
    MatSortModule,
    AgGridModule,
    SharedModule,
    MatProgressSpinnerModule,
    XpoAgGridBoardModule,
  ],

  exports: [MetricsComponent],
  entryComponents: [],
  providers: [MetricsService, InteractionService],
})
export class MetricsModule {}
