export enum ManifestTableLabels {
  ProNbr = 'PRO#',
  Origin = 'Origin',
  Dest = 'Dest.',
  Weight = 'Weight (lbs)',
  Utilization = 'Cube',
  MotorMoves = 'MM',
  LoosePieces = 'LP',
  FreightType = 'Freight Type',
  ServiceDate = 'Service Date',
}
