export class UserRoleServiceMock {
  getUser() {
    return {
      userId: 'userId',
      employeeId: 'employeeId',
      racfId: 'racfId',
      profileInstId: 'profileInstId',
      displayName: 'displayName',
      givenName: 'givenName',
      lastName: 'lastName',
      title: 'title',
      emailAddress: 'emailAddress',
      mobile: 'mobile',
      telephone: 'telephone',
      streetAddress: 'streetAddress',
      stateCd: 'stateCd',
      countryCd: 'countryCd',
      postalCode: 'postalCode',
      department: 'department',
      businessUnit: 'businessUnit',
      regionDescription: 'regionDescription',
      manager: 'manager',
      jobRoleCode: 'jobRoleCode',
      roles: [],
      sicCode: 'sicCode',
    };
  }
}
