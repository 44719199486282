import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { User } from '@xpo-ltl/sdk-common';
import {
  ExceptionManagementApiService,
  UnregisterFilterExpectationUnfilteredRqst,
} from '@xpo-ltl/sdk-exceptionmanagement';

import { XpoAccountPopoverConfig, XpoAppSwitcherApplication, XpoShellRoute } from '@xpo/ngx-core';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { XpoLtlAppSwitcherService, XpoLtlFeedbackService } from '@xpo/ngx-ltl';
import { XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import { Observable, of, Subscription } from 'rxjs';
import { delay, retryWhen, take, takeUntil, filter, map } from 'rxjs/operators';
import { AlertsMessagingService } from './execution/alerts/alerts-messaging.service';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ComponentsEnum } from './shared/enums/components.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { IconsKeys } from './shared/enums/icons.enum';
import { InteractionService } from './shared/services/interaction.service';
import { UserRoleService } from './shared/services/user-role/user-role.service';
import { GlobalFilters } from './shared/models/global-filters';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnDestroy {
  title: string;
  build: string;
  trainingResources: string;
  releaseNotes: string;
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routes: XpoShellRoute[];
  accountPopoverConfig: XpoAccountPopoverConfig;
  /* TO use LDA you can find in XLH8 region. */
  loginSubscription: Subscription;
  private unsubscriber = new Unsubscriber();

  constructor(
    private configManagerService: ConfigManagerService,
    public feedbackService: XpoLtlFeedbackService,
    private loginService: LoginService,
    private appSwitcherService: XpoLtlAppSwitcherService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private interactionService: InteractionService,
    private messagingTokenService: XrtFireMessagingTokenService,
    private exceptionService: ExceptionManagementApiService,
    private alertsMessagingService: AlertsMessagingService,
    private userRoleService: UserRoleService
  ) {
    this.setIconsRegistry();
    this.routes = [
      {
        label: 'Linehaul',
        path: `/${AppRoutes.LINEHAUL}`,
      },
    ];

    this.initSubscriptions();

    /** Shell setup */
    this.title = configManagerService.getSetting<string>(ConfigManagerProperties.appName);
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.trainingResources = configManagerService.getSetting<string>(ConfigManagerProperties.trainingResources);
    this.releaseNotes = configManagerService.getSetting<string>(ConfigManagerProperties.releaseNotes);

    this.setSicFunc();

    this.unregisterExistingFilters();
  }

  initSubscriptions() {
    this.loginSubscription = this.loginService.userLoggedIn$.subscribe(() => {
      this.setSicFunc();
    });
    this.subscribeToGlobalFilterChanges();
  }

  /** Initialize SIC switcher by getting logged in user's sic */
  setSicFunc() {
    this.loginService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            delay(1000),
            take(5)
          )
        )
      )
      .subscribe((user: User) => {
        if (user) {
          this.populateAccountPopover(user);
          this.userRoleService.setUser(user);
          this.populateAppSwitcher();
        }
      });
  }

  private populateAccountPopover(user: User): void {
    const fullName = (user.givenName || '') + ' ' + (user.lastName || '');

    this.accountPopoverConfig = {
      imageUri: this.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [
        { title: 'My Account', url: AccountUrls.myAccount },
        { title: 'Help', url: AccountUrls.help },
        { title: 'Privacy Policy', url: AccountUrls.privacyPolicy },
      ],
    };
  }

  private signOut(): void {
    // Removing the local storage keys
    this.loginService.clear();
  }

  private getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  private populateAppSwitcher() {
    this.apps$ = this.appSwitcherService.getAppList().pipe((err) => of([]));
  }

  private setIconsRegistry() {
    Object.values(IconsKeys).forEach((icon) => {
      this.iconRegistry.addSvgIcon(icon, this.sanitizer.bypassSecurityTrustResourceUrl(`./assets/images/${icon}.svg`));
    });
  }

  ngOnDestroy() {
    !!this.loginSubscription && this.loginSubscription.unsubscribe();
    this.unregisterExistingFilters();
  }

  unregisterExistingFilters() {
    this.messagingTokenService.getToken$().subscribe((token) => {
      const unregisterFilterExpecationRqst = new UnregisterFilterExpectationUnfilteredRqst();
      unregisterFilterExpecationRqst.connectionToken = token;
      unregisterFilterExpecationRqst.filterHash = null;

      this.exceptionService
        .unregisterFilterExpectationUnfiltered(unregisterFilterExpecationRqst, { toastOnError: false })
        .subscribe();
    });
  }

  private subscribeToGlobalFilterChanges(): void {
    this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(
        map((resp) => resp.data),
        filter((data: GlobalFilters) => !!data.region && !!data.shift && !!data.planDate),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((data: GlobalFilters) => {
        this.alertsMessagingService.updateCurrentFilter({
          sic: data.sic,
          shift: data.shift,
          planDate: data.planDate,
          region: data.region,
        });
      });
  }
}
