import { Component } from '@angular/core';
import { isEmpty as _isEmpty } from 'lodash';
import { IconsKeys } from '../../enums/icons.enum';

@Component({
  selector: 'app-closeto-formatter-cell',
  template: `
    <span>{{ params }}</span>
  `,
})
export class CloseToFormatterComponent {
  params: any;

  agInit(params: any): void {
    this.params = params.rowIndex ? params.value : '';
    if (this.params.length > 11) {
      this.params = this.params.slice(0, 12) + '...';
    }
  }
}
