import { Injectable } from '@angular/core';
import { LoadRequest } from '@xpo-ltl/sdk-linehauloperations';
import { XpoBoardState } from '@xpo/ngx-core-board';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
// tslint:disable-next-line:max-line-length
import { LanesViewDataSourceService } from '../../../../../execution/dashboard/allowable-loads/lanes-view/lanes-view-data-source.service';
// tslint:disable-next-line:max-line-length
import { AllowableLoadsService } from '../../../../../execution/dashboard/allowable-loads/services/allowable-loads.service';
import { AllowableLoadsTabsEnum } from '../../../../enums/allowable-loads-tabs.enum';
import { InteractionService } from '../../../../services/interaction.service';
import { LoadRequestPanelDataService } from '../../load-request-panel-data.service';

@Injectable({
  providedIn: 'root',
})
export class LoadRequestDataSourceService extends LanesViewDataSourceService {
  constructor(
    private allowableLoadsServiceExtended: AllowableLoadsService,
    private interactionServiceExtended: InteractionService,
    private loadRequestPanelDataService: LoadRequestPanelDataService
  ) {
    super(allowableLoadsServiceExtended, interactionServiceExtended);
  }

  /** This is the function that the board calls when it wants data, the state includes
  the current state of the board which includes the criteria, current view, selected rows etc.*/
  fetchData(state: XpoBoardState): Observable<any> {
    const alertData = this.loadRequestPanelDataService.getPanelData();
    const alertLane = alertData.loadRequestAlert.moveToSicCode;
    const sic = alertData.loadRequestAlert.originSicCode;

    this.allowableLoadsServiceExtended.returnSingleLane = true;
    const response = alertLane
      ? this.allowableLoadsServiceExtended.getListModelInstructions(
          state,
          AllowableLoadsTabsEnum.LANES,
          alertLane,
          false,
          sic
        )
      : of({});

    return response;
  }
}
