import { Injectable } from '@angular/core';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ComponentsEnum } from '../enums/components.enum';
import { InteractionService } from './interaction.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ShiftService {
  constructor(
    private timeService: XpoLtlTimeService,
    private interactionService: InteractionService,
    private datePipe: DatePipe
  ) {}

  isToday(date: Date) {
    const today = new Date();
    return (
      today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
    );
  }

  isTodayOrActiveFACShift(shift: string, planDate: Date, sic: string): Observable<boolean> {
    if (sic && shift === 'F') {
      return this.isInFacShiftRange(planDate, sic).pipe(map((isInRange) => this.isToday(planDate) || isInRange));
    } else {
      return of(this.isToday(planDate));
    }
  }

  isSameDay(firstDate: Date, secondDate: Date) {
    return (
      firstDate.getDate() === secondDate.getDate() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getFullYear() === secondDate.getFullYear()
    );
  }

  isCurrentShift() {
    return this.interactionService.subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS).pipe(
      filter((globalFilters) => globalFilters && globalFilters.data),
      switchMap((globalFilters) =>
        globalFilters.data.shift === 'O'
          ? this.isInOutboundShiftRange(globalFilters.data.planDate, globalFilters.data.sic)
          : this.isInFacShiftRange(globalFilters.data.planDate, globalFilters.data.sic)
      )
    );
  }

  isInOutboundShiftRange(planDate: Date, sic: string, ignoreStart = false): Observable<boolean> {
    return this.timeService.timezoneForSicCd$(sic).pipe(
      map((timezone) => {
        // start, now and end dates share the timezone, so they can be ignored
        const start = new Date(this.datePipe.transform(planDate, 'yyyy-MM-dd') + 'T10:00:00');
        const now = new Date(new Date().toLocaleString('en-US', { timeZone: timezone.trim() }));
        const end = new Date(this.datePipe.transform(planDate, 'yyyy-MM-dd') + 'T22:00:00');
        return (ignoreStart || start <= now) && now < end;
      })
    );
  }

  isInFacShiftRange(planDate: Date, sic: string, ignoreStart = false): Observable<boolean> {
    return this.timeService.timezoneForSicCd$(sic).pipe(
      map((timezone) => {
        // start, now and end dates share the timezone, so they can be ignored
        const start = new Date(this.datePipe.transform(planDate, 'yyyy-MM-dd') + 'T22:00:00');
        const now = new Date(new Date().toLocaleString('en-US', { timeZone: timezone.trim() }));
        const tomorrow = new Date(planDate);
        tomorrow.setDate(planDate.getDate() + 1);
        const date = this.datePipe.transform(tomorrow, 'yyyy-MM-dd');
        const end = new Date(this.datePipe.transform(tomorrow, 'yyyy-MM-dd') + 'T10:00:00');
        return (ignoreStart || start <= now) && now < end;
      })
    );
  }
}
