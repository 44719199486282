import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

export class ZipUtils {
  /**
   * Download zip file.
   * @param files
   */
  static downloadFiles(files: Array<File>) {
    const jszip = new JSZip();

    if (files && files.length) {
      files.forEach(function(file, index) {
        jszip.file(file.name, file);
      });

      jszip.generateAsync({ type: 'blob', compression: 'STORE' }).then(function(content) {
        saveAs(content, 'images.zip');
      });
    }
  }
}
