import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatTabsModule,
} from '@angular/material';
import { PipeModule, XpoDirectivesModule } from '@xpo-ltl/angular-utils';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoIconModule,
  XpoPopoverModule,
  XpoShellModule,
  XpoTabsModule,
} from '@xpo/ngx-core';
import { XpoAgGridModule } from '@xpo/ngx-core-ag-grid';
import { XpoBoardModule, XpoFiltersModule } from '@xpo/ngx-core-board';
import { XpoAgGridBoardModule } from '@xpo/ngx-core-board-ag-grid';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
/* tslint:disable: max-line-length */
import { AddLoadFormatter } from '../../../shared/formatters/ag-grid-cell-formatters/add-load-formatter';
import { BypassFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/BypassFormatterComponent';
import { CloseToFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/closeto-formatter';
import { ExceptionFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/exception-formatter';
import { IconsFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/icons-formatter';
import { LoadedWeightFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/loaded-weight-formatter';
import { ModifiedByFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/modified-by-formatter';
import { MoveToFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/move-to-formatter';
import { SpecialServiceFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/special-service-formatter';
import { StatusChangeFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/status-chage-formatter';
import { StatusFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/status-formatter';
import { DateFormatterComponent } from './../../../shared/formatters/date-formatter';
import { SharedModule } from './../../../shared/shared.module';
import { AllowableLoadsButtonsComponent } from './allowable-loads-buttons/allowable-loads-buttons.component';
import { AllowableLoadsComponent } from './allowable-loads.component';
import { ChangesViewComponent } from './changes-view/changes-view.component';
import { ManifestComponent } from './dialogs/manifest/manifest.component';
import { LanesViewComponent } from './lanes-view/lanes-view.component';
import { RequestLoadComponent } from './lanes-view/request-load/request-load.component';
import { LoadsViewComponent } from './loads-view/loads-view.component';

const dialogs = [ManifestComponent, RequestLoadComponent];

const agGridFormatters = [
  IconsFormatterComponent,
  SpecialServiceFormatterComponent,
  StatusFormatterComponent,
  ModifiedByFormatterComponent,
  BypassFormatterComponent,
  StatusChangeFormatterComponent,
  DateFormatterComponent,
  LoadedWeightFormatterComponent,
  ExceptionFormatterComponent,
  CloseToFormatterComponent,
  MoveToFormatterComponent,
  AddLoadFormatter,
];

@NgModule({
  declarations: [
    AllowableLoadsComponent,
    LanesViewComponent,
    LoadsViewComponent,
    ChangesViewComponent,
    AllowableLoadsButtonsComponent,
    ...agGridFormatters,
    ...dialogs,
    RequestLoadComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    XpoButtonModule,
    XpoAgGridModule,
    XpoBoardModule,
    XpoAgGridBoardModule,
    XpoPopoverModule,
    XpoShellModule,
    XpoDialogModule,
    MatIconModule,
    MatDialogModule,
    SharedModule,
    XpoTabsModule,
    MatTabsModule,
    XpoCardModule,
    HttpClientModule,
    AgGridModule.withComponents([...agGridFormatters]),
    XpoFiltersModule,
    XpoIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    PipeModule,
    XpoDirectivesModule,
  ],
  exports: [...dialogs, AllowableLoadsComponent],
  entryComponents: [...dialogs],
  providers: [DecimalPipe, DatePipe],
})
export class AllowableLoadsModule {}
