import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExecutionModule } from './execution/execution.module';
import { AppRoutes } from './shared/enums/app-routes.enum';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.LINEHAUL,
    loadChildren: 'app/execution/execution.module#ExecutionModule',
  },
  {
    path: '',
    redirectTo: AppRoutes.LINEHAUL,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: AppRoutes.LINEHAUL,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions, { enableTracing: true }), ExecutionModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
