import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShipmentDetailCd } from '@xpo-ltl/sdk-common';
import { ListOdsShipmentsRqst, ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import {
  XpoLtlShipmentDescriptor,
  XpoLtlShipmentDetailsConfig,
  XpoLtlShipmentDetailsService,
  XpoLtlShipmentDetailsTabs,
  XpoLtlTimeService,
} from '@xpo/ngx-ltl';
import { maxBy as _maxBy } from 'lodash';
import moment from 'moment';
import { concatMap, finalize, mergeMap } from 'rxjs/operators';
import { AlertsType } from '../../../shared/enums/alerts.enum';
import { ComponentsEnum } from '../../../shared/enums/components.enum';
import { AlertsService } from '../../../shared/services/alerts.service';
import { IconService } from '../../../shared/services/icons-service/icon.service';
import { LoadRequestUI } from './../../../shared/models/load-request-ui.models';
import { InteractionService } from './../../../shared/services/interaction.service';
import { ExceptionAlertType } from '../../../shared/enums/exception-alert-type.enum copy';
@Component({
  selector: 'alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss'],
})
export class AlertCardComponent implements OnInit {
  @Output() photoCameraTouch: EventEmitter<any> = new EventEmitter();
  @Output() loadRequestPanel: EventEmitter<any> = new EventEmitter();
  @Input() alert: any;
  eType;
  eHeader;
  eContent;
  eDetail;
  loadSic: string;
  loadRequestInfo: LoadRequestUI;
  alertDetails = {
    remainingLoads: 0,
    remainingCube: 0,
    remainingWeight: 0,
    trailerCube: 0,
    trailerWeight: 0,
    lane: null,
    shm_dlv_dat: null,
    calc_est_dlv_dat: null,
  };
  isNegativeLoads = false;
  private loadingDetails: boolean = false;

  constructor(
    private iconService: IconService,
    private xpoLtlShipmentDetailsService: XpoLtlShipmentDetailsService,
    private shipmentOdsApiService: ShipmentOdsApiService,
    private interactionService: InteractionService,
    private timeService: XpoLtlTimeService,
    private alertsService: AlertsService
  ) {}

  ngOnInit() {
    if (this.alert && this.alert.exception) {
      this.eHeader = this.alert.expectationHeader;
      this.eContent = this.alert.exception;
      this.eType = this.alert.expectationHeader.expectationType;

      if (this.eType.typeCd === ExceptionAlertType.LOAD_REQUEST) {
        const loadReqId = this.alert.expectationHeader.expectationDetail[0].value;
        this.alertsService.getLoadRequest(loadReqId).subscribe((val) => {
          this.loadRequestInfo = val;
        });
      }
      this.eDetail = this.getHighestDetail(this.alert.expectationHeader.expectationDetail);
      // Out of scope 14/4
      // if (this.eType.typeCd === 'BYPS_UNDER_UTLZ') {
      //   this.eDetail.value = this.mapPartialBypassDetail(this.eDetail);
      // }
      this.eType.typeCd === ExceptionAlertType.PROG_LOAD && this.mapDetail(this.eDetail, ExceptionAlertType.PROG_LOAD);
      this.eType.typeCd === ExceptionAlertType.MISLOAD_GUR &&
        this.mapDetail(this.eDetail, ExceptionAlertType.MISLOAD_GUR);
      this.eType.typeCd === ExceptionAlertType.HSS_UNDER_WGT &&
        this.mapDetail(this.eDetail, ExceptionAlertType.HSS_UNDER_WGT);
    } else if (this.alert && this.alert.lhoLoadRequestId) {
      this.eType = { typeCd: ExceptionAlertType.LOAD_REQUEST };
      this.eHeader = { expectationDate: this.alert.auditInfo.updatedTimestamp };
      this.loadSic = this.alert.originSicCode;
      this.loadRequestInfo = this.alert;
    }
  }

  getHighestDetail(detailList): object {
    if (detailList.length > 1) {
      return _maxBy(detailList, 'expectationDetailSequenceNbr');
    } else {
      return detailList[0];
    }
  }

  // Out of scope 14/4
  // private mapPartialBypassDetail(eDetail: ExpectationDetail) {
  //   const dateStrings = eDetail.value.split('/')[3].replace(/\./g, ':');
  //   const timeStrings = dateStrings.substr(0, 10) + 'T' + dateStrings.substr(11, dateStrings.length);

  //   return {
  //     originSic: eDetail.value.split('/')[0],
  //     cube: eDetail.value.split('/')[1],
  //     weight: eDetail.value.split('/')[2],
  //     arrivalEta: new Date(timeStrings),
  //   };
  // }

  mapDetail(eDetail, alertType) {
    const isProgOrHSS = alertType === ExceptionAlertType.PROG_LOAD || alertType === ExceptionAlertType.HSS_UNDER_WGT;
    const divider = isProgOrHSS ? '/' : ',';
    const keys = eDetail.valueKey.split(divider);
    const values = eDetail.value.split(divider);
    for (let i = 0; i < keys.length; i++) {
      let val = isProgOrHSS ? parseInt(values[i], 10) : values[i];
      switch (keys[i].trim()) {
        case 'LOAD COUNT':
          if (val < 0) {
            this.isNegativeLoads = true;
            val = val * -1;
          }
          this.alertDetails.remainingLoads = val;
          break;
        case 'REMAIN CUBE':
          this.alertDetails.remainingCube = val;
          break;
        case 'TRLR_CUBE':
          this.alertDetails.trailerCube = val;
          break;
        case 'WEIGHT':
          this.alertDetails.remainingWeight = val;
          break;
        case 'TRLR_WGT':
          this.alertDetails.trailerWeight = val;
          break;
        case 'LANE':
          this.alertDetails.lane = val;
          break;
        case 'SHM DLV DAT':
          this.alertDetails.shm_dlv_dat = val;
          break;
        case 'CALC EST DLV DAT':
          this.alertDetails.calc_est_dlv_dat = val;
          break;
        default:
          break;
      }
    }
  }

  getShift(shift: string): string {
    switch (shift) {
      case 'O':
        return 'Outbound';
      case 'I':
        return 'Inbound';
      case 'F':
        return 'FAC';
      case 'D':
        return 'Day';
    }
  }

  getIconByAlert(type: string): string {
    return this.iconService.getIconByAlert(type);
  }

  getFormattedDate(date) {
    const timeToSic = this.timeService.formatDate(date, 'HH:mm, MM/DD/YYYY', this.eHeader.expectationSic);
    return timeToSic;
  }
  getFormattedDates(date) {
    const timeToSic = this.timeService.formatDate(date, 'HH:mm, MM/DD/YYYY', this.loadSic);
    return timeToSic;
  }

  getFormattedOnlyDate(date) {
    return moment(date).format('MM/DD/YYYY');
  }

  getFormattedEstimateDate(date) {
    const formattedDate = moment(date).format('MM/DD/YYYY');
    return formattedDate === 'Invalid date' ? '' : formattedDate;
  }

  getMisloadedTo(values) {
    const [misloadedTo] = values.split(',');
    return misloadedTo;
  }

  onClickCamara() {
    const selectedAlert = {
      sidePanelId: this.eHeader.trailerNbr || 0,
      filterSic: this.eHeader.expectationSic || 0,
      currentEventTmst: this.eContent.postedDateTimeUtc || '',
      closeTmst: this.eContent.postedDateTimeUtc || '',
      statusCd: this.eDetail.statusCd || '',
    };

    this.photoCameraTouch.emit(selectedAlert);
    this.interactionService.sendDataLoadRequest([], 'CLOSE');
  }

  onClickDetails() {
    if (!this.loadingDetails) {
      const { proNbr } = this.eHeader;

      const listOdsShipmentsRqst: ListOdsShipmentsRqst = {
        proNbrs: [proNbr],
        filterByPickupDateAgeInMonths: 0,
        listInfo: {
          levelOfDetail: '',
          numberOfRows: 0,
          startAt: 0,
          sortFields: [],
          startAtId: 0,
          fields: [],
          totalRowCount: 0,
        },
      };

      this.loadingDetails = true;

      this.shipmentOdsApiService
        .listOdsShipments(listOdsShipmentsRqst)
        .pipe(
          concatMap((resp) => {
            const shipmentInstId = resp.odsShipments[0].shipment.shipmentInstId;
            const shipDetail: XpoLtlShipmentDescriptor = {
              shipmentInstId,
              shipmentDetailCd: ShipmentDetailCd.LINEHAUL_DIMENSIONS,
            };
            const xpoConfig: XpoLtlShipmentDetailsConfig = {
              tabs: [{ id: XpoLtlShipmentDetailsTabs.Details }, { id: XpoLtlShipmentDetailsTabs.History }],
              showShipmentSelector: false,
              searchable: false,
              hideCharges: true,
            };
            return this.xpoLtlShipmentDetailsService.showShipmentDetailsDialog(shipDetail, xpoConfig, {
              width: '1290px',
              maxHeight: '1200px',
            });
          }),
          finalize(() => (this.loadingDetails = false))
        )
        .subscribe();
    }
  }

  getAlertType(alert: AlertsType) {
    return AlertsType[alert];
  }

  loadRequestClick(type) {
    if (type === ExceptionAlertType.LOAD_REQUEST) {
      const payload = {
        loadRequestAlert: this.alert,
      };
      this.loadRequestPanel.emit(payload);
      this.interactionService.subscribeToComponent(ComponentsEnum.LOAD_PANEL).subscribe((resp) => {
        if (
          resp.data.loadPanel &&
          resp.data.status === 'CLOSE' &&
          this.alert.lhoLoadRequestId === resp.data.loadPanel.lhoLoadRequestId
        ) {
          this.alert.declinedQuantity = resp.data.loadRequest.declinedQuantity;
          this.alert.approvedQuantity = resp.data.loadRequest.approvedQuantity;
          this.alert.linehaulComments = resp.data.loadRequest.linehaulComments;
          this.alert.linehaulEmplid = resp.data.loadRequest.linehaulEmplid;
        }
      });
    }
  }
}
