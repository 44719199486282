import { Inject, Injectable } from '@angular/core';
import { ComponentConfiguration, GoldenLayoutService } from '@embedded-enterprises/ng6-golden-layout';
import { defaultTo as _defaultTo, first as _first, get as _get, remove as _remove, size as _size } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { LayoutConfig } from '../layout-config.interface';
import { GoldenLayoutExtService } from './golden-layout-ext.service.js';
import {
  XpoLtlLayoutPreferencesStorage,
  XPO_LTL_LAYOUT_PREFERENCES_STORAGE,
  XpoLtlLayoutPreferencesStorageData,
} from './layout-preferences-storage.interface';

@Injectable({
  providedIn: 'root',
})
export class LayoutPreferenceService {
  private availableLayoutsSubject = new BehaviorSubject<LayoutConfig[]>([]);
  readonly availableLayouts$ = this.availableLayoutsSubject.asObservable();

  private activeLayoutSubject = new BehaviorSubject<LayoutConfig>(undefined);
  readonly activeLayout$ = this.activeLayoutSubject.asObservable();

  set activeLayout(value: LayoutConfig) {
    this.activeLayoutSubject.next(value);
    this.installLayout(value);
  }
  get activeLayout(): LayoutConfig {
    return this.activeLayoutSubject.value;
  }

  private defaultLayoutName: string;
  private defaultLayouts: LayoutConfig[] = [];

  constructor(
    private goldenLayoutService: GoldenLayoutService,
    @Inject(XPO_LTL_LAYOUT_PREFERENCES_STORAGE) private storage: XpoLtlLayoutPreferencesStorage
  ) {}

  /**
   * Initialize with the passed Components and default Layouts. Loads user configurations.
   */
  initialize(components: ComponentConfiguration[], defaultLayouts: LayoutConfig[]) {
    // set the components
    this.goldenLayoutService.config.components = components;

    this.defaultLayouts = _defaultTo(defaultLayouts, []);
    if (this.defaultLayouts.length === 0) {
      // There are no default layouts. Create a default
      const defaultLayout = {
        name: 'default',
        default: true,
        content: [],
      };

      const defaultComponent = this.goldenLayoutService.config.components[0];
      if (defaultComponent) {
        // use first component as the default component to display
        defaultLayout.content.push({
          type: 'component',
          componentName: defaultComponent.componentName,
          title: defaultComponent.componentName,
        });
      }

      this.defaultLayouts.push(defaultLayout);
    }

    // use the first defaultLayout as the default
    this.defaultLayoutName = _get(_first(this.defaultLayouts), 'name');
  }

  /**
   * load saved layouts from storage
   */
  loadLayoutsFromStorage() {
    // const glService = this.goldenLayoutService as GoldenLayoutExtService;
    // glService.initialized$.pipe(take(1)).subscribe(() => {
    // this.storage.getData().subscribe((userPrefs) => {
    setTimeout(() => {
      const userPrefs = JSON.parse(localStorage.getItem('storedLayout')) || new XpoLtlLayoutPreferencesStorageData();
      const layouts = [...this.defaultLayouts, ...userPrefs.userLayouts];
      this.availableLayoutsSubject.next(layouts);

      const lastLayout = this.getLayout(userPrefs.activeLayout);
      const defaultLayout = this.getLayout(this.defaultLayoutName);
      this.activeLayout = _defaultTo(lastLayout, defaultLayout);
    }, 100);
    //   });
    // });
  }

  /**
   * Return true if the named layout exists
   * @param layoutName name of the layout to look for (case-insensitive)
   */
  hasLayout(layoutName: string): boolean {
    return this.getLayout(layoutName) !== undefined;
  }

  /**
   * Returns the named Layout
   * @param layoutName name of layout (case-insensitive)
   */
  getLayout(layoutName: string): LayoutConfig {
    if (layoutName && this.availableLayoutsSubject.value.length > 0) {
      const layoutToFind = layoutName.toLowerCase();
      return this.availableLayoutsSubject.value.find((item) => item.name.toLowerCase() === layoutToFind);
    }
    return undefined;
  }

  /**
   * Load the passed layout into GoldenLayout
   * @param layout
   */
  private installLayout(layout: LayoutConfig): void {
    if (layout) {
      const goldenLayout = (this.goldenLayoutService as GoldenLayoutExtService).goldenLayout;
      if (goldenLayout) {
        goldenLayout.destroy();
        goldenLayout.config = layout;

        // Fix 'ag-header' bug
        goldenLayout.on('stackCreated', (stack) => {
          stack.on('maximised', () => {
            if (stack.childElementContainer && stack.childElementContainer.length > 0) {
              Array.from(stack.childElementContainer[0].getElementsByClassName('ag-header-container')).forEach(
                (elem: HTMLElement) => {
                  elem.style.transform = 'translateX(0)';
                }
              );
            }
          });
        });

        goldenLayout.init();
      }

      // update this layout as the last selected
      this.savePrefs().subscribe();
    }
  }

  /**
   * Save the currently activeLayout and all userLayouts
   */
  private savePrefs(): Observable<void> {
    const userPrefs = {
      activeLayout: _get(this.activeLayout, 'name', ''),
      userLayouts: this.availableLayoutsSubject.value.filter((item) => {
        return !!item && !item.default;
      }),
    };
    localStorage.setItem('storedLayout', JSON.stringify(userPrefs));
    return this.storage.setData(userPrefs);
  }

  /**
   * Save the current layout as a new layout with the passed name, replacing existing layout if it
   * it already exists
   */
  saveLayoutAs(layoutName: string) {
    const gsService = this.goldenLayoutService as GoldenLayoutExtService;
    const newLayout: LayoutConfig = gsService.goldenLayout.toConfig();

    const existingDefault = this.availableLayoutsSubject.value.find(
      (item) => item.default && item.name.toLowerCase() === layoutName.toLowerCase()
    );
    if (existingDefault || _size(layoutName) === 0) {
      layoutName = 'User ' + layoutName;
    }

    newLayout.name = layoutName;
    newLayout.default = false;

    // remove the named layout if it is already in the list
    const userLayouts = this.availableLayoutsSubject.value.filter(
      (item) => !item.default && item.name.toLowerCase() !== layoutName.toLowerCase()
    );
    userLayouts.push(newLayout);

    // rebuild list of available layouts to include the new layout
    const defaultLayouts = this.availableLayoutsSubject.value.filter((item) => item.default);
    this.availableLayoutsSubject.next([...defaultLayouts, ...userLayouts]);

    // set the layout we just saved as active
    this.activeLayout = this.getLayout(layoutName);

    return this.savePrefs();
  }

  /**
   * Delete the named layout.  If it is the current layout, then make the default
   * layout active.
   *
   * @param layoutName name of layout to delete
   */
  deleteLayout(layoutName: string): Observable<void> {
    if (_size(layoutName) === 0) {
      // must supply a name
      return of();
    }

    // remove the named layout from the list
    const userLayouts = this.availableLayoutsSubject.value.filter((item) => !item.default);
    const removed = _remove(userLayouts, (item) => item.name.toLowerCase() === layoutName.toLowerCase());
    if (_size(removed) === 0) {
      return of();
    }

    if (this.activeLayout.name === layoutName) {
      // deleting active layout, so make default layout active
      this.activeLayout = this.getLayout(this.defaultLayoutName);
    }

    // rebuild list of available layouts to include the new layout
    const defaultLayouts = this.availableLayoutsSubject.value.filter((item) => item.default);
    this.availableLayoutsSubject.next([...defaultLayouts, ...userLayouts]);

    return this.savePrefs();
  }
}
