import { Injectable } from '@angular/core';
import {
  GetUserPreferenceQuery,
  GetUserPreferenceResp,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import { result as _result } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserRoleService } from '../../services/user-role/user-role.service';
import {
  XpoLtlLayoutPreferencesStorage,
  XpoLtlLayoutPreferencesStorageData,
} from './layout-preferences-storage.interface';

const LAYOUT_PREFERENCE_STORAGE_KEY = 'xpoLtlLayoutPreferences';

@Injectable({
  providedIn: 'root',
})
export class XpoLtlLayoutPreferencesStorageUserPreferencesService implements XpoLtlLayoutPreferencesStorage {
  constructor(private userPreferenceService: UserPreferenceApiService, private userRoleService: UserRoleService) {}

  getData(): Observable<XpoLtlLayoutPreferencesStorageData> {
    const getPreferencesQuery = new GetUserPreferenceQuery();
    getPreferencesQuery.uiComponentName = LAYOUT_PREFERENCE_STORAGE_KEY;
    getPreferencesQuery.userId = _result(this.userRoleService, 'getUser().userId');
    return this.userPreferenceService.getUserPreference(getPreferencesQuery, { loadingOverlayEnabled: false }).pipe(
      catchError((error) => of(new XpoLtlLayoutPreferencesStorageData())),
      map((result: GetUserPreferenceResp) => {
        try {
          const data = JSON.parse(result.preferences) as XpoLtlLayoutPreferencesStorageData;
          return data;
        } catch {
          return new XpoLtlLayoutPreferencesStorageData();
        }
      })
    );
  }

  setData(data: XpoLtlLayoutPreferencesStorageData): Observable<void> {
    const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
    upsertPreferencesRequest.uiComponentName = LAYOUT_PREFERENCE_STORAGE_KEY;
    upsertPreferencesRequest.preferences = JSON.stringify(data);
    upsertPreferencesRequest.userId = _result(this.userRoleService, 'getUser().userId');
    const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
    return of(); //this.userPreferenceService.upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery);
  }
}
