/* tslint:disable: max-line-length */
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { XpoBoardView } from '@xpo/ngx-core-board';
import { XpoLtlServiceCentersService } from '@xpo/ngx-ltl';
import { AgGridEvent, ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { LanesViewBoardTemplate } from '../../../../../execution/dashboard/allowable-loads/lanes-view/lanes-view-board-template';
import { AllowableLoadsService } from '../../../../../execution/dashboard/allowable-loads/services/allowable-loads.service';
import { IconsKeys } from '../../../../enums/icons.enum';
import { AddLoadFormatter } from '../../../../formatters/ag-grid-cell-formatters/add-load-formatter';
import { BypassFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/BypassFormatterComponent';
import { CloseToFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/closeto-formatter';
import { ExceptionFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/exception-formatter';
import { IconsFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/icons-formatter';
import { LoadedWeightFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/loaded-weight-formatter';
import { MoveToFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/move-to-formatter';
import { SpecialServiceFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/special-service-formatter';
import { StatusFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/status-formatter';
import { InteractionService } from './../../../../services/interaction.service';
import { LoadRequestDataSourceService } from './load-request-data-source.service';

@Component({
  selector: 'app-load-request-panel-details',
  templateUrl: './load-request-panel-details.component.html',
  styleUrls: ['./load-request-panel-details.component.scss'],
})
export class LoadRequestPanelDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() lane: string = '';

  private gridApi: GridApi;
  private colApi: ColumnApi;
  gridOptions: GridOptions;
  aggFunctions;
  viewTemplates: LanesViewBoardTemplate[];
  views: XpoBoardView[];
  initialized = false;

  frameworkComponents = {
    IconsFormatterComponent: IconsFormatterComponent,
    SpecialServiceFormatterComponent: SpecialServiceFormatterComponent,
    StatusFormatterComponent: StatusFormatterComponent,
    BypassFormatterComponent: BypassFormatterComponent,
    LoadedWeightFormatterComponent: LoadedWeightFormatterComponent,
    ExceptionFormatterComponent: ExceptionFormatterComponent,
    CloseToFormatterComponent: CloseToFormatterComponent,
    MoveToFormatterComponent: MoveToFormatterComponent,
    AddLoadFormatter: AddLoadFormatter,
  };
  detailLanesNodes: any[] = [];

  constructor(
    public datasource: LoadRequestDataSourceService,
    private allowableLoadsService: AllowableLoadsService,
    public serviceCentersService: XpoLtlServiceCentersService,
    private interactionService: InteractionService
  ) {}

  ngOnInit() {
    this.initialized = true;
    this.startLaneTable();
    this.generateTemplate();
  }

  ngOnChanges(newinfo: SimpleChanges): void {
    if (this.initialized) {
      this.startLaneTable();
      this.generateTemplate();
    }
  }

  onLanesGridLoad(gridEvent: AgGridEvent) {
    this.gridApi = gridEvent.api;
    this.colApi = gridEvent.columnApi;
  }

  // returns the list as a string
  getValuesAsString(values) {
    return values.filter(Boolean).toString();
  }

  // just returns the list of values
  getValues(values) {
    return values;
  }

  getBypAgg(values) {
    return values.filter(Boolean).length;
  }

  getGuaranteedInd(values) {
    const nonEmptyValues = values.filter(Boolean);
    return nonEmptyValues.length ? IconsKeys.guaranteed : '';
  }

  getExceptionInd(values) {
    const nonEmptyValues = values.filter(Boolean);
    return nonEmptyValues.length ? IconsKeys.circleFilled : '';
  }

  getCountAgg(values) {
    return values.filter(Boolean).length;
  }

  getSumAgg(values) {
    let sum = 0;
    values.forEach((val) => {
      sum += val ? val : 0;
    });
    return sum;
  }

  formatDecimal(number) {
    return number.value
      ? Math.floor(number.value)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : '';
  }

  getSpecialServices(params: any) {
    const total = {
      guaranteedInd: false,
      frzblInd: false,
      hazmatInd: false,
      headLoadInd: false,
      headLoadDetail: false,
      misloadInd: false,
    };

    if (params.children) {
      _.forEach(params.children, (children2) => {
        _.forEach(total, (value, key) => {
          if (!!children2[key]) {
            total[key] = children2[key];
          }
        });
      });
    }
    return total;
  }

  generateTemplate() {
    this.viewTemplates = [new LanesViewBoardTemplate(this.serviceCentersService)];
    this.views = [this.viewTemplates[0].createLanesView()];
    this.interactionService.sendAlertLane(this.lane);
  }

  startLaneTable() {
    this.aggFunctions = {
      getCloseToVal: this.getValuesAsString,
      getByp: this.getBypAgg,
      getGuaranteed: this.getGuaranteedInd,
      getCountAgg: this.getCountAgg,
      getSumAgg: this.getSumAgg,
      getExceptionInd: this.getExceptionInd,
      getValues: this.getValues,
      formatDecimal: this.formatDecimal,
    };
    this.gridOptions = {
      groupMultiAutoColumn: true,
      groupSuppressAutoColumn: true,
      aggFuncs: this.aggFunctions,
      suppressAggFuncInHeader: true,
      frameworkComponents: this.frameworkComponents,
      headerHeight: 28,
      icons: {
        groupExpanded: `<img src="./assets/images/expanded.svg"
          style="width: 22px; position: absolute; left: 5px; top: 4px;" data=test"expandButton">`,
        groupContracted: `<img src="./assets/images/contracted.svg"
          style="width: 22px; position: absolute; left: 5px; top: 4px;" data=test"contractButton">`,
      },
      // level 1 grid options
      columnDefs: [
        {
          headerName: ' ',
          headerClass: 'main-table-header',
          children: [
            {
              width: 60,
              maxWidth: 60,
              headerName: '',
              showRowGroup: 'lane',
              field: 'lane',
              cellRenderer: 'agGroupCellRenderer',
              sortable: false,
              resizable: false,
              cellClass: 'expandstyles',
              cellRendererParams: {
                suppressCount: true,
              },
            },
          ],
        },
        {
          headerName: 'Trip Summary',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Move to',
              showRowGroup: 'lane',
              minWidth: 90,
              maxWidth: 90,
              cellRenderer: 'agGroupCellRenderer',
              field: 'lane',
              cellClass: 'laneColumn',
              cellRendererParams: {
                suppressCount: true,
              },
            },
            {
              headerName: 'Close To',
              field: 'closeToSicCd',
              minWidth: 100,
              maxWidth: 120,
              valueGetter: (params) => {
                return params.data.closeToSicCd.slice(0, 12) + '...';
              },
              cellClass: 'ellipsis',
            },
            {
              headerName: 'Special Services',
              minWidth: 100,
              maxWidth: 100,
              valueGetter: (params) => {
                return this.getSpecialServices(params.data);
              },
              aggFunc: 'getValues',
              sortable: false,
              cellRenderer: 'SpecialServiceFormatterComponent',
            },
          ],
        },
        {
          headerName: 'Loads',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Bypass',
              field: 'bypassLoadCount',
              minWidth: 60,
            },
            {
              headerName: 'Planned',
              field: 'planned',
              type: 'numericColumn',
              minWidth: 80,
              cellRenderer: function getPlanned(params) {
                if (params.value) {
                  return params.value.loadCount || '';
                }
                return '';
              },
            },
            {
              headerName: 'Actual',
              field: 'loaded',
              type: 'numericColumn',
              minWidth: 70,
              cellRenderer: (params) => {
                return params.value ? params.value.loadCount : '';
              },
            },
          ],
        },
        {
          headerName: 'Cube',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'planned',
              valueGetter: function getPlannedCube(params) {
                return params.data && params.data.planned && params.data.planned.cube ? params.data.planned.cube : '';
              },
              type: 'numericColumn',
              minWidth: 80,
            },
            {
              headerName: 'Loaded',
              field: 'loaded',
              valueGetter: function getLoadedCube(params) {
                let cubeLoadSum = 0;
                if (params.data) {
                  _.forEach(params.data.children, (closeTo) => {
                    _.forEach(closeTo.children, (trailer) => {
                      if (!trailer.bypassInd) {
                        cubeLoadSum += trailer.loadedCube;
                      }
                    });
                  });
                }
                return cubeLoadSum || '';
              },
              type: 'numericColumn',
              minWidth: 80,
            },
            {
              headerName: 'Remaining',
              valueGetter: function getRemainingCube(params) {
                return params.data && params.data.remaining && params.data.remaining.cube
                  ? params.data.remaining.cube
                  : '';
              },
              type: 'numericColumn',
              minWidth: 90,
            },
          ],
        },
        {
          headerName: 'Weight',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'planned',
              valueGetter: function getPlannedWeight(params) {
                return params.data && params.data.planned && params.data.planned.weight
                  ? params.data.planned.weight
                  : '';
              },
              valueFormatter: this.formatDecimal,
              type: 'numericColumn',
              minWidth: 80,
            },
            {
              headerName: 'Loaded',
              field: 'loaded',
              valueGetter: function getLoadedWeight(params) {
                let weightLoadSum = 0;
                if (params.data) {
                  _.forEach(params.data.children, (closeTo) => {
                    _.forEach(closeTo.children, (trailer) => {
                      if (!trailer.bypassInd) {
                        weightLoadSum += trailer.loadedWeight;
                      }
                    });
                  });
                }
                return weightLoadSum || '';
              },
              valueFormatter: this.formatDecimal,
              type: 'numericColumn',
              minWidth: 80,
            },
            {
              headerName: 'Remaining',
              field: 'remaining',
              valueGetter: function getRemainingWeight(params) {
                return params.data && params.data.remaining && params.data.remaining.weight
                  ? params.data.remaining.weight
                  : '';
              },
              valueFormatter: this.formatDecimal,
              type: 'numericColumn',
              minWidth: 90,
            },
          ],
        },
        {
          headerName: 'Load Average',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'planned',
              valueGetter: function getPlannedLoadAvg(params) {
                return params.data && params.data.planned && params.data.planned.loadAverage
                  ? params.data.planned.loadAverage
                  : '';
              },
              valueFormatter: this.formatDecimal,
              type: 'numericColumn',
              minWidth: 75,
            },
            {
              headerName: 'Actual',
              field: 'loaded',
              valueGetter: function getPlannedWeight(params) {
                return params.data && params.data.loaded && params.data.loaded.loadAverage
                  ? params.data.loaded.loadAverage
                  : '';
              },
              valueFormatter: this.formatDecimal,
              type: 'numericColumn',
              minWidth: 70,
            },
          ],
        },
        {
          headerName: 'HSS',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'hssLoadCount',
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data.hssLoadCount || '';
              },
              minWidth: 75,
            },
            {
              headerName: 'Actual',
              field: 'hssLoadCount',
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data.hssLoadCount || '';
              },
              minWidth: 70,
            },
          ],
        },
        {
          headerName: 'Others',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'TUC (%)',
              field: 'trailerUtilizationCapability',
              type: 'numericColumn',
              minWidth: 75,
            },
            {
              headerName: 'TU (%)',
              field: 'closedTrailerUtilization',
              type: 'numericColumn',
              minWidth: 70,
            },
          ],
        },
      ],
      groupDefaultExpanded: 0,
      masterDetail: true,
      getRowHeight: (params) => {
        const isDetailRow = params.node.detail;
        if (isDetailRow) {
          let detailPanelHeight = params.data.children.length * 30 + 3;
          params.data.children.map((node) => {
            detailPanelHeight += node.children.length * 27 + 40;
          });
          // saves the lane node to recalculate its height if necessary
          this.detailLanesNodes.push({ lane: params.data.lane, node: params.node, originalHeight: detailPanelHeight });
          return detailPanelHeight + 2;
        } else {
          return 30;
        }
      },
      detailCellRendererParams: {
        // level 2 grid options
        detailGridOptions: {
          headerHeight: 1,
          rowHeight: 27,
          getRowNodeId: () => {
            return _.uniqueId('rowNode_');
          },
          frameworkComponents: this.frameworkComponents,
          icons: {
            groupExpanded: `<img class="group-expanded-icon" src="./assets/images/expanded.svg"
              style="width: 22px; position: absolute; left: 20px; top: 4px;" data=test"expandButton">`,
            groupContracted: `<img src="./assets/images/contracted.svg"
              style="width: 22px; position: absolute; left: 20px; top: 4px;" data=test"contractButton">`,
          },
          columnDefs: [
            {
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell',
              minWidth: 45,
              maxWidth: 45,
              showRowGroup: 'closeToSicCd',
              field: 'closeToSicCd',
              cellRenderer: 'agGroupCellRenderer',
              sortable: false,
              resizable: false,
              cellRendererParams: {
                suppressCount: true,
              },
            },
            {
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell secondary-table-cell-caption',
              minWidth: 72,
              maxWidth: 72,
              valueGetter: (params) => {
                return params.data && params.data.closeToSicCd === this.datasource.sic ? 'Hold to' : 'Close to';
              },
            },
            {
              headerClass: 'secondary-table-header',
              showRowGroup: 'closeToSicCd',
              minWidth: 210,
              maxWidth: 210,
              field: 'closeToSicCd',
              cellClass: 'secondary-table-cell close-to-cell',
            },
            {
              minWidth: 120,
              maxWidth: 120,
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell',
              headerName: 'Special Services',
              valueGetter: (params) => {
                return this.getSpecialServices(params.data);
              },
              aggFunc: 'getValues',
              sortable: false,
              cellRenderer: 'SpecialServiceFormatterComponent',
            },
            {
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell',
            },
            {
              // Loads Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              type: 'numericColumn',
              field: 'planned',
              cellRenderer: function getPlanned(params) {
                return params.data.planned && params.data.planned.loadCount ? params.data.planned.loadCount : '';
              },
            },
            {
              // Loads Actual
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              type: 'numericColumn',
              field: 'loaded',
              valueGetter: function getLoadCount(params) {
                return params.data && params.data.loaded && params.data.loaded.loadCount
                  ? params.data.loaded.loadCount
                  : '';
              },
            },
            {
              // Cube Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getPlannedCube(params) {
                return params.data && params.data.planned ? params.data.planned.cube : '';
              },
              type: 'numericColumn',
            },
            {
              // Cube Loaded
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              type: 'numericColumn',
              valueGetter: function getLoadedCube(params) {
                let cubeLoadSum = 0;
                if (params.data) {
                  _.forEach(params.data.children, (trailer) => {
                    if (!trailer.bypassInd) {
                      cubeLoadSum += trailer.loadedCube;
                    }
                  });
                }
                return cubeLoadSum || '';
              },
            },
            {
              // Cube Remaining
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getRemainingCube(params) {
                return params.data && params.data.remaining ? params.data.remaining.cube : '';
              },
              type: 'numericColumn',
            },
            {
              // Weight Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getPlannedWeight(params) {
                return params.data && params.data.planned ? params.data.planned.weight : '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Weight Loaded
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getLoadedWeight(params) {
                let weightLoadSum = 0;
                if (params.data) {
                  _.forEach(params.data.children, (trailer) => {
                    if (!trailer.bypassInd) {
                      weightLoadSum += trailer.loadedWeight;
                    }
                  });
                }
                return weightLoadSum || '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Weight Remaining
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getRemainingWeight(params) {
                return params.data && params.data.remaining ? params.data.remaining.weight : '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Load Average Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getLoadAvgPlanned(params) {
                return params.data && params.data.planned ? params.data.planned.loadAverage : '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Load Average Actual
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function getLoadAvgActual(params) {
                return params.data && params.data.loaded ? params.data.loaded.loadAverage : '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // HSS Planned NOT SHOWN
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
            },
            {
              // HSS Actual
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.loadedHss && params.data.loadedHss.loadCount
                  ? params.data.loadedHss.loadCount
                  : '';
              },
            },
            {
              // TUC (%)
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function(params) {
                return params.data ? params.data.trailerUtilizationCapability : '';
              },
              type: 'numericColumn',
            },
            {
              // TU (%)
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              valueGetter: function(params) {
                return params.data ? params.data.closedTrailerUtilization : '';
              },
              type: 'numericColumn',
            },
          ],
          groupDefaultExpanded: 1,
          masterDetail: true,
          getRowHeight: (params) => {
            const isDetailRow = params.node.detail;
            if (isDetailRow) {
              return params.data.children.length * 27 + 40 + 1;
            } else {
              return 30;
            }
          },
          detailCellRendererParams: {
            // level 3 grid options
            detailGridOptions: {
              suppressMenu: true,
              getRowClass: () => {
                return 'deep-table-row';
              },
              headerHeight: 35,
              rowHeight: 27,
              headerComponentParams: {},
              frameworkComponents: this.frameworkComponents,
              columnDefs: [
                /*  {
                  headerName: '',
                  width: 50,
                  maxWidth: 50,
                  suppressMenu: true,
                  lockPinned: true,
                  pinned: 'left',
                  valueGetter: ({ data: { hasNoChildrens } }) => {
                    return !hasNoChildrens ? IconsKeys.photoCamera : '';
                  },
                  lockPosition: true,
                  suppressMovable: true,
                  sortable: false,
                  cellRenderer: 'IconsFormatterComponent',
                  cellClass: ['iconColumn', 'deep-table-cell'],
                  headerClass: 'deep-table-cell-header',
                  // Cell event triggers and provides a new value to the Behavior Subject
                  onCellClicked: (params) => {
                    params.data.filterSic && LanesViewBoardTemplate._clickSubject.next(params.data);
                  },
                },*/
                {
                  headerName: 'Loads',
                  lockPosition: true,
                  suppressMovable: true,
                  lockPinned: true,
                  pinned: 'left',
                  field: 'load',
                  cellClass: 'deep-table-cell',
                  headerClass: 'deep-table-cell-header',
                  width: 200,
                  maxWidth: 200,
                  cellClassRules: {
                    'no-trailer-message': function(params) {
                      return params.data.hasNoChildrens;
                    },
                  },
                  valueGetter: (params) => {
                    if (params.data.hasNoChildrens) {
                      return 'No Trailers Loaded in this Lane';
                    }
                    return params.data.load;
                  },
                },
                {
                  field: 'Special Services',
                  lockPosition: true,
                  suppressMovable: true,
                  lockPinned: true,
                  pinned: 'left',
                  sortable: false,
                  headerClass: 'deep-table-cell-header',
                  cellClass: 'deep-table-cell',
                  width: 130,
                  maxWidth: 130,
                  valueGetter: (params) => {
                    const { guaranteedInd, frzblInd, hazmatInd, headLoadInd, headLoadDetail, misloadInd } = params.data;
                    return { guaranteedInd, frzblInd, hazmatInd, headLoadInd, headLoadDetail, misloadInd };
                  },
                  cellRenderer: 'SpecialServiceFormatterComponent',
                  minWidth: 200,
                },
                {
                  headerName: 'Loaded Weight (lbs)',
                  headerClass: 'deep-table-cell-header',
                  cellClass: ['deep-table-cell', 'cell-aling-right'],
                  lockPinned: true,
                  pinned: 'left',
                  field: 'loadedWeight',
                  lockPosition: true,
                  suppressMovable: true,
                  width: 145,
                  maxWidth: 145,
                  valueGetter: ({ data: { loadedWeight, hasNoChildrens } }) => {
                    loadedWeight = `${loadedWeight}`;
                    const chars = loadedWeight.length - 3;
                    if (!hasNoChildrens) {
                      if (chars > 0) {
                        return loadedWeight.substring(0, chars) + ',' + loadedWeight.substring(chars, 6);
                      } else {
                        return loadedWeight;
                      }
                    } else {
                      return null;
                    }
                  },
                },
                {
                  headerName: 'Loaded Cube',
                  headerClass: 'deep-table-cell-header',
                  cellClass: ['deep-table-cell', 'cell-aling-right'],
                  lockPinned: true,
                  pinned: 'left',
                  field: 'loadedCube',
                  lockPosition: true,
                  suppressMovable: true,
                  width: 115,
                  maxWidth: 115,
                  valueGetter: (params) => {
                    return params.data.loadedCube ? params.data.loadedCube.toFixed(2) : null;
                  },
                },
                {
                  headerName: 'Source',
                  headerClass: 'deep-table-cell-header',
                  cellClass: 'deep-table-cell',
                  lockPinned: true,
                  pinned: 'left',
                  field: 'sourceCd',
                  lockPosition: true,
                  suppressMovable: true,
                  width: 120,
                  maxWidth: 120,
                },
                {
                  headerName: 'Door #',
                  headerClass: 'deep-table-cell-header',
                  cellClass: 'deep-table-cell',
                  lockPinned: true,
                  pinned: 'left',
                  field: 'eventDoor',
                  lockPosition: true,
                  suppressMovable: true,
                  width: 120,
                  maxWidth: 120,
                  valueGetter: (params) => {
                    const convert = parseInt(params.data.eventDoor, 10);
                    return !isNaN(convert) ? convert : '';
                  },
                },
                {
                  headerName: 'Status',
                  field: 'statusCd',
                  headerClass: 'deep-table-cell-header',
                  cellRenderer: 'StatusFormatterComponent',
                  cellClass: ['statusColumn', 'deep-table-cell'],
                  lockPinned: true,
                  pinned: 'left',
                  lockPosition: true,
                  suppressMovable: true,
                  width: 105,
                  maxWidth: 105,
                },
                {
                  headerName: '',
                  headerClass: 'deep-table-cell-header',
                  cellRenderer: 'IconsFormatterComponent',
                  cellClass: ['iconColumn', 'deep-table-cell', 'bypassColumn'],
                  lockPinned: true,
                  pinned: 'left',
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  width: 30,
                  maxWidth: 30,
                  valueGetter: (params) => {
                    return params.data.bypassInd ? IconsKeys.bypass : '';
                  },
                },
              ],
              onFirstDataRendered(params) {
                params.api.sizeColumnsToFit();
              },
            },
            getDetailRowData: function(params) {
              params.successCallback(params.data.children);
            },
          },
          onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
            params.api.forEachDetailGridInfo(function(detailGridInfo) {
              detailGridInfo.api.sizeColumnsToFit();
            });
          },
        },
        getDetailRowData: function(params) {
          params.successCallback(params.data.children);
        },
      },
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
        params.api.forEachDetailGridInfo(function(detailGridInfo) {
          detailGridInfo.api.sizeColumnsToFit();
        });
      },
    };
    this.gridOptions.defaultColDef = {
      suppressMenu: true,
      sortable: true,
      resizable: true,
    } as ColDef;
  }

  ngOnDestroy() {
    this.gridApi = null;
    this.colApi = null;
  }
}
