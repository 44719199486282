import { SidePanelStatus } from '../enums/side-panel-status';

export interface SidePanelData {
  currentEventTmst: number;
  closeTmst: number;
  statusCd: string;
  sidePanelId: string;
  filterSic: string;
  laneData?: object;
}

export class SidePanelDataStructures {
  sideData?: SidePanelData;
  sidePanelOpts?: any;
  sidePanelDisplay?: boolean;

  constructor(sideData: SidePanelData, sidePanelOpts: any, sidePanelDisplay: SidePanelStatus) {
    this.sideData = sideData || SidePanelDataStructures.getBlankData();
    this.sidePanelOpts = sidePanelOpts || {};
    this.sidePanelDisplay = sidePanelDisplay === SidePanelStatus.OPENED;
  }

  static getBlankData(): SidePanelData {
    const sideData = {
      currentEventTmst: 0,
      closeTmst: 0,
      statusCd: '',
      sidePanelId: '',
      filterSic: '',
    };
    return sideData;
  }
}
