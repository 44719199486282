import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { XpoConfirmDialog } from '@xpo/ngx-core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { LayoutConfig } from '../layout-config.interface';
import { LayoutPreferenceService } from '../services/layout-preference.service';
import { XpoLtlSaveLayoutDialog } from './save-layout-dialog.component';

@Component({
  selector: 'layout-preference-selector',
  templateUrl: './layout-preference-selector.component.html',
  styleUrls: ['./layout-preference-selector.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LayoutPreferenceSelectorComponent implements OnDestroy {
  @Input() label: string = 'Layout:';
  @Input() placeholder: string = 'Choose Layout';
  @ViewChild('selector', { static: true }) matSelector: MatSelect;

  readonly activeLayout$ = this.layoutPreferenceService.activeLayout$;
  readonly availableLayouts$ = this.layoutPreferenceService.availableLayouts$;
  dialogSubscription: Subscription;
  private isDeleting: boolean = false;

  constructor(
    private layoutPreferenceService: LayoutPreferenceService,
    private dialog: MatDialog,
    private confirmDialog: XpoConfirmDialog
  ) {}

  handleSelectionChange(selection: MatSelectChange) {
    if (!this.isDeleting) {
      this.layoutPreferenceService.activeLayout = selection.value;
    }
  }

  compareLayoutFn(l1: LayoutConfig, l2: LayoutConfig): boolean {
    return l1 && l2 ? l1.name === l2.name : l1 === l2;
  }

  saveLayout() {
    this.matSelector.close();

    const dialogRef = this.dialog.open(XpoLtlSaveLayoutDialog, {
      width: '500px',
      data: this.layoutPreferenceService.activeLayout.name ? this.layoutPreferenceService.activeLayout.name : '',
    });

    this.dialogSubscription = dialogRef.afterClosed().subscribe((layoutName: string) => {
      if (layoutName.length) {
        this.layoutPreferenceService.saveLayoutAs(layoutName).subscribe();
      }
    });
  }

  deleteLayout(layoutName: string) {
    this.matSelector.close();
    this.isDeleting = true;

    this.confirmDialog
      .confirm(`Are you sure you want to delete layout '${layoutName}'?`)
      .pipe(take(1))
      .subscribe((result) => {
        this.isDeleting = false;
        if (result) {
          this.layoutPreferenceService.deleteLayout(layoutName).subscribe(() => {
            // make sure that the activeLayout is selected and displayed
            this.matSelector.value = this.layoutPreferenceService.activeLayout;
          });
        }
      });
  }

  ngOnDestroy() {
    this.dialogSubscription.unsubscribe();
  }
}
