import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'substring',
})
export class SubstringPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    let substringValue = value;
    if (value && Array.isArray(args) && args.length >= 2 && args.every((a) => value.includes(a))) {
      const startPos = value.indexOf(args[0]);
      const endPos = value.indexOf(args[1]);
      if (startPos && endPos >= startPos) {
        substringValue = value.substring(startPos + 1, endPos);
      }
    }
    return substringValue;
  }
}
