import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'xpo-LtlSaveLayoutDialog',
  templateUrl: 'save-layout-dialog.component.html',
  styles: [
    `
      .xpo-LtlSaveLayoutDialog .mat-form-field {
        width: 100%;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class XpoLtlSaveLayoutDialog {
  layoutName: string;

  constructor(
    public dialogRef: MatDialogRef<XpoLtlSaveLayoutDialog>,
    @Inject(MAT_DIALOG_DATA) public activeLayoutName: string
  ) {
    this.layoutName = activeLayoutName;
  }
}
