import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SidePanelOpts } from './image-gallery-container/enums/options.model';
import { SidePanelData } from './image-gallery-container/enums/side-panel-data.model';
import { ImageGalleryService } from './image-gallery-container/image-gallery-container-service.component';

@Component({
  selector: 'app-side-panel-container',
  templateUrl: './side-panel-container.component.html',
  styleUrls: ['./side-panel-container.component.scss'],
})
export class SidePanelContainerComponent implements OnInit, OnDestroy {
  @Input() sidePanelOpts: SidePanelOpts;
  @Input() sidePanelData: SidePanelData;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  url: any;
  fullscreen: boolean = false;
  panelOptions: SidePanelOpts;

  constructor(private imageGalleryService: ImageGalleryService) {}

  ngOnInit() {
    this.panelOptions = {
      defaultView: this.defaultView(this.sidePanelOpts.defaultView),
      allowFullScreen: this.sidePanelOpts.allowFullScreen,
      allowPreview: this.sidePanelOpts.allowPreview,
      displayRelatedProsTable: this.sidePanelOpts.displayRelatedProsTable,
      allowZoomAndRotate: this.sidePanelOpts.allowZoomAndRotate,
      allowDownload: this.sidePanelOpts.allowDownload,
      allowDownloadAll: this.sidePanelOpts.allowDownloadAll,
      allowProsList: this.sidePanelOpts.allowProsList,
      allowSendEmail: this.sidePanelOpts.allowSendEmail,
    };
  }

  defaultView(val) {
    const views = {
      side: 'SIDE',
      full: 'FULL',
    };
    return views[val] || 'SIDE';
  }

  ngOnDestroy() {
    this.sidePanelData = null;
  }

  closePanel() {
    this.imageGalleryService.setIndex(0);
    this.onClose.emit();
  }

  closeOrReducePanel() {
    this.fullscreen ? (this.fullscreen = false) : this.onClose.emit();
  }

  changeStatus(status: boolean) {
    this.fullscreen = status;
    this.imageGalleryService.emitSidePanelStatus(status);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeOrReducePanel();
    }
  }
}
