export interface IInteractionServiceResp {
  name?: string;
  data?: { [key: string]: any };
  timestamp?: Date;
}

export class InteractionServiceResp {
  name?: string;
  data?: { [key: string]: any };
  timestamp: Date;
  constructor(name?: string, data?: { [key: string]: any }) {
    this.name = name;
    this.data = data || {};
    this.timestamp = new Date();
  }
}
