import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import {
  XpoButtonModule,
  XpoChipsModule,
  XpoDialogModule,
  XpoDownloadButtonModule,
  XpoFeedbackModule,
} from '@xpo/ngx-core';
import { XpoCardModule } from '@xpo/ngx-core';
import { NgxGalleryModule } from 'ngx-gallery';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
const dialogs = [
  // YOUR DIALOGS
  NotAuthorizedComponent,
];

@NgModule({
  declarations: [...dialogs],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoDialogModule,
    MatIconModule,
    NgxGalleryModule,
    XpoDownloadButtonModule,
    DragDropModule,
    XpoFeedbackModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    XpoButtonModule,
    XpoChipsModule,
    MatGridListModule,
    XpoCardModule,
  ],
  exports: [...dialogs],
  entryComponents: [...dialogs],
})
export class DialogModule {}
