import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExceptionAlert } from '@xpo-ltl/sdk-exceptionmanagement';
import { LoadRequest } from '@xpo-ltl/sdk-linehauloperations';
import { Observable } from 'rxjs';
import { SicFilterService } from '../../../shared/components/sic-filter/sic-filter.service';
import { SidePanelData } from '../../../shared/components/side-panel-container/image-gallery-container/enums/side-panel-data.model';
import { AlertsType } from '../../../shared/enums/alerts.enum';
import { ComponentsEnum } from '../../../shared/enums/components.enum';
import { SidePanelStatus } from '../../../shared/enums/side-panel-status';
import { RegionWithSics } from '../../../shared/models/region-with-sics.model';
import { SidePanelDataStructures } from '../../../shared/models/side-panel-data.model';
import { SidePanelOpts } from './../../../shared/components/side-panel-container/image-gallery-container/enums/options.model';
import { InteractionService } from './../../../shared/services/interaction.service';
import { ShiftService } from '../../../shared/services/shift.service';
import { InteractionServiceResp } from '../../../shared/models/interaction-service-response.model';
import { map, filter } from 'rxjs/operators';
import { GlobalFilters } from '../../../shared/models/global-filters';

@Component({
  selector: 'app-alerts-tab-content',
  templateUrl: './alerts-tab-content.component.html',
  styleUrls: ['./alerts-tab-content.component.scss'],
})
export class AlertsTabContentComponent implements OnInit {
  @Input() alerts: Observable<(ExceptionAlert | LoadRequest)[]>;
  @Input() searching: boolean = false;
  @Output() changeFilter: EventEmitter<any> = new EventEmitter();
  @Output() changeShowResolvedExpired: EventEmitter<boolean> = new EventEmitter();

  alertsToShow: (ExceptionAlert | LoadRequest)[] = [];
  timestampOrders = ['Newest to Oldest', 'Oldest to Newest'];
  selectedTimeOrder = this.timestampOrders[0];
  alertTypes: string[];
  selectedType = 'All';
  sicDisabled = true;
  isToday = false;
  showResolvedExpired = false;
  sorting = false;
  regionListWithSics: Observable<RegionWithSics[]> = this.sicFilterService.currentSicList;

  get showSpinner() {
    return this.sorting || this.searching;
  }

  private alertsAll: (ExceptionAlert | LoadRequest)[] = [];
  private sidePanelOpts: SidePanelOpts;
  private sideData: SidePanelData;
  private generalFilter: string = '';
  private sic: string;
  private currentRegion: string;

  constructor(
    private sicFilterService: SicFilterService,
    private interactionService: InteractionService,
    private shiftService: ShiftService
  ) {}

  ngOnInit() {
    this.alerts.subscribe((data) => {
      this.alertsAll = data;
      this.sortAlertsByDate(this.selectedTimeOrder);
      this.alertTypes = Object.keys(AlertsType);
    });

    this.sidePanelOpts = {
      defaultView: 'side',
      allowSendEmail: false,
      allowDownload: false,
      allowProsList: false,
      allowDownloadAll: false,
      allowZoomAndRotate: false,
      displayRelatedProsTable: false,
      allowPreview: true,
      allowFullScreen: true,
    };

    this.interactionService.subscribeToComponent(ComponentsEnum.ALERTS).subscribe((resp: InteractionServiceResp) => {
      if (resp.data && resp.data.alerts) {
        this.sortAlertsByDate(this.selectedTimeOrder, resp.data.alerts);
        this.alertTypes = Object.keys(AlertsType);
      }
    });

    /*Set conditions for closing the load request panel*/
    this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(
        map((resp) => resp.data),
        filter((data: GlobalFilters) => !!data.shift && !!data.planDate)
      )
      .subscribe((data: GlobalFilters) => {
        if (this.currentRegion !== data.region) {
          this.currentRegion = data.region;
          this.cleanSicInput(data.region);
        }

        this.shiftService.isTodayOrActiveFACShift(data.shift, data.planDate, data.sic).subscribe((isToday) => {
          this.isToday = isToday;
          this.showResolvedExpired = !this.isToday;
        });
        this.sicDisabled = data.region === '';
        this.interactionService.closeLoadRequest();
      });
  }

  changeSicFilter(sic: string) {
    if (this.sic !== sic) {
      this.sic = sic;
      const send = this.sic ? { filter: 'sicCd', value: this.sic } : null;
      this.changeFilter.emit(send);
    }
  }

  changeGeneralFilter(value: string) {
    const filteredAlerts = this.alertsAll.filter(
      (alert: ExceptionAlert) =>
        alert.expectationHeader &&
        (alert.expectationHeader.destinationSic.toLowerCase().includes(value.toLowerCase()) ||
          alert.expectationHeader.expectationSic.toLowerCase().includes(value.toLowerCase()) ||
          alert.exception.statusCd.toLowerCase().includes(value.toLowerCase()) ||
          alert.expectationHeader.trailerNbr.toLowerCase().includes(value.toLowerCase())) &&
        (this.selectedType !== 'All'
          ? String(alert.expectationHeader.expectationType.typeDescription) === this.selectedType
          : true) &&
        (this.sic ? String(alert.expectationHeader.expectationSic) === this.sic : true)
    );
    this.sortAlertsByDate(this.selectedTimeOrder, filteredAlerts);
  }

  sortAlertsByDate(value: string, filteredAlerts = null) {
    this.sorting = true;
    const alerts = (filteredAlerts || this.alertsAll).sort((a: any, b: any) => {
      if (value === this.timestampOrders[0]) {
        // need to add ignore because this are valid types but was not recognized
        // @ts-ignore
        return (
          (b.exception ? b.exception.postedDateTimeUtc : b.auditInfo.createdTimestamp) -
          (a.exception ? a.exception.postedDateTimeUtc : a.auditInfo.createdTimestamp)
        );
      } else {
        // @ts-ignore
        return (
          (a.exception ? a.exception.postedDateTimeUtc : a.auditInfo.createdTimestamp) -
          (b.exception ? b.exception.postedDateTimeUtc : b.auditInfo.createdTimestamp)
        );
      }
    });

    this.alertsToShow = [
      ...alerts.filter((a: any) => a.lhoLoadRequestId && !a.approvedQuantity && !a.declinedQuantity),
      ...alerts.filter((a: any) => !(a.lhoLoadRequestId && !a.approvedQuantity && !a.declinedQuantity)),
    ];
    this.sorting = false;
  }

  inputAction(sic: string) {
    if (!sic) {
      this.cleanSicInput();
    }
  }

  private cleanSicInput(region?) {
    if (this.sic !== '') {
      this.sic = '';
      this.changeGeneralFilter(this.generalFilter);
      this.changeFilter.emit({ filter: 'sicCd', value: '', region: region });
    }
  }

  changeAlertType(value: string) {
    const send = { filter: 'exceptionType', value: !value.includes('All') ? value : null };
    this.changeFilter.emit(send);
  }

  getAlertType(alert: AlertsType) {
    return AlertsType[alert];
  }

  openSidePanelDisplay() {
    const message = new SidePanelDataStructures(this.sideData, this.sidePanelOpts, SidePanelStatus.OPENED);
    this.interactionService.sendDataSidePanel(message);
  }

  onOpenSidePanel(event: SidePanelData) {
    if (event.sidePanelId) {
      this.sideData = { ...event };
      this.openSidePanelDisplay();
    }
  }

  onOpenLoadPanel(event) {
    if (event) {
      this.interactionService.sendDataLoadRequest(event, 'OPEN');
    }
  }

  onShowResolvedExpiredCheck() {
    this.changeShowResolvedExpired.emit(this.showResolvedExpired);
  }
}
