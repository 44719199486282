import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'xpo-footer',
  templateUrl: './xpo-footer.component.html',
  styleUrls: ['./xpo-footer.component.scss'],
})
export class XpoFooterComponent implements OnInit {
  @Input() buildVersion: string;
  @Input() trainingResourcesLink: string;
  @Input() releaseNotesLink: string;
  constructor() {}

  ngOnInit() {}
}
