import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GoldenLayoutModule } from '@embedded-enterprises/ng6-golden-layout';
import { XpoLtlEnvironmentAndRoleGuardModule } from '@xpo/ngx-ltl';
import { LoadRequestPanelModule } from '../shared/components/load-request-panel/load-request-panel.module';
import { SidePanelContainerComponent } from '../shared/components/side-panel-container/side-panel-container.component';
import { LayoutPreferenceModule } from '../shared/layout-preference/layout-preference.module';
import { LayoutPreferenceService } from '../shared/layout-preference/services/layout-preference.service';
// tslint:disable-next-line: max-line-length
import { XpoLtlLayoutPreferencesStorageUserPreferencesService } from '../shared/layout-preference/services/layout-preferences-storage-user-preferences.service';
// tslint:disable-next-line: max-line-length
import { XPO_LTL_LAYOUT_PREFERENCES_STORAGE } from '../shared/layout-preference/services/layout-preferences-storage.interface';
import { LoadRequestPanelComponent } from './../shared/components/load-request-panel/load-request-panel.component';
import { SidePanelContainerModule } from './../shared/components/side-panel-container/side-panel-container.module';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertsModule } from './alerts/alerts.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { ExecutionRoutingModule, PermsResolver } from './execution-routing.module';
import { ExecutionComponent } from './execution.component';
import { goldenLayoutComponents, goldenLayoutDefaultLayout } from './layout.constant';
import { MetricsComponent } from './metrics/metrics.component';
import { MetricsModule } from './metrics/metrics.module';
import { RealtimeComponent } from './realtime/realtime.component';
import { RealtimeModule } from './realtime/realtime.module';

@NgModule({
  declarations: [ExecutionComponent],
  imports: [
    CommonModule,
    ExecutionRoutingModule,
    GoldenLayoutModule,
    LayoutPreferenceModule,
    MetricsModule,
    DashboardModule,
    AlertsModule,
    XpoLtlEnvironmentAndRoleGuardModule,
    RealtimeModule,
    SidePanelContainerModule,
    LoadRequestPanelModule,
    HttpClientModule,
  ],
  entryComponents: [
    MetricsComponent,
    DashboardComponent,
    AlertsComponent,
    RealtimeComponent,
    LoadRequestPanelComponent,
  ],
  providers: [
    PermsResolver,
    {
      provide: XPO_LTL_LAYOUT_PREFERENCES_STORAGE,
      useClass: XpoLtlLayoutPreferencesStorageUserPreferencesService,
    },
  ],
})
export class ExecutionModule {
  constructor(private layoutPreferenceService: LayoutPreferenceService) {
    this.layoutPreferenceService.initialize(goldenLayoutComponents, goldenLayoutDefaultLayout);
  }
}
