/**
 *  The purpose of this module is to be used in unit tests
 * */
import { NgModule } from '@angular/core';

import { MatButtonModule, MatCardModule, MatIconModule, MatMenuModule, MatToolbarModule } from '@angular/material';

@NgModule({
  imports: [MatButtonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatCardModule],
  exports: [MatButtonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatCardModule],
})
export class MaterialModule {}
