export class EnumUtil {
  static getTrailerStatus(status: string) {
    const enums = {
      Loading: 'Loading',
      LoadingAtDock: 'Loading',
      Closed: 'Closed',
      Requested: 'Requested',
      Added: 'Added',
      Dispatched: 'Dispatched',
      Overhead: 'Overhead',
      CloseForLinehaul: 'Close For Linehaul',
      Enroute: 'Enroute',
    };
    return enums[status];
  }
  static getPhotoType(imageType: string = 'OpenPhoto') {
    const types = {
      LoadPhoto: 'Load Photo',
      ClosePhoto: 'Close Photo',
      OpenPhoto: 'Open Photo',
    };
    return types[imageType];
  }

  static getPhotoStatus(status: string) {
    const enums = {
      LDDK: 'Load Photo',
      CLOS: 'Close Photo',
    };
    return enums[status];
  }
}
