import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InteractionService } from '../../../shared/services/interaction.service';
import { ModelMetricsResp, RegionAlerts } from '../metrics.service';

@Component({
  selector: 'app-region-alerts',
  templateUrl: './region-alerts.component.html',
  styleUrls: ['./region-alerts.component.scss'],
})
export class RegionAlertsComponent implements OnInit, OnDestroy {
  info: any[];
  dataObject: ModelMetricsResp;
  dataSource: any;
  @Input() alertsToShow: RegionAlerts[] = [];

  /*
   * Some columns were commented until they need to be displayed
   */
  initColumns: any[] = [
    {
      name: 'sic',
      display: 'SIC',
    },
    // {
    //   name: 'model',
    //   display: 'Model Compliance',
    // },
    // {
    //   name: 'bypass',
    //   display: 'Bypass Compliance',
    // },
    {
      name: 'gmisload',
      display: 'G! Misload',
    },
    {
      name: 'hss',
      display: 'HSS Underweight',
    },
    // {
    //   name: 'totals',
    //   display: 'Totals',
    // },
  ];
  displayedColumns: any[] = this.initColumns.map((col) => col.name);

  constructor(private interactionService: InteractionService) {}

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // /** Gets the total model of all. */
  getTotal(item: string): number {
    return this.dataObject.regionAlerts.map((t) => t[item]).reduce((acc, value) => acc + value, 0);
  }

  ngOnInit(): void {
    this.info = [
      { amount: this.calculateAlertsAmount(this.alertsToShow, 'gmisload'), description: 'G! Misload' },
      { amount: this.calculateAlertsAmount(this.alertsToShow, 'hss'), description: 'HSS Underweight' },
    ];
    this.dataSource = new MatTableDataSource(this.alertsToShow);
    this.dataSource.sort = this.sort;
  }

  onClick(element) {
    this.interactionService.sendDataMetrics(element.sic);
  }

  ngOnDestroy() {}

  calculateAlertsAmount(alerts: RegionAlerts[], type: string) {
    let alertsAmount = 0;
    alerts.forEach((alert) => {
      alertsAmount += alert[type];
    });
    return alertsAmount;
  }
}
