export enum AppRoutes {
  DASHBOARD = 'dashboard',
  LINEHAUL = 'linehaul',
  ALLOWABLE_LOADS = 'allowable-loads',
  LANES_VIEW = 'lanes-view',
  LOADS_VIEW = 'loads-view',
  CHANGES_VIEW = 'changes-view',
  SCHEDULES = 'schedules',
  DOORS = 'doors',
  FLIPS = 'flips',
  EXCEPTIONS = 'exceptions',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  IMAGE_VIEWER_SIDE_COMPONENT = 'trailer-image-gallery/:id',
}
