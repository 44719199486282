import { Injectable, NgModule } from '@angular/core';
import { Resolve, RouterModule, Routes } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { User } from '@xpo-ltl/sdk-common';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { XpoLtlEnvironmentAndRoleGuard } from '@xpo/ngx-ltl';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigManagerProperties } from '../shared/enums/config-manager-properties.enum';
import { AppRoutes } from './../shared/enums/app-routes.enum';
import { AuthService } from './../shared/services/auth.service';
import { ExecutionComponent } from './execution.component';

@Injectable()
export class PermsResolver implements Resolve<boolean> {
  constructor(
    private loginService: LoginService,
    private configManagerService: ConfigManagerService,
    private authService: AuthService
  ) {}

  resolve(): Observable<any> | Promise<any> | any {
    const endpoint = <string>this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot);
    return this.loginService.getLoggedInUser(endpoint).pipe(
      switchMap((user: User) => {
        return this.authService.setPerms(user.employeeId);
      })
    );
  }
}

const RouterDefinitions: Routes = [
  {
    path: '',
    redirectTo: AppRoutes.LINEHAUL,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.LINEHAUL,
    component: ExecutionComponent,
    canActivate: [XpoAuthenticationGuard, XpoLtlEnvironmentAndRoleGuard],
    resolve: {
      Perms: PermsResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(RouterDefinitions)],
  exports: [RouterModule],
})
export class ExecutionRoutingModule {}
