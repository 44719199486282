import { Component } from '@angular/core';
import { IconsKeys } from '../../enums/icons.enum';

@Component({
  selector: 'app-move-to-formatter-cell',
  styleUrls: ['./move-to-formatter.scss'],
  template: `
    <span class="move-label">{{ label }}</span>
    <span *ngFor="let ind of indicators" class="move-ind">{{ ind }} </span>
  `,
})
export class MoveToFormatterComponent {
  label: string;
  indicators: Array<string> = [];

  agInit(params: any): void {
    this.label = params.value.label;
    this.setIndicators(params);
  }

  private setIndicators(params) {
    const { supplementalInd, exclusiveInd } = params.value;
    supplementalInd && this.indicators.push('sup');
    exclusiveInd && this.indicators.push('exc');
  }
}
