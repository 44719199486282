/* tslint:disable: max-line-length */
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestLoadComponent } from '../../../execution/dashboard/allowable-loads/lanes-view/request-load/request-load.component';
import { IconsKeys } from '../../enums/icons.enum';

@Component({
  selector: 'app-add-load-formatter-cell',
  styles: [
    `
      .icon-button {
        display: inline-flex;
        width: 24px;
        height: 24px;
      }
      [mat-button].mat-button,
      [mat-flat-button].mat-flat-button,
      [mat-raised-button].mat-raised-button,
      [mat-icon-button].mat-icon-button,
      [mat-stroked-button].mat-stroked-button :focus {
        color: rgb(74, 74, 74) !important;
      }
      [mat-button].mat-button[disabled],
      [mat-icon-button].mat-icon-button[disabled] {
        color: #9b9b9b !important;
      }
    `,
  ],
  template: `
    <div *ngIf="enableAdd | async">
      <button mat-icon-button class="icon-button" (click)="handleAddLoad()" [disableRipple]="true">
        <mat-icon [svgIcon]="IconsKeys.addLoad"></mat-icon>
      </button>
    </div>
  `,
})
export class AddLoadFormatter {
  readonly IconsKeys = IconsKeys;
  enableAdd = of(false);
  params: any;

  constructor(private dialog: MatDialog) {}

  agInit(params: any): void {
    this.params = params;
    this.enableAdd = params.value.pipe(map((res) => res));
  }

  handleAddLoad() {
    this.dialog.open(RequestLoadComponent, { data: this.params, minWidth: '600px' });
  }
}
