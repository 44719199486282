import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionCd, ExpectationStatusCd } from '@xpo-ltl/sdk-common';
import { ExceptionAlert } from '@xpo-ltl/sdk-exceptionmanagement';
import { LoadRequest } from '@xpo-ltl/sdk-linehauloperations';
import { Unsubscriber } from '@xpo/ngx-ltl';
import * as _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentsEnum } from '../../shared/enums/components.enum';
import { ExceptionAlertType } from '../../shared/enums/exception-alert-type.enum copy';
import { LoadRequestUI } from '../../shared/models/load-request-ui.models';
import { AlertsService } from '../../shared/services/alerts.service';
import { InteractionService } from '../../shared/services/interaction.service';
import { TimeUtilsService } from '../../shared/services/time-utils.service';
import { AlertFilters } from './../../shared/models/alert-filter';
import { AlertsMessagingService } from './alerts-messaging.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit, OnDestroy {
  unresolvedAlerts: BehaviorSubject<(ExceptionAlert | LoadRequestUI)[]> = new BehaviorSubject([]);
  expiredAlerts: ExceptionAlert[] = [];

  sidePanelDisplay: boolean = false;
  alertFilters = new AlertFilters();

  currentLoadRequestIds = [];
  moment = moment;

  private unsubscriber = new Unsubscriber();
  shift: string;
  globalFilterSetted: any = {};
  actionCd: ActionCd;
  expectationStatusCd: ExpectationStatusCd;
  searchingAlerts: boolean = false;
  lastUpdated: BehaviorSubject<any> = new BehaviorSubject('');
  includeUnresolvedAlerts: boolean;
  currentGetAlertsSubscription: Subscription;

  constructor(
    private alertsService: AlertsService,
    private interactionService: InteractionService,
    private alertsMessagingService: AlertsMessagingService,
    private timeUtilsService: TimeUtilsService
  ) {}

  ngOnInit() {
    this.lastUpdated.next(this.timeUtilsService.getCurrentDateTime());
    this.getPushNotifications();
    this.initSubscriptions();
    this.getLatestAlerts();
  }

  private initSubscriptions() {
    this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(
        filter(
          (globalFilters) =>
            globalFilters &&
            globalFilters.data &&
            (globalFilters.data.sic !== this.globalFilterSetted.sic ||
              globalFilters.data.region !== this.globalFilterSetted.region ||
              globalFilters.data.planDate !== this.globalFilterSetted.planDate ||
              globalFilters.data.shift !== this.globalFilterSetted.shift)
        )
      )
      .subscribe((globalFilters) => {
        this.globalFilterSetted = { ...globalFilters.data };
        this.expiredAlerts = [];
        this.alertFilters.region = globalFilters.data.region;
        this.alertFilters.sicCd = '';
        this.currentLoadRequestIds = [];
        if (globalFilters.data.region) {
          this.getAlerts(globalFilters.data, this.alertFilters.exceptionType);
        }
      });

    this.interactionService
      .subscribeToComponent(ComponentsEnum.ALERT_FILTERS)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(({ data }) => {
        if (data.filter) {
          this.alertFilters[data.filter] = data.value;
        }
      });
  }

  private getAlerts(globalFilters, exceptionType = null, sicCd = null, showExpired = false) {
    this.searchingAlerts = true;
    if (this.currentGetAlertsSubscription) {
      this.currentGetAlertsSubscription.unsubscribe();
    }
    this.currentGetAlertsSubscription = this.alertsService
      .getAlerts(globalFilters, exceptionType, sicCd, showExpired)
      .pipe(
        map((resp) => (resp && resp.unresolvedAlerts ? resp.unresolvedAlerts : [])),
        map((unresolvedAlerts: ExceptionAlert[]) => this.generateAlertsCriteria(unresolvedAlerts)),
        tap(
          (unresolvedAlerts: ExceptionAlert[]) =>
            (this.currentLoadRequestIds = this.getLoadRequestIdsFromAlerts(unresolvedAlerts))
        ),
        switchMap((unresolvedAlerts: ExceptionAlert[]) =>
          unresolvedAlerts ? this.getSeperatedAlerts(unresolvedAlerts) : of([])
        )
      )
      .subscribe(
        (unresolvedAlerts: (ExceptionAlert | LoadRequestUI)[]) => {
          this.searchingAlerts = false;
          this.unresolvedAlerts.next(unresolvedAlerts);
          this.lastUpdated.next(this.timeUtilsService.getCurrentDateTime());
        },
        (err) => {
          this.searchingAlerts = false;
        }
      );
  }

  onChangeFilter(params: any) {
    if (params.region) {
      this.globalFilterSetted.region = params.region;
    }
    this.searchingAlerts = true;
    this.expiredAlerts = [];
    this.alertFilters[params.filter] = params.value;
    this.interactionService.setAlertFilters(params);
    this.getLatestAlerts();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private getPushNotifications() {
    this.alertsMessagingService.elementsUpdated$.subscribe((elementsUpdated) => {
      if (elementsUpdated) {
        const newAlertsIds = elementsUpdated.map((element) =>
          element.DocumentKey.includes('9999') ? this.substractRealId(element.DocumentKey) : element.DocumentKey
        );
        if (newAlertsIds && newAlertsIds.length) {
          const calls: Observable<LoadRequestUI>[] = newAlertsIds.map((alertId) =>
            this.alertsService.getLoadRequest(alertId)
          );

          forkJoin(calls).subscribe((result) => {
            const oldRequests = this.unresolvedAlerts.value
              ? this.unresolvedAlerts.value.filter(
                  (a: any) => !result.some((r) => a.lhoLoadRequestId === r.lhoLoadRequestId)
                )
              : [];
            this.unresolvedAlerts.next([...oldRequests, ...result]);
          });
        }
      }
    });
  }

  getLatestAlerts() {
    if (this.globalFilterSetted.region) {
      this.searchingAlerts = true;
      this.getAlerts(
        this.globalFilterSetted,
        this.alertFilters.exceptionType,
        this.alertFilters.sicCd,
        this.includeUnresolvedAlerts
      );
    } else {
      this.searchingAlerts = false;
    }
  }

  generateAlertsCriteria(alerts: ExceptionAlert[]) {
    const moreThanOneExpectationDetaill = alerts.filter(
      ({ expectationHeader: { expectationDetail } }) => expectationDetail.length > 1
    );
    const lessThanOneExpectationDetaill = alerts.filter(
      ({ expectationHeader: { expectationDetail } }) => expectationDetail.length === 1
    );
    const recursiveGeneratedAlerts: ExceptionAlert[] = [];

    moreThanOneExpectationDetaill.forEach((alert) => {
      alert.expectationHeader.expectationDetail.forEach((expectationDetail) => {
        const temp = alert;
        temp.expectationHeader.expectationDetail = [expectationDetail];
        recursiveGeneratedAlerts.push(temp);
      });
    });

    return this.filterDuplicateAlerts([...recursiveGeneratedAlerts, ...lessThanOneExpectationDetaill]);
  }

  private filterDuplicateAlerts(alerts) {
    const filteredAlerts = [];

    alerts.forEach((alert) => {
      if (alert.expectationHeader.expectationType.typeCd !== ExceptionAlertType.LOAD_REQUEST) {
        const eHeaderId = alert.expectationHeader.expectationInstId;

        const repeatedIndex = filteredAlerts.findIndex((x) => x.expectationHeader.expectationInstId === eHeaderId);

        if (repeatedIndex > -1) {
          if (filteredAlerts[repeatedIndex].exception.exceptionInstId < alert.exception.exceptionInstId) {
            filteredAlerts.splice(repeatedIndex, 1);
            filteredAlerts.push(alert);
          }
        } else {
          filteredAlerts.push(alert);
        }
      } else {
        filteredAlerts.push(alert);
      }
    });

    return filteredAlerts;
  }

  getLoadRequestIdsFromAlerts(alerts: ExceptionAlert[]): any[] {
    const loadReqIds = [];
    alerts.forEach((alert: ExceptionAlert) => {
      if (alert.expectationHeader.expectationType.typeCd === ExceptionAlertType.LOAD_REQUEST) {
        alert.expectationHeader.expectationDetail.forEach((expectationDetail) => {
          loadReqIds.push(expectationDetail.valueKey === 'LoadRequestID' ? expectationDetail.value : undefined);
        });
      }
    });
    return loadReqIds;
  }

  private isLoadRequest(alert: any): boolean {
    return (
      alert.expectationHeader &&
      alert.expectationHeader.expectationType.typeCd === ExceptionAlertType.LOAD_REQUEST &&
      alert.expectationHeader.expectationDetail[0].valueKey === 'LoadRequestID'
    );
  }

  private getSeperatedAlerts(alerts: ExceptionAlert[]): Observable<(ExceptionAlert | LoadRequestUI)[]> {
    const filteresAlerts = alerts.filter((a) => a.expectationHeader.expectationType.typeCd !== 'BYPS_UNDER_UTLZ');
    const unresolvedExceptions = filteresAlerts.filter((a) => !this.isLoadRequest(a));
    const loadReqIds: { lrId: string; exceptionInstId: number }[] = filteresAlerts
      .filter((a) => this.isLoadRequest(a))
      .map((a) => ({
        lrId: a.expectationHeader.expectationDetail[0].value,
        exceptionInstId: a.exception.exceptionInstId,
      }));

    const loadReqIdsUniq = _.uniqBy(loadReqIds, 'lrId');

    // no load requests, so just add other exceptions and don't bother making loadRequest api call
    if (loadReqIdsUniq.length) {
      const getLoadReqCalls: Observable<LoadRequestUI>[] = loadReqIdsUniq.map((loadReqId) =>
        this.alertsService
          .getLoadRequest(loadReqId.lrId, loadReqId.exceptionInstId)
          .pipe(catchError(() => of(undefined)))
      );
      return forkJoin(getLoadReqCalls).pipe(
        map((loadReqRespArray) => [...loadReqRespArray.filter((r) => r), ...unresolvedExceptions])
      );
    } else {
      return of(unresolvedExceptions);
    }
  }

  refreshAlerts() {
    this.getLatestAlerts();
  }

  private substractRealId(id) {
    return id.replace('9999', '');
  }

  onShowResolvedExpired(value: boolean) {
    this.includeUnresolvedAlerts = value;
    this.refreshAlerts();
  }
}
