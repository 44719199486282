export class EmailUtils {
  static generateMessageBody(trailerNbr: string, messsage: string, img: string, link: string): string {
    const _trailerNbr = `<div><p class="trailerNbr">Trailer # ${trailerNbr}</p></div>`;

    const messageInput = messsage
      ? `<div class="messageInput">
                                      <p><span>Message:</span> ${messsage}</p>
                                    </div>`
      : '';

    const trailerImage = `<div><img src="${img}"></div>`;

    const trailerLink = `<div><a href="${link}" class="mat-primary-button">View All Photos</a></div> `;

    const styles = `<style>
                        div{
                          margin-bottom: 25px;
                        }
                        .trailerNbr{
                          font-weight: 500;
                          font-size: 25px;
                        }
                        .messageInput{
                          text-align: justify;
                          width: 400px;
                        }
                        .messageInput span{
                          font-weight: 500;
                        }
                        img{
                          width: 400px;
                        }
                        .mat-primary-button{
                          display: inline-block;
                          color: #FFF;
                          background-color: #0868AC;
                          line-height: 30px;
                          padding: 0 16px;
                          font-family: Roboto, "Helvetica Neue", sans-serif;
                          height: 29px;
                          text-decoration: none;
                        }
                    </style>`;

    return _trailerNbr.concat(messageInput, trailerImage, trailerLink, styles);
  }
}
