import { Component } from '@angular/core';

@Component({
  selector: 'app-loaded-weight-formatter-cell',
  template: `
    <span>
      <mat-icon
        *ngIf="params.value && params.value < weightWarning && isHss(params.data) && params.data.statusCd === 'Closed'"
        >report_problem</mat-icon
      >
    </span>
    <span>{{ params.value | number: '.0-2' }} </span>
  `,
  styleUrls: ['./loaded-weight-formatter.scss'],
})
export class LoadedWeightFormatterComponent {
  params: any;
  weightWarning: number = 35000;

  agInit(params: any): void {
    this.params = params;
  }

  isHss(data) {
    return String(data.load).match(/[a-z]/i);
  }
}
