import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'proNbrFormat' })
export class ProNbrFormatPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    const eraseFirstCero = value ? value.replace(/^0/, '') : '';
    const proNbrWithSpace = !!value ? `${eraseFirstCero.slice(0, 3)}-${eraseFirstCero.slice(3).replace(/^0/, '')}` : '';
    return proNbrWithSpace;
  }
}
