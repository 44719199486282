import { Component, HostListener, OnInit } from '@angular/core';
import { ComponentsEnum } from '../../enums/components.enum';
import { InteractionServiceResp } from '../../models/interaction-service-response.model';
import { InteractionService } from './../../services/interaction.service';
import { LoadRequestPanelDataService } from './load-request-panel-data.service';

@Component({
  selector: 'app-load-request-panel',
  templateUrl: './load-request-panel.component.html',
  styleUrls: ['./load-request-panel.component.scss'],
})
export class LoadRequestPanelComponent implements OnInit {
  loadRequestPanel = false;
  loadRequestData;
  alertLane: string = '';

  constructor(
    private interactionService: InteractionService,
    private loadRequestPanelDataService: LoadRequestPanelDataService
  ) {
    this.interactionService
      .subscribeToComponent(ComponentsEnum.LOAD_PANEL)
      .subscribe((resp: InteractionServiceResp) => {
        if (resp.data.status !== 'CLOSE') {
          this.loadRequestPanelDataService.setPanelData(resp.data.loadPanel);
          this.loadRequestData = resp.data.loadPanel;
          this.alertLane = resp.data.loadPanel.loadRequestAlert.moveToSicCode;
        }
      });
  }

  ngOnInit() {}

  closePanel() {
    this.interactionService.sendAlertLane('');
    this.interactionService.sendDataLoadRequest([], 'CLOSE');
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closePanel();
    }
  }
}
