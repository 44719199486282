import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutConfig } from '../layout-config.interface';

export class XpoLtlLayoutPreferencesStorageData {
  activeLayout: string = undefined;
  userLayouts: LayoutConfig[] = [];
}

export interface XpoLtlLayoutPreferencesStorage {
  getData(): Observable<XpoLtlLayoutPreferencesStorageData>;
  setData(data: XpoLtlLayoutPreferencesStorageData): Observable<void>;
}

export const XPO_LTL_LAYOUT_PREFERENCES_STORAGE = new InjectionToken<XpoLtlLayoutPreferencesStorage>(
  'XpoLtlLayoutPreferencesStorage'
);
