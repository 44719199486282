import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule, MatFormFieldModule, MatIconModule, MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SdkLocationModule as SdkLocationModule2 } from '@xpo-ltl-2.0/sdk-location';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { LoginModule } from '@xpo-ltl/login';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { LinehaulOperationsApiService } from '@xpo-ltl/sdk-linehauloperations';
import { LocationApiService as LocationService } from '@xpo-ltl/sdk-location';
import { SdkLocationModule } from '@xpo-ltl/sdk-location';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import {
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoShellModule,
  XpoSnackBarModule,
} from '@xpo/ngx-core';
import { XpoBoardModule, XpoFiltersModule } from '@xpo/ngx-core-board';
import {
  XpoLtlReleaseNotesComponent,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherComponent,
  XpoLtlRoleSwitcherModule,
  XpoLtlServiceCentersService,
  XpoLtlShipmentDetailsComponent,
  XpoLtlShipmentDetailsModule,
  XpoLtlShipmentDetailsService,
  XpoLtlSicSwitcherModule,
} from '@xpo/ngx-ltl';
import { XrtFireMessagingService, XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import { environment } from '../environments/environment';
import { NotificationComponent } from './app-notification.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogModule } from './dialogs/dialog.module';
import { SidePanelContainerModule } from './shared/components/side-panel-container/side-panel-container.module';
import { AuthService } from './shared/services/auth.service';
import { InteractionService } from './shared/services/interaction.service';
import { SharedModule } from './shared/shared.module';
import { AlertCardComponentMock } from './utils/mocks/alert-card.component.mock';
import { ManifestComponentMock } from './utils/mocks/manifest.component.mock';
import { UserRoleServiceMock } from './utils/mocks/user-role.service.mock';
import { PipeModule } from '@xpo-ltl/angular-utils';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.getSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}
import { ExceptionManagementApiService } from '@xpo-ltl/sdk-exceptionmanagement';
import { HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';
import * as $ from 'jquery';
import { SicFilterService } from './shared/components/sic-filter/sic-filter.service';
// tslint:disable-next-line: import-spacing
import { LayoutPreferenceModule } from './shared/layout-preference/layout-preference.module';
import { LaneDataTypesService } from './shared/services/lane-data-types.service';
import { XpoFooterModule } from './xpo-footer/xpo-footer.module';
import { FiltersModule } from './filters/filters.module';
window['$'] = $;

@NgModule({
  declarations: [AppComponent, NotificationComponent],
  imports: [
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    CommonModule,
    LoginModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoLtlReleaseNotesModule,
    XpoLtlSicSwitcherModule,
    XpoLtlRoleSwitcherModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkLocationModule2,
    SdkUserPreferenceModule,
    SdkInfrastructureModule,
    SdkLoggingModule,
    SdkUserPreferenceModule,
    XpoLtlShipmentDetailsModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
    XpoBoardModule,
    XpoFiltersModule,
    LayoutPreferenceModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    XpoFooterModule,
    PipeModule,
    FiltersModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    DataApiService,
    NotificationService,
    DecimalPipe,
    DatePipe,
    ExceptionManagementApiService,
    LinehaulOperationsApiService,
    InteractionService,
    LocationApiService,
    LocationService,
    XpoLtlServiceCentersService,
    UserRoleServiceMock,
    AlertCardComponentMock,
    ManifestComponentMock,
    HumanResourceApiService,
    SicFilterService,
    LaneDataTypesService,
    XrtFireMessagingService,
    XrtFireMessagingTokenService,
    XpoLtlShipmentDetailsService,
    ShipmentOdsApiService,
    SidePanelContainerModule,
    AuthService,
  ],
  entryComponents: [XpoLtlReleaseNotesComponent, XpoLtlRoleSwitcherComponent, XpoLtlShipmentDetailsComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
