import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatFormFieldModule, MatIconModule } from '@angular/material';
import { NgxGalleryModule } from 'ngx-gallery';
import { SortByPipe } from '../pipes/sortby-pipe';
import { SubstringPipe } from './../pipes/substring-pipe';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { LoadRequestPanelComponent } from './components/load-request-panel/load-request-panel.component';
import { LoadRequestPanelModule } from './components/load-request-panel/load-request-panel.module';
import { SicFilterComponent } from './components/sic-filter/sic-filter.component';
import { ProNbrFormatPipe } from './formatters/proNbr-pipe';
@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    ComingSoonComponent,
    SicFilterComponent,
    SubstringPipe,
    SortByPipe,
    // Pipes/Directives
    ProNbrFormatPipe,
  ],
  imports: [
    MatIconModule,
    CommonModule,
    // Pipes/Directives
    CommonModule,
    NgxGalleryModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    LoadRequestPanelModule,
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    ComingSoonComponent,
    SicFilterComponent,
    LoadRequestPanelComponent,
    SubstringPipe,
    SortByPipe,
    // Pipes/Directives
    ProNbrFormatPipe,
  ],
  providers: [SortByPipe],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
