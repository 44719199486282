import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ComponentsEnum } from '../../../shared/enums/components.enum';
import { RegionWithSics } from '../../../shared/models/region-with-sics.model';
import { SicInfo } from '../../../shared/models/sic-info.model';
import { InteractionService } from '../../services/interaction.service';
import { SicFilterService } from './sic-filter.service';

export const _filter = (opt: SicInfo[], value: string): SicInfo[] => {
  const filterValue = value ? value.toLowerCase() : '';

  return opt.filter(
    ({ sicShowed, sicDescription }) =>
      (sicShowed && sicShowed.toLowerCase().includes(filterValue)) ||
      (sicDescription && sicDescription.toLowerCase().includes(filterValue))
  );
};

@Component({
  selector: 'sic-filter',
  templateUrl: 'sic-filter.component.html',
  styleUrls: ['sic-filter.component.scss'],
})
export class SicFilterComponent implements OnInit, OnChanges {
  filteredOptions: Observable<RegionWithSics[]>;
  sicFound = true;

  private firstLoad = true;
  private _options: RegionWithSics[] = [];
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;
  @Output() valueChange = new EventEmitter();
  @Output() inputAction = new EventEmitter();
  @Input() shift: string;
  @Input() region: string;
  @Input() isGlobalFilter: boolean;
  @Input() isDisabled = false;
  @Input() regionListWithSics: RegionWithSics[];
  myControl = new FormControl({ value: '', disabled: this.isDisabled });

  constructor(
    private sicFilterService: SicFilterService,
    private interactionService: InteractionService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.interactionService.subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS).subscribe(({ data: { sic } }) => {
      if (!sic) {
        this.myControl.setValue('');
      }
    });

    router.events.subscribe((navEnd) => {
      if (navEnd instanceof NavigationEnd && this.isGlobalFilter) {
        setTimeout(() => {
          this.myControl.setValue(this.activatedRoute.snapshot.queryParams.sic);
          this.onEnter();
        }, 0);
      }
    });
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.region) {
      this.updateSicList();
    }
    if (changes.shift) {
      this.shift = changes.shift.currentValue;
      this.updateSicList();
    }
    if (changes.isDisabled) {
      if (changes.isDisabled.currentValue) {
        this.myControl.disable();
      } else {
        this.myControl.enable();
      }
    }
    if (changes.regionListWithSics && changes.regionListWithSics.currentValue.length) {
      this.updateSicList();
      if (this.firstLoad) {
        this.onEnter();
        this.firstLoad = false;
      }
    }
  }

  updateSicList() {
    if (this.regionListWithSics) {
      if (this.regionListWithSics.length) {
        if (!this.region) {
          const allSics = this.regionListWithSics
            .map((r) => r.sicList)
            .reduce((sicList1, sicList2) => sicList1.concat(sicList2));
          this.options = [{ region: '', sicList: allSics }];
        } else {
          const selectedRegion = this.regionListWithSics.find((selRegion) => selRegion.region === this.region);
          this.sicFilterService.setSicList([selectedRegion]);

          selectedRegion.sicList.filter(
            (sic) => (sic.isOb === 1 && this.shift === 'O') || (sic.isFac === 1 && this.shift === 'F')
          );

          if (!selectedRegion.sicList.map((s) => s.sicCd).includes(this.myControl.value.substring(0, 3))) {
            this.myControl.setValue('');
            this.valueChanged(this.myControl.value);
          }

          const otherSic = this.regionListWithSics
            .filter((selRegion) => selRegion.region !== this.region)
            .map((r) => r.sicList)
            .reduce((r1, r2) => r1.concat(r2));

          this.options = [selectedRegion, { region: 'Other', sicList: otherSic }];
        }
      }
    }
  }

  get options(): RegionWithSics[] {
    // transform value for display
    return this._options;
  }

  set options(options: RegionWithSics[]) {
    this._options = options;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this._filter(value);
      })
    );
  }

  getShiftLabel(option) {
    if (option.region === 'Other' || !option.region) {
      return '';
    }
    switch (this.shift) {
      case 'O':
        return ' - Outbound';
      case 'F':
        return ' - FAC';
      default:
        return '';
    }
  }

  setUppercase() {
    this.sicFound = true;
    this.myControl.setValue(this.myControl.value.toUpperCase());
  }

  setInputAction() {
    this.inputAction.emit(this.myControl.value);
  }

  onEnter() {
    this.sicFound = this.options
      .map((group) => ({ region: group.region, sicList: _filter(group.sicList, this.myControl.value) }))
      .some((group) => group.sicList.length > 0);

    if (this.sicFound) {
      const sicToShow = this.options
        .map((o) => o.sicList)
        .reduce((s1, s2) => s1.concat(s2))
        .find((s) => s.sicCd === this.myControl.value);
      setTimeout(() => {
        if (sicToShow) {
          this.myControl.setValue(sicToShow.sicShowed);
        }
      }, 0);
      if (this.myControl.value) {
        this.valueChanged(this.myControl.value);
      }
    }
    this.autocomplete ? this.autocomplete.closePanel() : '';
  }

  valueChanged($event) {
    this.valueChange.emit($event.substring(0, 3));
  }

  private _filter(value: string): RegionWithSics[] {
    if (value) {
      return this.options
        .map((group) => ({ region: group.region, sicList: _filter(group.sicList, value) }))
        .filter((group) => group.sicList.length > 0);
    }

    return this.options;
  }

  restrictType() {
    this.sicFound = true;
    const pattern = /^[a-zA-Z, -]*$/;
    let tempVal = this.myControl.value;
    if (!pattern.test(tempVal)) {
      tempVal = tempVal.replace(/[^a-zA-Z, -]/, '');
    }
    this.myControl.setValue(tempVal);
  }
}
