import { Component } from '@angular/core';
import { IconsKeys } from '../../enums/icons.enum';

@Component({
  selector: 'app-lanes-bypass-formatter-cell',
  template: `
    <span *ngIf="!!totalBypassCount">{{ totalBypassCount }}</span>
    <mat-icon *ngIf="!!bypassIcon" svgIcon="{{ bypassIcon }}" class="{{ bypassIcon }}"></mat-icon>
  `,
})
export class BypassFormatterComponent {
  params: any;
  totalBypassCount: any;
  bypassIcon: any;

  agInit(params) {
    this.params = params;
    if (params.node.group) {
      let sum = 0;
      params.node.allLeafChildren.forEach((childRow) => {
        sum += childRow.data.bypassLoadCount ? childRow.data.bypassLoadCount : 0;
      });
      this.totalBypassCount = sum;
    } else {
      this.bypassIcon = params.data && params.data.bypassInd ? IconsKeys.circleFilled : '';
    }
  }
}
