export enum AllowableLoadsTableEnum {
  Lane = 'Move To',
  CloseTo = 'Close To',
  Guaranteed = 'Guaranteed',
  SpecialService = 'Special Service',
  Load = 'Loads',
  Bypass = 'Bypass',
  HSS = 'HSS',
  DoorNbr = 'Door #',
  LoadedWeight = 'Loaded Weight (lbs)',
  LoadedCube = 'Loaded Cube (%)',
  ShipmentsLoaded = 'Shipments Loaded',
  ShipmentsDest = 'Shipments Dest.',
  Manifest = 'Manifest',
  Source = 'Source',
  Exception = 'Exception',
  Status = 'Status',
  Photos = 'Photos',
}
