import { Injectable, OnDestroy } from '@angular/core';
import { ListChangesForLanesResp } from '@xpo-ltl/sdk-linehauloperations';
import { XpoBoardDataSource, XpoBoardState } from '@xpo/ngx-core-board';
import { Unsubscriber } from '@xpo/ngx-ltl';
import _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentsEnum } from '../../../../shared/enums/components.enum';
import { InteractionServiceResp } from '../../../../shared/models/interaction-service-response.model';
import { InteractionService } from '../../../../shared/services/interaction.service';
import { AllowableLoadsService } from '../services/allowable-loads.service';

@Injectable({
  providedIn: 'root',
})
export class ChangesViewDataSourceService extends XpoBoardDataSource implements OnDestroy {
  private _fetchChangesData = new BehaviorSubject<number>(0);
  fetchLoadsData$ = this._fetchChangesData.asObservable();
  interactionSubscription: Subscription;

  private listChangesForLanes: BehaviorSubject<ListChangesForLanesResp> = new BehaviorSubject(undefined);
  listChangesForLanes$ = this.listChangesForLanes.asObservable();

  private unsubscriber = new Unsubscriber();

  currentSicCd;
  currentShiftCd;
  runDate;
  sic;
  shift;
  state;

  constructor(private interactionService: InteractionService, private allowableLoadsService: AllowableLoadsService) {
    super();
    this.initSubscriptions();
  }
  fetchData(state: XpoBoardState): Observable<any> {
    return this.allowableLoadsService.getListChangesForLanes(state);
  }

  initSubscriptions() {
    this.interactionSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((resp: InteractionServiceResp) => {
        if (this.sic !== resp.data.sic || this.runDate !== resp.data.planDate || this.shift !== resp.data.shift) {
          this.sic = resp.data.sic;
          this.runDate = resp.data.planDate;
          this.shift = resp.data.shift;
          if (
            !_.isEqual(resp.data, this.allowableLoadsService.savedFiltersChanges) &&
            resp.data.planDate &&
            resp.data.shift
          ) {
            this.refresh();
          }
        }
      });
  }

  ngOnDestroy() {
    this.interactionSubscription.unsubscribe();
  }
}
