export enum AlertsType {
  MISLOAD_GUR = 'G! Shipment Misloaded',
  HSS_UNDER_WGT = 'HSS Underweight',
  PROG_LOAD = 'Progressive Load',
  LOAD_REQUEST = 'Load Request',
  // Out of scope 14/4
  // BYPS_UNDER_UTLZ = 'Partial Bypass',
  // Overage
  // On-Time Departure
}
