import { Injectable } from '@angular/core';
import { IconsKeys } from '../../enums/icons.enum';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor() {}

  getIconByAlert(type: string): string {
    const keys = {
      'g-alert': IconsKeys.guaranteed,
      photoCamera: IconsKeys.photoCamera,
      arrowUp: IconsKeys.arrowUp,
      underweight: IconsKeys.HSSWeight,
      bell: IconsKeys.bell,
      onTimeDepart: IconsKeys.onTimeDepart,
      progLoad: IconsKeys.progLoad,
      partialBypass: IconsKeys.partialBypass,
    };
    return keys[type];
  }
}
