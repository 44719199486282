import { Injectable } from '@angular/core';
import { LocationApiService } from '@xpo-ltl/sdk-location';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegionWithSics } from '../shared/models/region-with-sics.model';

@Injectable({ providedIn: 'root' })
export class LinehaulService {
  // global filters - modify accordingly after adding sic switcher
  private sicSubject = new BehaviorSubject('');
  private regionSubject = new BehaviorSubject('');
  private shiftSubject = new BehaviorSubject('');
  private planDateSubject = new BehaviorSubject('');

  sic$ = this.sicSubject.asObservable();
  region$ = this.regionSubject.asObservable();
  shift$ = this.shiftSubject.asObservable();
  planDate$ = this.planDateSubject.asObservable();

  constructor(private locationApiService: LocationApiService) {}

  /** gets/updates the regions list based on sic */
  getRegionsWithSicsList(): Observable<RegionWithSics[]> {
    return this.locationApiService.listAllActiveSics().pipe(
      map((resp) => {
        const tempRegionWithSics: RegionWithSics[] = [];
        if (!!resp && typeof resp.activeSics !== 'undefined') {
          const tempRegions = [];
          resp.activeSics.forEach((sicInfo) => {
            if (tempRegions.indexOf(sicInfo.region) === -1) {
              tempRegions.push(sicInfo.region);
              tempRegionWithSics.push({ region: sicInfo.region, sicList: [] });
            }
          });
          resp.activeSics.forEach((info) => {
            tempRegionWithSics.forEach((regionSic) => {
              if (info.region === regionSic.region) {
                regionSic.sicList.push({
                  sicCd: info.sicCd,
                  city: info.city,
                  state: info.state,
                  isOb: info.isOb,
                  isFac: info.isFac,
                  sicShowed: `${info.sicCd}-${info.sicDescription}, ${info.state}`,
                  sicDescription: info.sicDescription,
                });
              }
            });
          });
          tempRegionWithSics.unshift({ region: '', sicList: [] });
        }
        return tempRegionWithSics;
      })
    );
  }
}
