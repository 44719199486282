/* tslint:disable: max-line-length */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatInputModule, MatSelectModule } from '@angular/material';

import {} from '@angular/material';
import { XpoButtonModule } from '@xpo/ngx-core';
import { XpoAgGridModule } from '@xpo/ngx-core-ag-grid';
import { XpoBoardModule, XpoFiltersModule } from '@xpo/ngx-core-board';
import { XpoAgGridBoardModule } from '@xpo/ngx-core-board-ag-grid';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { PipesModule } from '../../../pipes/pipes.module';
import { AddLoadFormatter } from '../../formatters/ag-grid-cell-formatters/add-load-formatter';
import { BypassFormatterComponent } from '../../formatters/ag-grid-cell-formatters/BypassFormatterComponent';
import { CloseToFormatterComponent } from '../../formatters/ag-grid-cell-formatters/closeto-formatter';
import { ExceptionFormatterComponent } from '../../formatters/ag-grid-cell-formatters/exception-formatter';
import { IconsFormatterComponent } from '../../formatters/ag-grid-cell-formatters/icons-formatter';
import { LoadedWeightFormatterComponent } from '../../formatters/ag-grid-cell-formatters/loaded-weight-formatter';
import { ModifiedByFormatterComponent } from '../../formatters/ag-grid-cell-formatters/modified-by-formatter';
import { MoveToFormatterComponent } from '../../formatters/ag-grid-cell-formatters/move-to-formatter';
import { SpecialServiceFormatterComponent } from '../../formatters/ag-grid-cell-formatters/special-service-formatter';
import { StatusChangeFormatterComponent } from '../../formatters/ag-grid-cell-formatters/status-chage-formatter';
import { StatusFormatterComponent } from '../../formatters/ag-grid-cell-formatters/status-formatter';
import { DateFormatterComponent } from './../../formatters/date-formatter';
import { LoadRequestPanelDetailsComponent } from './components/load-request-panel-details/load-request-panel-details.component';
import { LoadRequestPanelFormComponent } from './components/load-request-panel-form/load-request-panel-form.component';
import { LoadRequestPanelComponent } from './load-request-panel.component';

const agGridFormatters = [
  IconsFormatterComponent,
  SpecialServiceFormatterComponent,
  StatusFormatterComponent,
  ModifiedByFormatterComponent,
  BypassFormatterComponent,
  StatusChangeFormatterComponent,
  DateFormatterComponent,
  LoadedWeightFormatterComponent,
  ExceptionFormatterComponent,
  CloseToFormatterComponent,
  MoveToFormatterComponent,
  AddLoadFormatter,
];
@NgModule({
  declarations: [LoadRequestPanelComponent, LoadRequestPanelFormComponent, LoadRequestPanelDetailsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    XpoButtonModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    XpoAgGridModule,
    XpoBoardModule,
    XpoAgGridBoardModule,
    XpoFiltersModule,
    PipesModule,
    AgGridModule.withComponents([...agGridFormatters]),
  ],
  exports: [LoadRequestPanelComponent],
})
export class LoadRequestPanelModule {}
