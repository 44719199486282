import { Component } from '@angular/core';

@Component({
  selector: 'app-exception-formatter-cell',
  template: `
    <div *ngIf="params.value" class="exceptionInd"><span>#</span></div>
  `,
  styleUrls: ['./exception-formatter.scss'],
})
export class ExceptionFormatterComponent {
  params: any;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
  }
}
