import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LinehaulOperationsApiService, ListLoadRequestsResp } from '@xpo-ltl/sdk-linehauloperations';
import { UpsertLoadRequestRqst } from '@xpo-ltl/sdk-linehauloperations';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { XpoConfirmDialog, XpoConfirmDialogConfig, XpoSnackBar } from '@xpo/ngx-core';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { of, pipe, Subscription, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { LoadRequestUI } from '../../../../models/load-request-ui.models';
import { AuthService } from '../../../../services/auth.service';
import { InteractionService } from '../../../../services/interaction.service';
import { UserRoleService } from '../../../../services/user-role/user-role.service';
import {
  ExceptionManagementApiService,
  ListItemsByApplicationIdQuery,
  ListItemsByApplicationIdResp,
  ItemQueueDetail,
  UpdateItemStatusRqst,
  Exception,
  UpdateItemStatusResp,
  UpdateExceptionPath,
  UpdateExceptionRqst,
  OperationException,
} from '@xpo-ltl/sdk-exceptionmanagement';

@Component({
  selector: 'app-load-request-panel-form',
  templateUrl: './load-request-panel-form.component.html',
  styleUrls: ['./load-request-panel-form.component.scss'],
})
export class LoadRequestPanelFormComponent implements OnInit, OnChanges {
  @Input() data: any = {};

  initialized = false;
  loadsQty = '';
  approveFlag = false;
  actionFlag = true;
  loadQuantityArray = [];
  exceptionInstId: number;
  approvalType = ['Eligible', 'Ineligible'];
  formNames = {
    LoadQty: 'loadQuantity',
    ApprovalType: 'approvalType',
    DeclineReason: 'declineReason',
    Action: 'action',
  };
  form: FormGroup;
  actionFormControl: AbstractControl = new FormControl();
  loadQuantityFormControl: AbstractControl = new FormControl();
  declineReasonFormControl: AbstractControl = new FormControl();
  approvalTypeFormControl: AbstractControl = new FormControl();

  info = {
    requesting: '',
    requested: null,
    lane: '',
    nOfLoads: 0,
    requestedBy: '',
    reason: '',
    comments: '',
    closeTo: '',
    requestType: '',
  };
  private loadReqAlert: LoadRequestUI;
  interactionSubscription: Subscription;
  actionsEnum = { APPROVE: 'APPROVE', DECLINE: 'DECLINE' };
  exceptionStatusCd = { APPROVE: 'Approved', DECLINE: 'Declined' };
  actions = Object.values(this.actionsEnum);
  requestType = '';
  areActionsDisabled: boolean;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private linehaulOperationsApiService: LinehaulOperationsApiService,
    private exceptionManagementService: ExceptionManagementApiService,
    private xpoSnackBar: XpoSnackBar,
    private userRoleService: UserRoleService,
    private timeService: XpoLtlTimeService,
    private loggingApiService: LoggingApiService,
    private interactionService: InteractionService,
    public dialog: XpoConfirmDialog
  ) {}

  ngOnInit() {
    this.loadReqAlert = this.data.loadRequestAlert;
    this.initialized = true;
    this.initializeForm();
    this.setLoadQuantityArray();
    this.loadInfo();
    this.setComments();
    this.setApprovalType();

    this.actionFormControl.valueChanges.subscribe((value) => {
      // for approve - approval compliance type is required
      // for decline - comments/decline reason is required, so make appropriate validations
      if (value === this.actionsEnum.APPROVE) {
        if (this.info.requestType !== 'CUT') {
          this.approvalTypeFormControl.setValidators(Validators.required);
        }
        this.declineReasonFormControl.clearValidators();
        this.declineReasonFormControl.markAsTouched();
        this.declineReasonFormControl.updateValueAndValidity({ onlySelf: true });
      } else if (value === this.actionsEnum.DECLINE) {
        this.declineReasonFormControl.setValidators(Validators.required);
        this.approvalTypeFormControl.clearValidators();
        this.approvalTypeFormControl.markAsTouched();
        this.approvalTypeFormControl.updateValueAndValidity({ onlySelf: true });
      }
    });
  }

  initializeForm() {
    this.form = this.fb.group({
      loadQuantity: ['', Validators.required],
      approvalType: [''],
      declineReason: [''],
      action: ['', Validators.required],
    });
    this.loadQuantityFormControl = this.form.get('loadQuantity');
    this.declineReasonFormControl = this.form.get('declineReason');
    this.actionFormControl = this.form.get('action');
    this.approvalTypeFormControl = this.form.get('approvalType');
  }

  loadInfo() {
    let createdDate;
    if (this.loadReqAlert) {
      this.info.requesting = this.loadReqAlert.originSicCode;
      this.info.requested = this.loadReqAlert.auditInfo.createdTimestamp;
      this.info.lane = this.loadReqAlert.moveToSicCode;
      this.info.nOfLoads = Math.abs(this.loadReqAlert.requestQuantity);
      this.info.closeTo = this.loadReqAlert.loadToSicCode;
      this.loadsQty = this.loadReqAlert.requestQuantity.toString();
      this.info.requestedBy = this.loadReqAlert.requestorName;
      this.info.comments = this.loadReqAlert.requestorComments;
      this.info.requestType = this.loadReqAlert.requestType;
      createdDate = new Date(this.loadReqAlert.auditInfo.createdTimestamp);
    }

    this.linehaulOperationsApiService.listLoadRequestReasonCodes().subscribe((list_reasons) => {
      if (list_reasons.loadRequestReasonCodes && list_reasons.loadRequestReasonCodes.length > 0) {
        list_reasons.loadRequestReasonCodes.map((x) => {
          if (x.reasonCd === this.loadReqAlert.reasonCd) {
            this.info.reason = x.reasonDescription;
          }
        });
      }
    });
    this.requestType = this.loadReqAlert.requestType;
  }

  setLoadQuantityArray() {
    this.loadQuantityArray = [];
    for (let i = Math.abs(this.loadReqAlert.requestQuantity); i > 0; i--) {
      this.loadQuantityArray.push(i);
    }

    const { approvedQuantity, declinedQuantity, requestQuantity } = this.loadReqAlert;

    if (approvedQuantity) {
      this.loadQuantityFormControl.setValue(approvedQuantity);
    } else {
      declinedQuantity
        ? this.loadQuantityFormControl.setValue(declinedQuantity)
        : this.loadQuantityFormControl.setValue(Math.abs(requestQuantity));
    }
  }

  setApprovalType() {
    if (!!this.loadReqAlert['approveTypeCd']) {
      this.approvalTypeFormControl.setValue(this.loadReqAlert.approveTypeCd);
    }
  }

  setComments() {
    if (this.loadReqAlert && this.loadReqAlert.linehaulComments) {
      this.declineReasonFormControl.setValue(this.loadReqAlert.linehaulComments);
    }
  }

  onClickSelectAction(approve: boolean) {
    this.actionFlag = true;
    this.approveFlag = approve;
  }

  onCancel() {
    this.approveFlag = false;
    this.actionFlag = false;
    this.cleanControls();
    this.closePanel();
  }

  cleanControls() {
    this.approvalTypeFormControl.reset();
    this.declineReasonFormControl.reset();
    this.declineReasonFormControl.setErrors(null);
  }

  approve() {
    if (!this.readOnly) {
      this.areActionsDisabled = true;
      const declinedLoads = Math.abs(this.loadReqAlert.requestQuantity) - this.form.get('loadQuantity').value;
      if (!this.approvalTypeFormControl.value && this.info.requestType !== 'CUT') {
        this.approvalTypeFormControl.setErrors({ required: true });
      } else {
        this.approvalTypeFormControl.setErrors(null);
      }
      if (declinedLoads > 0 && !this.declineReasonFormControl.value) {
        this.declineReasonFormControl.setErrors({ required: true });
      } else {
        this.declineReasonFormControl.setErrors(null);
      }
      if (!this.form.get('loadQuantity').value || this.form.get('loadQuantity').value === 0) {
        this.loadQuantityFormControl.setErrors({ required: true });
      } else {
        this.loadQuantityFormControl.setErrors(null);
      }
      this.form.markAllAsTouched();
      this.exceptionInstId = this.loadReqAlert.exceptionInstId;
      const request = new UpsertLoadRequestRqst();
      request.loadRequest = this.getLoadRequest(this.loadReqAlert);

      if (this.form.valid) {
        request.loadRequest.approvedQuantity = this.form.get('loadQuantity').value;
        request.loadRequest.declinedQuantity = declinedLoads;
        request.loadRequest.approveTypeCd = this.form.get('approvalType').value || 'Eligible';
        request.loadRequest.declineReasonCd = null;
        request.loadRequest.approveDeclineDateTime = new Date();
        // request.loadRequest.approveReasonCd = this.form.get('declineReason').value;
        request.loadRequest.linehaulComments = this.form.get('declineReason').value;
        request.loadRequest.linehaulName = `${this.userRoleService.user.givenName} ${this.userRoleService.user.lastName}`;
        request.loadRequest.linehaulEmplid = this.userRoleService.user.employeeId;
        request.loadRequest.statusCd = this.exceptionStatusCd.APPROVE;
        this.linehaulOperationsApiService
          .upsertLoadRequest(request)
          .pipe(
            catchError((err) => {
              return throwError(`ERROR: ${err.error.moreInfo[0].location}`);
            }),
            filter((response) => !!response)
          )
          .subscribe(
            (response) => {
              this.loggingApiService.info(
                'For SIC -  ' +
                  request.loadRequest.originSicCode +
                  ' - Lane: ' +
                  request.loadRequest.moveToSicCode +
                  '- Approved Loads : ' +
                  request.loadRequest.approvedQuantity +
                  ' Out Of ' +
                  request.loadRequest.requestQuantity,
                'Approved Load Requests',
                'For SIC -  ' +
                  request.loadRequest.originSicCode +
                  ' - Lane: ' +
                  request.loadRequest.moveToSicCode +
                  '- Approved Loads : ' +
                  request.loadRequest.approvedQuantity +
                  ' Out Of ' +
                  request.loadRequest.requestQuantity
              );

              this.areActionsDisabled = false;

              this.loadReqAlert.declinedQuantity = request.loadRequest.declinedQuantity;
              this.loadReqAlert.approvedQuantity = request.loadRequest.approvedQuantity;
              this.loadReqAlert.linehaulComments = request.loadRequest.linehaulComments;
              this.loadReqAlert.linehaulEmplid = this.userRoleService.user.employeeId;
              this.updateException(
                this.exceptionStatusCd.APPROVE,
                this.loadReqAlert.exceptionInstId,
                this.loadReqAlert.linehaulComments,
                request.loadRequest
              );
              this.updateItemStatus(
                this.exceptionStatusCd.APPROVE,
                this.loadReqAlert.exceptionInstId,
                this.loadReqAlert
              );
              this.xpoSnackBar.success('SUCCESS: Load Request Approved!');
              this.closePanel();
            },
            (err) => {
              this.xpoSnackBar.error(err);
            }
          );
      } else {
        this.xpoSnackBar.error('Please fix the errors above before Approving');
        this.areActionsDisabled = false;
      }
    }
  }

  updateException(status: string, exceptionInstId: any, comment?: string, requestLoadRequest?: any) {
    const updatePath = new UpdateExceptionPath();
    const request = new UpdateExceptionRqst();
    updatePath.exceptionInstId = exceptionInstId;
    request.exception = new Exception();
    request.exception.statusCd = status;
    request.exception.exceptionInstId = exceptionInstId;
    request.operationName = status;
    request.exception.operationException = new OperationException();
    if (requestLoadRequest) {
      request.exception.operationException.loadApprovedQuantity = requestLoadRequest.approvedQuantity || null;
      request.exception.operationException.loadRejectedQuantity = requestLoadRequest.declinedQuantity || null;
      request.exception.operationException.approveReasonCd = requestLoadRequest.approveReasonCd || null;
      request.exception.operationException.cancelReasonCd = requestLoadRequest.declineReasonCd || null;
      request.exception.operationException.approvalTypeCd = requestLoadRequest.approveTypeCd || null;
      request.exception.operationException.loggedSic = requestLoadRequest.originSicCode || null;
      request.exception.operationException.linehaulRegion = requestLoadRequest.regionCd || null;
    }
    if (comment) {
      request.exception.resolution = comment;
    }
    this.exceptionManagementService
      .updateException(request, updatePath, { toastOnError: false })
      .pipe(
        tap(() => {
          this.loggingApiService.info(`Updated exception with instId ${exceptionInstId} with status: ${status}`);
        }),
        catchError((err) => {
          this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].location}`);
          return of(false);
        })
      )
      .subscribe();
  }

  updateItemStatus(status: string, exceptionInstId: number, request?) {
    const queryParams: ListItemsByApplicationIdQuery = {
      applicationInstId: exceptionInstId.toString(),
      listInfo: null,
      currentStatusCd: null,
      assigneeEmployeeId: null,
      lockedByEmployeeId: null,
      assigneeEmplRoleId: null,
    };
    const opEx = new OperationException();
    opEx.loggedSic = request.originSic;
    opEx.linehaulRegion = request.regionCd;
    this.exceptionManagementService
      .listItemsByApplicationId(queryParams)
      .pipe(
        map((resp: ListItemsByApplicationIdResp) =>
          resp.items.map((itemDetail: ItemQueueDetail) => {
            const { item } = itemDetail;
            const exception: Exception = {
              auditInfo: null,
              operationException: opEx,
              resolvedDateTimeUtc: null,
              nbrOfMinimumBeforeExpiry: null,
              resolution: null,
              listActionCd: null,
              priority: null,
              statusCd: null,
              postedDateTimeUtc: null,
              activeDateTimeUtc: null,
              exceptionInstId: this.exceptionInstId,
            };
            const updateItemRequest: UpdateItemStatusRqst = {
              itemInstId: item.itemInstId,
              itemStatus: status,
              context: exception,
              statusUpdateComment: this.loadReqAlert.linehaulComments,
            };
            return this.exceptionManagementService.updateItemStatus(updateItemRequest);
          })
        ),
        switchMap((requests: Observable<UpdateItemStatusResp>[]) => forkJoin(requests))
      )
      .subscribe();
  }

  decline() {
    if (!this.readOnly) {
      this.areActionsDisabled = true;
      if (!this.declineReasonFormControl.value) {
        this.declineReasonFormControl.setErrors({ required: true });
        this.approvalTypeFormControl.setErrors(null);
      } else {
        this.declineReasonFormControl.setErrors(null);
        this.approvalTypeFormControl.setErrors(null);
      }
      this.form.markAllAsTouched();

      const request = new UpsertLoadRequestRqst();
      if (this.form.valid) {
        let confirmDecline = false;
        const confirmConfig: XpoConfirmDialogConfig = {
          confirmButtonText: 'Proceed',
          confirmButtonColor: 'warn',
          rejectButtonText: 'Go back',
        };
        // Open confirm dialog and subscribe to get the action from the user
        this.dialog
          .confirm('This will decline this Load Request. Do you want to continue ?', 'Confirm', confirmConfig)
          .subscribe((result) => {
            confirmDecline = result;
            if (confirmDecline) {
              request.loadRequest = this.getLoadRequest(this.loadReqAlert);
              request.loadRequest.declinedQuantity = this.info.nOfLoads ? Number(this.info.nOfLoads) : null;
              request.loadRequest.approvedQuantity = null;
              request.loadRequest.approveTypeCd = null;
              request.loadRequest.declineReasonCd = null;
              request.loadRequest.approveDeclineDateTime = new Date();
              request.loadRequest.approveReasonCd = null;
              request.loadRequest.linehaulComments = this.form.get('declineReason').value;
              request.loadRequest.linehaulName = `${this.userRoleService.user.givenName} ${this.userRoleService.user.lastName}`;
              request.loadRequest.linehaulEmplid = this.userRoleService.user.employeeId;
              request.loadRequest.statusCd = this.exceptionStatusCd.DECLINE;
              this.linehaulOperationsApiService
                .upsertLoadRequest(request)
                .pipe(
                  catchError((err) => {
                    return throwError(`ERROR: ${err.error.moreInfo[0].location}`);
                  }),
                  filter((response) => !!response)
                )
                .subscribe(
                  (response) => {
                    this.loggingApiService.info(
                      'For SIC -  ' +
                        request.loadRequest.originSicCode +
                        ' - Lane: ' +
                        request.loadRequest.moveToSicCode +
                        '- Declined Loads : ' +
                        this.form.get('loadQuantity').value,
                      'Declined Load Requests',
                      'For SIC -  ' +
                        request.loadRequest.originSicCode +
                        ' - Lane: ' +
                        request.loadRequest.moveToSicCode +
                        '- Declined Loads : ' +
                        this.form.get('loadQuantity').value
                    );
                    this.xpoSnackBar.success('SUCCESS: Load Request Declined!');
                    this.areActionsDisabled = false;

                    this.loadReqAlert.declinedQuantity = request.loadRequest.declinedQuantity;
                    this.loadReqAlert.approvedQuantity = request.loadRequest.approvedQuantity;
                    this.loadReqAlert.linehaulComments = request.loadRequest.linehaulComments;
                    this.loadReqAlert.linehaulEmplid = this.userRoleService.user.employeeId;
                    this.exceptionManagementService.listItemsByApplicationId;
                    this.updateException(
                      this.exceptionStatusCd.DECLINE,
                      this.loadReqAlert.exceptionInstId,
                      this.loadReqAlert.linehaulComments,
                      request.loadRequest
                    );
                    this.updateItemStatus(
                      this.exceptionStatusCd.DECLINE,
                      this.loadReqAlert.exceptionInstId,
                      this.loadReqAlert
                    );
                    this.closePanel(this.loadReqAlert);
                  },
                  (err) => {
                    this.xpoSnackBar.error(err);
                  }
                );
            }
          });
      } else {
        this.xpoSnackBar.error('Please fix the errors above before Declining');
        this.areActionsDisabled = false;
      }
    }
  }

  private getLoadRequest(data) {
    const lr = { ...data };
    delete lr.expirationDateFrom;
    delete lr.expirationDateTo;
    delete lr.exceptionInstId;
    return lr;
  }

  ngOnChanges(newinfo: SimpleChanges): void {
    if (this.initialized) {
      this.data = newinfo.data.currentValue;
      this.loadReqAlert = this.data.loadRequestInfo;
      this.loadReqAlert = this.data.loadRequestAlert;
      this.form.reset();
      this.loadInfo();
      this.setLoadQuantityArray();
      this.setComments();
      this.setApprovalType();
      if (this.readOnly) {
        this.declineReasonFormControl.disable();
      } else {
        this.declineReasonFormControl.enable();
      }
    }
  }

  getFormattedDate(date) {
    const timeToSic = this.timeService.formatDate(date, ' HH:mm, MM/DD/YYYY', this.loadReqAlert.originSicCode);
    return timeToSic;
  }

  hasError(error: string, formGrp?: FormGroup, field?: string): boolean {
    if (formGrp && formGrp.hasError(error)) {
      return formGrp.hasError(error) && formGrp.touched;
    } else if (formGrp && field) {
      return formGrp.get(field).hasError(error) && formGrp.get(field).touched;
    }
  }

  closePanel(loadReqAlert?: LoadRequestUI) {
    this.interactionService.sendDataLoadRequest(loadReqAlert, 'CLOSE');
  }

  get readOnly() {
    const now = new Date();
    const timeCheck = true; // this.loadReqAlert.expirationDateFrom < now && this.loadReqAlert.expirationDateTo > now;
    const notAllowed = !this.authService.isAllowedToApprove();
    const alreadyProcessed = this.loadReqAlert && this.loadReqAlert.linehaulEmplid;
    return notAllowed || alreadyProcessed || !timeCheck || this.approvalTypeFormControl.disabled;
  }
}
