import { MoveModeCd } from '@xpo-ltl/sdk-common';
export class EnumUtil {
  static getTrailerStatus(status: string) {
    const enums = {
      Loading: 'Loading',
      LoadingAtDock: 'Loading',
      Closed: 'Closed',
      Requested: 'Requested',
      Added: 'Added',
      Dispatched: 'Dispatched',
      Overhead: 'Overhead',
      CloseForLinehaul: 'Close For Linehaul',
      Enroute: 'Enroute',
    };
    return enums[status];
  }

  static getMoveModeAbbr(moveMode) {
    switch (moveMode) {
      case MoveModeCd.EXCLUSIVE:
        return 'EXL';
      case MoveModeCd.SUPPLEMENTAL:
        return 'SUP';
      default:
        return moveMode ? moveMode.toUpperCase() : '';
    }
  }

  static getShift(shift: string = 'Shift', type: string = '') {
    const enums = {
      DayReship: { label: 'Day Reship', code: 'D' },
      Inbound: { label: 'Inbound', code: 'I' },
      NightFAC: { label: 'FAC', code: 'F' },
      Outbound: { label: 'Outbound', code: 'O' },
    };
    return enums[shift][type];
  }

  static toFilterArray(enumValue) {
    const array = [];
    for (const key in enumValue) {
      // this validation should be enhaced once the filters DAY_RESHIP and INBOUND start working
      if (enumValue.hasOwnProperty(key) && key !== 'DAY_RESHIP' && key !== 'INBOUND') {
        array.push({ title: this.getShift(enumValue[key], 'label'), code: this.getShift(enumValue[key], 'code') });
      }
    }
    return array;
  }

  static toShiftCodeValue(code: string): string {
    const shiftEnums = {
      D: 'DayReship',
      I: 'Inbound',
      F: 'NightFAC',
      O: 'Outbound',
    };

    return shiftEnums[code];
  }

  static getPhotoType(imageType: string = 'OpenPhoto') {
    const types = {
      LoadPhoto: 'Load Photo',
      ClosePhoto: 'Close Photo',
      OpenPhoto: 'Open Photo',
    };
    return types[imageType];
  }

  static getPhotoStatus(status: string) {
    const enums = {
      LDDK: 'Load Photo',
      CLOS: 'Close Photo',
    };
    return enums[status];
  }
}
