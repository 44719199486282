export enum ComponentsEnum {
  GLOBAL_FILTERS = 'globalFilters',
  ALERT_FILTERS = 'alertFilters',
  METRICS = 'metrics',
  ALL = 'all',
  REGION = 'region',
  SIC = 'sic',
  SHIFT = 'shift',
  PLAN_DATE = 'planDate',
  SIDE_PANEL = 'sidePanel',
  LOAD_PANEL = 'loadPanel',
  AMOUNT = 'amount',
  ALERTS = 'alerts',
  ALERT_LANE = 'alertLane',
}
